import React, { Component } from 'react'
import { connect } from 'react-redux'
import Routes from '../core/components/router/Routes'
import WaitBlock from '../core/components/window/WaitBlock'

class DynamicApp extends Component {

    state = {
        loading: false,
    }
    componentDidMount () {
        if (this.props.isConnected 
            && this.props.numClientEnCours > 0
            && ( !this.props.clients)
        ) {
         
            this.setState ({
              loading : false
            })
          }        
         else {
          this.setState ({
            loading : false
          })
        }
    }
    render() {
        return (
            (this.state.loading || this.props.loading) ? 
                (<WaitBlock text="Récupération des informations en cours..." />) :
                (   <div className='CentreApplication'>
                       
                        <Routes />       
                    </div>
                )
        )
    }
}

const mapStateToProps = state => {
    return {       
        isConnected: state.identity.isConnected,
        numClientEnCours : state.identity.numClientEnCours,
        clients: state.identity.clients,
       
    }
}

export default connect(mapStateToProps) (DynamicApp)
