import { setError } from './WindowAppActions'

/**
 * Url de l'API
 * paramétrage défini 
 */


export const API = 'API'
export const METHOD_GET = 'GET'
export const METHOD_PUT = 'PUT'
export const METHOD_POST = 'POST'
export const METHOD_DELETE = 'DELETE'

export const RESET_DATA = 'DATA_RESET'
export const SET_SOURCE_TO_CANCEL = 'DATA_SET_SOURCE_TO_CANCEL'
export const SET_LOADING = 'DATA_LOADING'
export const SET_CACHE = 'DATA_SET_CACHE'
export const SET_DATA = 'DATA_SET'
export const DATA_SET_ERROR = 'DATA_SET_ERROR'
export const SET_DATA_MONTH_CALENDAR = 'DATA_SET_DATA_MONTH_CALENDAR'
export const SET_DATA_YEAR_CALENDAR = 'DATA_SET_DATA_YEAR_CALENDAR'
export const SET_DATA_DAILY_REPORT = 'DATA_SET_DATA_DAILY_REPORT'
export const SET_DATA_MONTHLY_REPORT = 'DATA_SET_DATA_MONTHLY_REPORT'
export const SET_DATA_ATTRIBUTIONS = 'DATA_SET_DATA_ATTRIBUTIONS'
export const UPDATE_DATA_BEFORE_ATTRIBUTIONS = 'DATA_UPDATE_DATA_BEFORE_ATTRIBUTIONS'
export const UPDATE_DATA_AFTER_ATTRIBUTIONS = 'DATA_UPDATE_DATA_AFTER_ATTRIBUTIONS'

export const apiError = (error) => dispatch => {
    dispatch(setError(error))
    dispatch(setData(null))
}

/**
 * Effectue une remise à zéro des éléments liés au chargement des données
 */
export const resetData = () => ({
    type: RESET_DATA,
    payload: null
})

export const setDataError = (data) => ({
    type: DATA_SET_ERROR,
    payload: data
})
export const resetDataError = () => ({
    type: DATA_SET_ERROR,
    payload: null
})
export const setSourceToCancel = (source) => ({
    type: SET_SOURCE_TO_CANCEL,
    payload: source
})
export const setLoading = (data) => ({
    type: SET_LOADING,
    payload: data
})

export const setData = (data) => ({
    
    type: SET_DATA,
    payload: data
})

