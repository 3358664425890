
import {
  CircularProgress, Container,
  Grid, Toolbar,
  Typography, withStyles
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/AddCircleOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import SplitPane from "react-split-pane";
import { bindActionCreators } from 'redux';
import ResizableIconButton from '../../../core/components/window/ResizableIconButton';
import '../../../stylesheets/splitpane.css';
import '../../../stylesheets/tabpanel.css';
import { deleteAppel, getListeAppelsparClient, insertAppel, updateAppel } from '../../redux/actions/ClientActions';
import { setTitle } from '../../redux/actions/WindowAppActions';
import ButtonOw from '../window/ButtonOw';



  
  function CustomNoRowsOverlay() {
    return (
      <GridOverlay className={classes.root}>
         <div className={classes.label}>Aucun Appel à effectuer</div>
     </GridOverlay>
  );
}

class AppelForm extends React.Component {
  constructor(props) {
    super(props);


  this.state = {  
    etat :0,
    editMode:false,
    addMode:false,
    deleteMode:false,
    reaffiche  : false,    
    numclient:0,
    newid:1,
    idencours:50,
    nbrows :0,
    ajoutPossible :1,
    ordremax :0,
    datedujour :"2020-07-28",
    datemaxi:"2022-07-28",
    olddateappel :"",
    olddateiso : null,
    selectedDateTime: "2017-05-24",
    zappel:{
      id:1,
      numero :0,
      date_iso : new Date(),
      date_appel : new Date(),
      motif_appel:'INFO',
      libel_appel:"Informations",
      ordre_appel:1,
      comment_appel:'',
      login_appel:'NME',
  },
    appel:{
        id:0,
        numero :0,
        date_iso : new Date(),
        date_appel : new Date(),
        motif_appel:'INFO',
        libel_appel:this.recupLibel("INFO"),
        ordre_appel:12,
        comment_appel:'',
        login_appel:'NME',
    },
    appelnull:{
      id:0,
      numero :0,
      date_iso : new Date(),
      date_appel : new Date(),
      motif_appel:'INFO',
      libel_appel:this.recupLibel("INFO"),
      ordre_appel:1,
      comment_appel:'',
      login_appel:'NME',
  },
    Rows:[],
    
    newRows: [
      {
        id:1,
        numero: 5049,
        date_iso:new Date("2000-01-01T00:00"),
        date_appel:new Date("2000-01-01T00:00"),
        motif_appel: "V",
        libel_appel: "",
        ordre_appel: "0",
        login_appel:"",
        comment_appel:"i",
        
      },
    ]
   
   }
   this.handleInputChange = this.handleInputChange.bind(this);
   this.afficheAppel = this.afficheAppel.bind(this);
   
    
  }
 
  
    typemotif = [];
  

     typeutilisateur = [
      {label:"", code:"0"},
      {label:"Nicolas MEDINA", code:"NME"},
      {label:"Jean Jacques BARET", code:"JJB"},
      {label:"Jean Philippe", code:"JPB"},
      {label:"Claude CASTELLO", code:"CLC"},
    ];
    
    listmodif = [];


    afficheAppel (values) {
     
      const nbrows = values.length;
      let datz ='';
      let items = [];
      if (nbrows ===0) {
        let appel0 = this.state.appelnull;
        this.setState({
          Rows:items,
          appel:appel0,
          nbrows :0,
          ordremax :0,
        })

      } 
      else {

          let nbmaxi =0;
          for (var i=0;i<nbrows;i++) {
            let tmpdat = values[i].date_appel;  
         
           
            try {
              if (tmpdat != null) {
                datz = tmpdat.substr(6,4)+'-'+ tmpdat.substr(3,2)+'-'+tmpdat.substr(0,2);                  
        
              }
              else  { 
                datz = '';    
              }
          
            } catch(e) {
              datz = ''; 
            }        
                                     
            let item ={
              id:i+1,
              numero :values[i].numero,      
              date_iso : new Date(datz).toISOString(),        
              date_appel :new Date(datz).toLocaleString().slice(0,10),
              motif_appel:values[i].motif_appel,
              libel_appel:this.recupLibel(values[i].motif_appel),
              ordre_appel:values[i].ordre_appel,
              comment_appel:values[i].comment_appel,
              login_appel:values[i].login_appel  
            };       
            nbmaxi = Math.max(nbmaxi,item.ordre_appel);
            items.push(item);
          }
        
      
          this.setState({
            Rows:items,
            appel:items[this.state.newid -1],
            nbrows :nbrows,
            ordremax :nbmaxi,
          })
         
       
      }
        
      try {
      
        this.setState({
          selectedDateTime : datz,
        })
    
      } catch(e) {
        
        this.setState({
          selectedDateTime :"",
        })
      
      }
      
    }

    recupLibel (code) {
  
      if (code !=='') {
        const resu = this.typemotif.find(item =>item.code === code) ;   
      ;
        if (resu !== undefined) {
          return resu.label;
        }
        else return '';
      }
      else return '';  
     }

    componentDidMount() {
      this.typemotif = this.props.typemotifs;
      var today = new Date();                     
      var sYear = today.getFullYear().toString ();
      var sYear2 =(2+ today.getFullYear()).toString ();
      var sMonth = ('0'+(today.getMonth() + 1)).slice(-2);
      var sDay =   ('0'+today.getDate()).slice(-2);
      var datejour =   sYear+'-'+sMonth +'-'+sDay;      
      var datemaxi =   sYear2+'-'+sMonth +'-'+sDay;         

       this.setState({
        datedujour : datejour,
        datemaxi : datemaxi,
        }); 
      

      this.setState({
        numclient :this.props.numclient,
       },()=>{
        var numsearch = parseInt(this.props.numclient);
        this.props.getListeAppelsparClient (numsearch)
        .then ( (response) => {
         
         this.afficheAppel(response.value);
         
      })
    
      }, (error) => {
        console.warn('error ',error);
        
      })

    }
       
     

      
      
        
    
   
    componentDidUpdate(prevProps, prevState) {  
       
      if ((this.state.numclient !== this.props.numclient) || 
         (this.state.reaffiche)) {
        this.setState({
          numclient :this.props.numclient,
          reaffiche: false,
          newid:1,
         },()=>{
          var numsearch = parseInt(this.props.numclient);
          this.props.getListeAppelsparClient (numsearch)
          .then ( (response) => {
           
           this.afficheAppel(response.value);
          
    
        })
      
        }, (error) => {
          console.warn('error ',error);
          
        })
      }
    }



   

    clickEdit= () => {   

      const displayCode = (localStorage.getItem('Suiviclients_Search_ajoutPossible') === '1') 
      if (displayCode) {
 
            this.setState({
              editMode: true,  
              addMode: false,   
              deleteMode:false,    
              idencours:this.state.newid,            
            })

            localStorage.setItem('Suiviclients_Search_modifEncours', 1);
          
            var tmpdat = this.state.appel.date_appel;
            var tmpiso = this.state.appel.date_iso;
            var datz = tmpdat.substr(6,4)+'-'+ tmpdat.substr(3,2)+'-'+tmpdat.substr(0,2);     
      
            try {
         
            this.setState({
              selectedDateTime : datz,
              olddateappel : tmpdat,
              olddateiso :tmpiso, 
            })    
        
            } catch(e) {
              this.setState({
                selectedDateTime :"",
                olddateappel:"",
                olddateiso :null,
              })
              
            }
        }
        else {
          this.setState({
            ajoutPossible :false,
          },()=>{
           
          
          })
        }
      
    }

    clickDelete= () => {   

      const displayCode = (localStorage.getItem('Suiviclients_Search_ajoutPossible') === '1') 
      if (displayCode) {
 
            this.setState({
              editMode: true,  
              addMode: false, 
              deleteMode :true,      
              idencours:this.state.newid,            
            })

            localStorage.setItem('Suiviclients_Search_modifEncours', 1);
          
            var tmpdat = this.state.appel.date_appel;
          
            var datz = tmpdat.substr(6,4)+'-'+ tmpdat.substr(3,2)+'-'+tmpdat.substr(0,2);     
          
            try {
         
            this.setState({
              selectedDateTime : datz,
            })    
        
            } catch(e) {
              this.setState({
                selectedDateTime :"",
              })
              
            }
        }
        else {
          this.setState({
            ajoutPossible :false,
          },()=>{
           
          
          })
        }
      
    }
     clickAjout= () => {   
      const displayCode = (localStorage.getItem('Suiviclients_Search_ajoutPossible') === '1') 
      if (displayCode) {
        var dateiso = new Date().toISOString(); 
        dateiso = dateiso.substr(0,10) + "T00:00:00Z";
        var datelocale = new Date().toLocaleString();
        var tmp = this.state.zappel;
         tmp.numero = this.state.numclient;
         tmp.date_iso = dateiso;
         tmp.date_appel =datelocale;                      
        this.setState({
          zappel : tmp,
        })

        
        localStorage.setItem('Suiviclients_Search_modifEncours', 1);;
        const appel = this.state.zappel;
        var tmpdat = appel.date_appel;
      
        var datz = tmpdat.substr(6,4)+'-'+ tmpdat.substr(3,2)+'-'+tmpdat.substr(0,2);     
       
        this.setState({
          addMode: true,   
          editMode:true,    
          deleteMode :false,  
          idencours:this.state.newid,  
          
        })
  
        this.setState({
          appel: appel,   
          selectedDateTime : datz, 
        })
     
      
      }
      else {
        this.setState({
          ajoutPossible :false,
        },()=>{
         
        
        })

      }
   
   
     }

    clickValid= () => {    
      var modifdate = false;


      var field = {}
      var fieldName = "numero";
      field[fieldName] =  this.state.appel.numero;
      this.listmodif.push(field);
      fieldName = "ordre_appel";
      
      field[fieldName] =  this.state.appel.ordre_appel;
      if (this.state.addMode) {field[fieldName] =  this.state.ordremax +1;}
      this.listmodif.push(field);

      fieldName = "date_appel";
      var datx= this.state.appel.date_iso;
      field[fieldName] =  datx;     
      this.listmodif.push(field);


      if (!this.state.addMode && !this.state.deleteMode)
      {
        if (this.state.appel.date_appel !== this.state.olddateappel) {
          modifdate = true;
        }

      }

      if (this.state.addMode || modifdate) {       
        fieldName = "motif_appel";
        field[fieldName] =  this.state.appel.motif_appel;
        this.listmodif.push(field);
        fieldName = "comment_appel";
        field[fieldName] =  this.state.appel.comment_appel;
        this.listmodif.push(field);
        fieldName = "login_appel";
        field[fieldName] =  this.state.appel.login_appel;
        this.listmodif.push(field);
      }

      var changes = {};
      for (var key in this.listmodif) {
        var obj = this.listmodif[key];
        for (var prop in obj) {
   
          changes[prop] = obj[prop];
          }            
        };  
      
      while(this.listmodif.length > 0) {
        this.listmodif.pop();
      }
      let appel = this.state.appel;
      appel.libel_appel = this.recupLibel(appel.code_appel);
   
      let values =  this.state.Rows;    
    
      if (this.state.addMode) {
        appel.numero = this.state.numclient;
      }

      values = values.map((x) => x);
      values[this.state.newid-1] = appel;

 
 
      if (modifdate) {
        field = {}
        fieldName = "numero";
        field[fieldName] =  this.state.appel.numero;
        this.listmodif.push(field);
        fieldName = "ordre_appel";
        
        field[fieldName] =  this.state.appel.ordre_appel;
        if (this.state.addMode) {field[fieldName] =  this.state.ordremax +1;}
        this.listmodif.push(field);

        fieldName = "date_appel";
        field[fieldName] =  this.state.olddateiso;     
        this.listmodif.push(field);

        var changesdelete = {};
        for (key in this.listmodif) {
           obj = this.listmodif[key];
          for ( prop in obj) {
     
            changesdelete[prop] = obj[prop];
            }            
          };  
        
        while(this.listmodif.length > 0) {
          this.listmodif.pop();
        }
   

        

      }
      


      if (this.state.editMode && !this.state.deleteMode && !this.state.addMode && !modifdate) { 

        this.props.updateAppel (changes)
        .then ( (resu) => {      
          
      
          if (resu.value ===1) {      
            
                                          
              localStorage.setItem('Suiviclients_Search_modifEncours', 0);
              
              this.setState({
                  editMode: false,
                  addMode:false,
                  deleteMode:false,
                  idencours :50,       
                  Rows : values,
                  reaffiche:true,   // il faut recharger la liste des appels
              }) 
            
                        
            }
            else {
              console.warn('pas de  modif');
            }
        }
        , (error) => {
          console.warn('Erreur update', error)
          
        })
      }
      else
      if (this.state.editMode && this.state.deleteMode) {
         
        this.props.deleteAppel (changes)
        .then ( (resu) => {    
           
          if (resu.value ===1) {        
                                          
              localStorage.setItem('Suiviclients_Search_modifEncours', 0);
              
              this.setState({
                  editMode: false,
                  addMode:false,
                  deleteMode:false,
                  idencours :50,       
                  Rows : values,
                  reaffiche:true,   // il faut recharger la liste des appels
              }) 
            
                        
            }
            else  { 
              
            }
        }
        , (error) => {
          console.warn('Erreur delete', error)
          
        })

      }
      else
      if (this.state.addMode || modifdate) { 
        if (!modifdate) {
          this.props.insertAppel (changes)
          .then ( (resu) => {    
                 
            if (resu.value === true) {     
                localStorage.setItem('Suiviclients_Search_modifEncours', 0);
                
                this.setState({
                    editMode: false,
                    addMode:false,
                    deleteMode:false,
                    idencours :50,       
                    Rows : values,
                    reaffiche:true,   // il faut recharger la liste des appels
                })   
              
              }
              else {
                
              }
          }
          , (error) => {
            console.warn('Erreur insert', error)
            
          })
        }
        else { //modifdate
             
              this.props.deleteAppel (changesdelete)
              .then ( (resu) => {    
                         
                if (resu.value === 1) {     
                  if (modifdate) {
                    this.props.insertAppel (changes)
                    .then ( (resu) => {  
                           
                        if (resu.value ===true) { 
                          localStorage.setItem('Suiviclients_Search_modifEncours', 0);
                    
                          this.setState({
                              editMode: false,
                              addMode:false,
                              deleteMode:false,
                              idencours :50,       
                              Rows : values,
                              reaffiche:true,   // il faut recharger la liste des appels
                          })

                        }
                        else {

                        }
                      }
                      , (error) => {
                        console.warn('Erreur delete', error)
                        
                      })
                    }
                  }
                  
              }
              , (error) => {
                console.warn('Erreur insert', error)
                
              })
            }      
      }

     
    
    }

   

    

   
    clickSearch= () => {

    }

    clickAnnul= () => {    
      let values =  this.state.Rows;  
      this.setState({
        editMode: false,   
        addMode:false,    
        deleteMode:false,      
        idencours:50,     
        ajoutPossible :1,  
      })    
      if (values.length >0) {
        this.setState({
          appel : values[this.state.newid-1] ,    
        })
      } else  {
        //this.state.appel.comment_appel ='';
        this.setState(Object.assign(this.state.appel,{comment_appel:''}));
      }
      
      localStorage.setItem('Suiviclients_Search_modifEncours', 0);
      
    }

    resetClick = () => {

    
      this.setState({
        ajoutPossible: true,
      })  
      this.clickAnnul();
    }

    handleInfosChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      } 
     
      handleInputChange(evt) {
        const field = {}
        const fieldName= evt.target.name          
        field[fieldName] =  evt.target.value;

        let dejaexist = false;
        for (var key in this.listmodif) {
          var obj = this.listmodif[key];
          for (var prop in obj) {
       
            if (prop === fieldName) {
              obj[prop] = evt.target.value;
              dejaexist = true;
            }               
                         
          };  
        }
        if (!dejaexist) {
          this.listmodif.push (field);
        }
   

       
        const appel = Object.assign({}, this.state.appel, field)
        this.setState({
          appel: appel,
         })

  

    }

      updateSelectedDateTime = event => {
       
        this.setState({ selectedDateTime: event.target.value });
     
        var field = {}
        var fieldName= event.target.id;  
        field[fieldName] = new Date(event.target.value);
        
        var appel = Object.assign({}, this.state.appel, field)   
        fieldName = 'date_iso';
        field[fieldName] = event.target.value +'T00:00:00Z';    
        appel = Object.assign({}, this.state.appel, field)    
        this.setState({
            appel: appel,
        })
      
      }

      handleSelectedRow = (row)  => {
  
      }       
     
      handlePageChange = (event) => {
  
    
    }

      handleClickRow = (event)  => {
       
        if (!this.state.editMode)
        {
          
          const appel = this.state.Rows[event.id-1];        
         
      
          this.setState({
            newid:event.id,
            idencours:50, 
            appel : appel,      
          });
      
        
          
      
        }
        else {
      
          if (this.state.idencours!==50) {

          }
          else {
        
          this.setState({
            newid:event.id,
            idencours:event.id, 
           
          });
        }
       }
     
      };


      handleOverRow = (event)  => {
 
        /*
        this.setState({
          newid:event.id,
        
        });
        */
      } 

      handleCellChange =(event)  => {
     
   
        
      } 
      
      handleCellChangeCommitted =(event)  => {
 
       
       
      } 

      setSelection=(selections) => {
           
      
      }
     


  Columns = [
  { field: 'numero',
    headerName: 'Numero',
    headerClassName: 'super-app-theme--header',  
    headerAlign: 'left',
    sortable:false,
    hide:true,
    width: 100, editable: false,
  },
  { field: 'date_appel',
   headerName: 'date appel', 
   headerClassName: 'super-app-theme--header',  
   type: 'dateTime', 
   sortable:false,width: 170,
   editable: true 
  },
  {
    field: 'motif_appel',
    headerName: 'Motif',
    headerClassName: 'super-app-theme--header', 
    type: 'text',
    width: 80,
    sortable:false,
    editable: true,
  },
  {
    field: 'libel_appel',
    headerName: 'Appel',
    headerClassName: 'super-app-theme--header', 
    type: 'text',
    width: 170,
    sortable:false,
    editable: true,
  },
  {
    field: 'ordre_appel',
    headerName: 'Ordre',
    headerClassName: 'super-app-theme--header', 
    type: 'number',
    width: 80,
    sortable:false,
    editable: true,
  },
  {
    field: 'login_appel',
    headerName: 'Login',
    headerClassName: 'super-app-theme--header', 
    type: 'text',
    width: 80,
    sortable:false,
    editable: true,
  }
];


      
     render() {
    
           const selectionModel = [this.state.newid];
          
  
      
            return (
              <Container className={this.props.classes.root}>
              {!this.state.ajoutPossible ? 
                <div className="outlined_encours">  
                    <CircularProgress className="margin-t-15 margin-l-5" />
                    <label className="label margin-t-15">  
                        Modification en cours - Action Non Autorisée                    
                    </label>                        
                    <ResizableIconButton   title="Cliquer pour annuler"
                        onClick={this.resetClick}>
                        <CancelIcon   style= {{ color: "#ff0000",visibility:"visible", fontSize: 48}} />                                                                 
                    </ResizableIconButton>
                </div>:
                <div>
              
                  <Container>
                    
               
                  
                    <SplitPane split="vertical" defaultSize="60%" >
                         <div className = "boxRight" >  
                            <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>
                               
                                <div style={(!this.state.addMode && !this.state.editMode)?{ color: "#ffffff",visibility:"hidden",height:0 }
                                            :{ color: "#000000",visibility:"visible"}} >

                                      
                                      <FormControl className = {this.props.classes.formControlInterv} >      
                                        <div>
                                        <label className="label margin-l-5">
                                        {this.state.deleteMode ? 
                                            "Suppression de l'Appel :": "Modification de l'Appel " }
                                          </label>
                                       </div>   
                                       <div>            
                                        <label className="label margin-l-5">
                                            Date de l'appel : 
                                          </label>
                                        <TextField
                                            id="date_appel"       
                                            type="date"    
                                            label ={this.state.selectedDateTime?'':'date nulle'}   

                                            disabled={this.state.deleteMode}                                         
                                            value={this.state.selectedDateTime}
                                            onChange={this.updateSelectedDateTime}       
                                            className={this.state.selectedDateTime?this.props.classes.textFieldDate:this.props.classes.textFieldDateNulle}                                             
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            inputProps={{ min: this.state.datedujour, max: this.state.datemaxi ,style: {fontSize: 14,height:12,fontWeight:"bold"}}} 
                                          /> 
                                        </div>  
                                        <div>
                                          <label className="label margin-l-5">
                                            Ordre appel :
                                            <input  className= "inputappel width60 gras"
                                                name="ordre_appel"
                                                type="number"  min={0}
                                                disabled={true}   
                                                value={!this.state.addMode?this.state.appel.ordre_appel:this.state.ordremax+1}
                                             
                                                onChange={this.handleInputChange} />
                                            </label>
                                        </div>
                                        <div>
                                        <label className="label margin-l-5">
                                            Type d'appel :
                                            <select name="motif_appel" value={this.state.appel.motif_appel}
                                            disabled={this.state.deleteMode} className= "inputinterv width200 gras"
                                          
                                                onChange={this.handleInputChange}>
                                                {this.typemotif.map((option) => (
                                                    <option value={option.code}>{option.label}</option>
                                                    ))}
                                            </select> 
                                        </label>       
                                        </div>
                                       
                                        <div>
                                        <label className="label margin-l-5">
                                            Appel effectué par :
                                            <select name="login_appel" value={this.state.appel.login_appel}
                                            disabled={this.state.deleteMode} className= "inputinterv width200 gras"
                                                onChange={this.handleInputChange}>
                                                {this.typeutilisateur.map((option) => (
                                                    <option value={option.code}>{option.label}</option>
                                                    ))}
                                            </select> 
                                        </label>       
                                        </div>
                                        <div>
                                        <label className= {(localStorage.getItem('Suiviclients_Search_modifEncours') !== 0)? "label-hidden": "label-red"}> 
                                          Email non valide !  
                                        </label>
                                        </div>

                                      </FormControl>
                                    
                                </div> 
                                
                                <div style={(this.state.addMode || this.state.editMode)?{ color: "#ffffff",visibility:"hidden" }
                                            :{ color: "#000000",visibility:"visible"}} >
                               
                              
                                   
                                    <Grid item lg className={this.props.classes.classgrid}>
                                        <DataGrid className={this.props.classes.root} id="gridinterv"
                                          components={{
                                            NoRowsOverlay: CustomNoRowsOverlay,
                                            
                                          }}
                                          rows={this.state.editMode?this.state.Rows:this.state.Rows}                                            
                                          columns={this.Columns} 
                                          disableColumnMenu
                                          headerHeight={45}
                                          rowHeight = {50}
                                          isRowSelectable={(params) => true}
                                          onRowSelected={(e) => this.handleSelectedRow(e)} 
                                          onSelectionChange={(newSelection) => {
                                                
                                              
                                            }}
                                          onRowOver=  {(rowData) => this.handleOverRow(rowData.row)} 
                                          hideFooter
                                          onRowClick={(rowData) => this.handleClickRow(rowData.row)} 
                                          onEditCellChange ={(cellData) => this.handleCellChange(cellData)} 
                                          onEditCellChangeCommitted ={(cellData) => this.handleCellChangeCommitted(cellData)} 
                                          selectionModel ={selectionModel}
                                          isCellEditable={(params) => params.row.id === this.state.idencours}
                                          onPageChange={(page) => this.handlePageChange(page)} >
                                        </DataGrid> 
                                                                
                                    </Grid>      
                                
                                </div>
                                
                            
                            </CustomScrollbars>                   
                        </div>            
                        <div className= {this.state.editMode?"boxRight":"boxAppel" }>
                            <CustomScrollbars1 autoHide autoHideTimeout={500} autoHideDuration={200}>
                            
                                <Toolbar className={this.props.classes.toolbar}>
                                      
                                        <Typography className={this.props.classes.title} variant="h5">Appel</Typography>
                                        <ButtonOw 
                                            title='Modificationn' 
                                            onClick={this.clickEdit}>
                                            <EditIcon style={(this.state.editMode || this.state.nbrows===0)?{ color: "#ffffff",visibility:"hidden" }
                                            :{ color: "#000000",visibility:"visible"}} />
                                        </ButtonOw>
                                        <ButtonOw 
                                            title='Ajouter un appel'   
                                            disabled= {this.state.editMode}                                                  
                                            onClick={this.clickAjout}>
                                            <AddIcon style={this.state.editMode?{ color: "#ffffff",visibility:"hidden" }:{ color: "#000000",visibility:"visible"}} />
                                          </ButtonOw> 
                                          <ButtonOw 
                                            title='Supprimer un appel'   
                                            disabled= {this.state.editMode}                                                  
                                            onClick={this.clickDelete}>
                                            <DeleteIcon style={(this.state.editMode || (this.state.nbrows ===0))?{
                                               color: "#ffffff",visibility:"hidden" }:{ color: "#000000",visibility:"visible"}} />
                                          </ButtonOw>   
                                        <ButtonOw 
                                            title={this.state.deleteMode?"Confirmer la Suppression":"Valider" }
                                            onClick={this.clickValid}>

                                        {this.state.deleteMode ? 
                                        <DeleteIcon style={this.state.editMode?{ color: "#f0621f",visibility:"visible" }
                                          :{ color: "#f0621f",visibility:"hidden"}} />
                                          :
                                          <DoneIcon style={this.state.editMode?{ color: "#f0621f",visibility:"visible" }
                                          :{ color: "#f0621f",visibility:"hidden"}} />
                                        }  
                                        </ButtonOw>
                                        <ButtonOw 
                                            title='Annuler' 
                                        disabled= {!this.state.editMode}  
                                            onClick={this.clickAnnul}>
                                            <CancelIcon style={this.state.editMode?{ color: "#f0621f",visibility:"visible" }
                                            :{ color: "#f0621f",visibility:"hidden"}} />
                                        </ButtonOw>
                                        
                                        <ButtonOw 
                                            title='Recherche' 
                                            disabled={this.state.editMode}  
                                            onClick={this.clickSearch}>
                                            <SearchIcon style={{ color: "#0ee73f",visibility:"hidden"} }/>
                                        </ButtonOw>
                                                              
                                        
                                        
                                </Toolbar>                           
                                
                         
                                <FormControl fullWidth 
                                className={this.state.editMode?this.props.classes.formControlInfosModif:this.props.classes.formControlInfos}>
                                  <TextField 
                                   style ={this.state.editMode?{backgroundColor:"#ffffff"}:{backgroundColor:"#A0F950"}}
                                   variant ={this.state.editMode?"outlined":"filled"} 
                                     name="comment_appel"                                                                          
                                     disabled={this.state.deleteMode || (!this.state.editMode && (this.state.nbrows ===0))} 
                                     multiline
                                     inputProps={{style: {fontWeight:"bold"}}} 
                                     rowsMax={10}
                                     value={this.state.appel.comment_appel}
                                     onChange={this.handleInputChange}
                                  />
          
                                </FormControl>
                              

                          </CustomScrollbars1>
                        </div>
                  
                    
                    </SplitPane>

                
                   
                  </Container>
                </div>  
              }    
              </Container>  
       
         )
    }     
}  


const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 10,
      backgroundColor: 'rgba(252, 36, 89, 0.8)',
      width:10,
      height : 10,
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  
  const renderThumb1 = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 10,
      backgroundColor: 'rgba(22, 36, 89, 0.8)',
      width:10,
      height : 10,
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  const CustomScrollbars = props => (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...props}
    />
  );


  const CustomScrollbars1 = props => (
    <Scrollbars
      renderThumbHorizontal={renderThumb1}
      renderThumbVertical={renderThumb1}
      {...props}
    />
  );

  const mapStateToProps = state => ({
    isConnected : state.identity.isConnected,
    numClientEnCours : state.identity.numClientEnCours,
    clients : state.identity.clients,
    typemotifs :  state.identity.typemotifs,
    identityLoading : state.identity.loading,
    loading : false,
   
})
      



const mapDispatchToProps = (dispatch) => {
    return {
      getListeAppelsparClient : bindActionCreators (getListeAppelsparClient, dispatch),   
      updateAppel : bindActionCreators (updateAppel, dispatch),  
      deleteAppel : bindActionCreators (deleteAppel, dispatch),    
      insertAppel : bindActionCreators (insertAppel, dispatch),   
      setTitle : bindActionCreators (setTitle, dispatch),
    }  
    
  }

    const classes = theme => ({
      bkcol: {
        /*
        backgroundColor: '#DBFCFB',// bleu pastel
        backgroundColor: '#92ECEB',// bleu ciel
        backgroundColor: '#F9D7D7',// rose pastel 
        backgroundColor: '#F49090',// rose 
        backgroundColor: '#BBD3F7',// bleu 
        backgroundColor: '#93F7E1', 
        backgroundColor: '#CFC961',// marron
        backgroundColor: '#A0F950',//vert
        backgroundColor: '#5ef2e2', 
       */
      },
      
     
      classgrid: {
        '& .super-app-theme--header': {
          backgroundColor: theme.bkcolor.rosepastel,
          fontWeight: 'bold',
          color:theme.bkcolor.noir,
          
        },
        scrollBehavior: 'smooth',
        flexGrow: 1,
        backgroundColor: theme.bkcolor.vertpastel,
        height:'35vh',
      },
      root: {
        '& .zMuiDataGrid-row': {
          display: 'flex',
          width: 'fit-content',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          '&.Mui-selected': {
            backgroundColor: theme.bkcolor.marron,
            color:theme.bkcolor.blanc,
          },
          '@media (hover: none)': {
            backgroundColor:theme.bkcolor.rose,
           
          },
        },
        
        flexGrow: 1,
        backgroundColor: theme.bkcolor.vertpastel,
        height:'35vh',
      },    
     
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: theme.bkcolor.rosepastel,
        height:45,
        minHeight : 20,
        
      },
        title: {
            color:'inherit',
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            
         },       
        memo: {
            textAlign: 'justify',
            marginBottom: '1.3em',
        },
        btn_class :{
            color:"primary",
        },          
        customColor: {
          // or hex code, this is normal CSS background-color
          color:theme.bkcolor.rosepastel,
          backgroundColor: theme.bkcolor.rosepastel,
          height:30,         
          maxHeight: 300,
        },   
        formControl: {         
          color: 'primary',    
          margin: theme.spacing(2),   
         // backgroundColor: theme.bkcolor.rosepastel,  
        },    
        formControlInterv: {         
          color: 'primary',    
          margin: theme.spacing(2),                
         // backgroundColor: theme.bkcolor.rosepastel,      
       },   
       formControlInfos: {         
         color: 'primary',    
         margin: theme.spacing(2),   
         backgroundColor: theme.bkcolor.vertpastel,      
       
       },   
      formControlInfosModif: {         
       color: 'primary',    
       margin: theme.spacing(2),   
      // backgroundColor: theme.bkcolor.rosepastel,      
       } ,
        textField: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          width:220,
        },
        textFieldDate: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(2),
          marginBottom:theme.spacing(1),
          marginTop :5,
          width:130,
          backgroundColor: "white", 
          color: "black",   
        },
        grid : {
        '&& .MuiDataGrid-hover': {
            cursor: 'pointer',
          },
          height:'90vh',
          backgroundColor: theme.bkcolor.vertpastel,
          '& .MuiDataGrid-cellEditable': {
            backgroundColor: theme.bkcolor.rose,
          },
          '&.MuiDataGrid-row.Mui-odd': {
            backgroundColor: theme.bkcolor.rose,
          },          
          '& .MuiDataGrid-row-selected': {
            color: 'white',
            backgroundColor: theme.bkcolor.rose,
           },
           '& .Mui-cellEditable': {
            backgroundColor: theme.bkcolor.rose,
          },
          '& .MuiTableRow-selected': {
            color: 'white',
            backgroundColor: theme.bkcolor.rose,
           },
  
         },
      
        formtextfield: {
          margin: theme.spacing(1),
        },
        textfieldinterv: {
          backgroundColor: theme.bkcolor.vertpastel,
        },
        headergrid : {
          backgroundColor: theme.bkcolor.rosepastel,
        },
        superappthemeheader: {
          backgroundColor: 'rgba(255, 7, 0, 0.55)',
        },
        tableRow: {
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "purple",
            "& > .MuiTableCell-root": {
              color: "yellow"
            }
          }
        }
         
         
})

export default connect ( mapStateToProps, mapDispatchToProps) (withStyles(classes) (AppelForm))

