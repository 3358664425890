import { IconButton, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

/**
 * Bouton Optimal Web
 * @param   {string}    title
 * @param   {function}  onClick
 * @param   {element}   children  
 * 
 */
class ButtonOw extends Component {
  static propTypes = {
    title : PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
   // onClick : PropTypes.func.isRequired,
    children : PropTypes.element.isRequired,
    disabled: PropTypes.bool,
  }

  render() {
    const {title, onClick, color, disabled, children, ...props} = this.props

    let sColor = color ? color : 'primary'

    if (!disabled) {
      return (
        <Tooltip title={title}>
          <IconButton onClick={onClick} color={sColor} {...props}>
              {children}
          </IconButton>
        </Tooltip>
      )

    } else {
      return (
        <IconButton onClick={onClick} color={sColor} disabled={disabled} {...props}>
            {children}
        </IconButton>
      )
    }
    
  }
}

const classes = {
  root: {
   
  }
}

export default withStyles (classes) (ButtonOw)
