import React, { Component } from 'react'
import Logo from './athmo.gif'
class AthmoIcon extends Component {
  render() {
    return (
      <img src={Logo} alt="Logo Athmo" width='30' height='19' />
    )
  }
}

export default AthmoIcon
