import axios from 'axios';

export const CANCELLED = 'CANCELLED'




class BaseService {

    /**
     * Jeton d'annulation
     */
    sourceToCancel = null

    constructor() {
        this.sourceToCancel = axios.CancelToken.source()
    }
   

    getSourceToCancel = () => {
        return this.sourceToCancel
    }

    getOptions = () => {
        let option = null;
        let token = sessionStorage.getItem('TOKEN')
        if (token) {
            option = {
                headers : {
                    'Authorization' : `Bearer ${token}` 
                },
                cancelToken: this.sourceToCancel.token
            }
        }
      
        return option;

    }

    /**
     * Conservation d'une trace des appels dans la console du client
     * Usage potentiel par le support
     * @param {string} url 
     * @param {string} method 
     * @param {*} data 
     */
    log (url, method, data) {
      // console.log(`${method}: ${url}`, data);
    }
    async get(url) {
        this.log(url, 'GET');
        try {            
            const resp = await axios.get(url, this.getOptions());
          
            return this.promiseResolve(resp);
        } catch (err) {
            return this.promiseReject(err);
        }
    }
   
    gget(url) {
        this.log(url, 'GET');
        return axios.get(url, this.getOptions ())
        .then(this.promiseResolve)
        .catch(this.promiseReject);
    }
    async post(url, obj) {
        
        this.log(url, 'POST', obj);
        try {
            const resp = await axios.post(url, obj, this.getOptions());
            return this.promiseResolve(resp);
        } catch (err) {
            return this.promiseReject(err);
        }
    }

    async put(url, obj) {
        this.log (url, 'PUT', obj);
        try {
            const resp = await axios.put(url, obj, this.getOptions());
            return this.promiseResolve(resp);
        } catch (err) {
            return this.promiseReject(err);
        }
    }

    async delete(url) {
        this.log (url, 'DELETE');
        try {
            const resp = await axios.delete(url, this.getOptions());
            return this.promiseResolve(resp);
        } catch (err) {
            return this.promiseReject(err);
        }
    }

    oldget(url) {
        this.log(url, 'GET');
        return axios.get(url, this.getOptions ()).then(this.promiseResolve).catch(this.promiseReject);
    }

    oldpost(url, obj) {
        this.log(url, 'POST', obj);
        return axios.post(url, obj, this.getOptions ()).then(this.promiseResolve).catch(this.promiseReject);
    }

    oldput(url, obj) {
        this.log (url, 'PUT', obj);
        return axios.put(url, obj, this.getOptions ()).then(this.promiseResolve).catch(this.promiseReject);
    }

    olddelete(url) {
        this.log (url, 'DELETE');
        return axios.delete(url, this.getOptions ()).then(this.promiseResolve).catch(this.promiseReject);
    }
    /**
     * Gestion de l'erreur lors de l'appel de l'API
     * @param {string} err 
     */
    promiseReject(err) {
        
        /*
         * cas particulier : accès interdit car non authentifié
         */
        if (err.response && err.response.status === 401) {
            console.warn('Access denied. Redirect to /')
            sessionStorage.removeItem('TOKEN')
            sessionStorage.removeItem('idClient')
            window.location.assign('/');
        }
        
        /*
         * cas particulier = annulation
         */
        if (axios.isCancel(err)) {
            console.warn(err.message)
            return Promise.reject('CANCELLED')    /* on indique que l'on ne doit pas tenir compte des données car annulation */
        }

        /*
         * erreur
         */
        return Promise.reject(err.message || err);
        
    }

    /**
     * Gestion du bon déroulement de l'appel à l'API
     * @param {*} resp 
     */
    promiseResolve(resp) {
        
        return Promise.resolve(resp.data);
    }

}
export default BaseService;