
import {
  CircularProgress, Container,
  Grid, Toolbar,
  Typography, withStyles
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/AddCircleOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import SearchIcon from '@material-ui/icons/Search';
import 'bootstrap/dist/css/bootstrap.min.css';
import fr from 'date-fns/locale/fr';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import SplitPane from "react-split-pane";
import { bindActionCreators } from 'redux';
import ResizableIconButton from '../../../core/components/window/ResizableIconButton';
import '../../../stylesheets/splitpane.css';
import '../../../stylesheets/tabpanel.css';
import { getListeLastIntervParClient, insertInterv, updateInterv } from '../../redux/actions/ClientActions';
import { setTitle } from '../../redux/actions/WindowAppActions';
import ButtonOw from '../window/ButtonOw';
import "./datetimepicker.css";



registerLocale('fr', fr)


  
  function CustomNoRowsOverlay() {
    return (
      <GridOverlay className={classes.root}>
         <div className={classes.label}>Aucune Intervention</div>
     </GridOverlay>
  );
}

class IntervForm extends React.Component {
  constructor(props) {
    super(props);


  this.state = {  
    etat :0,
    editMode:false,
    addMode:false,
    reaffiche  : false,    
    affichetout : false,
    numclient:0,
    newid:1,
    idencours:50,
    nbrows :0,
    ajoutPossible :1,
    selectedDateTime: "2017-05-24T10:30:00",
    selectedDate :new Date('2014-08-18T21:11:54'),
    startDate:new Date(),
    zinterv:{
      id:1,
      numero :0,
      date_iso : new Date(),
      date_interv : new Date(),
      code_interv:'KFIC',
      libel_interv:"Pb en Cours",
      dur_interv:1,
      comment_interv:'',
      login_interv:'NME',
  },
    interv:{
        id:0,
        numero :0,
        date_iso : new Date(),
        date_interv : new Date(),
        code_interv:'KFIC',
        libel_interv:this.recupLibel("KFIC"),
        dur_interv:12,
        comment_interv:'',
        login_interv:'NME',
    },
    intervnull:{
      id:0,
      numero :0,
      date_iso : new Date(),
      date_interv : new Date(),
      code_interv:'KFIC',
      libel_interv:this.recupLibel("KFIC"),
      dur_interv:1,
      comment_interv:'',
      login_interv:'NME',
  },
    Rows:[],
    
    newRows: [
      {
        id:1,
        numero: 5049,
        date_iso:new Date("2000-01-01T00:00"),
        date_interv:new Date("2000-01-01T00:00"),
        code_interv: "V",
        libel_interv: "",
        dur_interv: "0",
        login_interv:"",
        comment_interv:"i",
        
      },
    ]
   
   }
   this.handleInputChange = this.handleInputChange.bind(this);
   this.afficheInterv = this.afficheInterv.bind(this);
   
    
  }
   typeinterv = [];
   typeintervactif = [];
   ztypeinterv = [
      {label:"", code:"0"},
      {label:"Dernier MAJ reçue", code:"DENV"},
      {label:"Inter clôturée", code:"OFIC"},            
      {label:"Pb en Cours", code:"KFIC"},
      {label:"Installation", code:"INST"},
      {label:"PB logiciel Résolu", code:"OLOG"},
      {label:"Pb logiciel", code:"KLOG"},
     ];

     typeutilisateur = [
      {label:"", code:"0"},
      {label:"Nicolas MEDINA", code:"NME"},
      {label:"Jean Jacques BARET", code:"JJB"},
      {label:"Jean Philippe", code:"JPB"},
      {label:"Claude CASTELLO", code:"CLC"},
    ];
    
    listmodif = [];


    afficheInterv (values) {
     
      const nbrows = values.length;
  
      let items = [];
      if (nbrows ===0) {
        let interv0 = this.state.intervnull;
        this.setState({
          Rows:items,
          interv:interv0,
          nbrows :0,
        })

      } 
      else {

      
          for (var i=0;i<nbrows;i++) {
            let tmpdat = values[i].date_interv;               
            let datz = tmpdat.substr(3,2)+'/'+ tmpdat.substr(0,2)+'/'+tmpdat.substr(6,13);
            let item ={
              id:i+1,
              numero :values[i].numero,      
              date_iso : new Date(datz).toISOString(),        
              date_interv :new Date(datz).toLocaleString().slice(0,18),
              code_interv:values[i].code_interv,
              libel_interv:this.recupLibel(values[i].code_interv),
              dur_interv:values[i].dur_interv,
              comment_interv:values[i].comment_interv,
              login_interv:values[i].login_interv  
            };       
       
            items.push(item);
          }
        
      
          this.setState({
            Rows:items,
            interv:items[this.state.newid -1],
            nbrows :nbrows,
          })


      }
        
      try {
      
        this.setState({
          selectedDateTime : (this.state.interv.date_interv).toISOString().slice(0, 16),
        })
  
      } catch(e) {
        
        this.setState({
          selectedDateTime :"",
        })
      
      }
      
    }

    recupLibel (code) {
  
      if (code !=='') {
        const resu = this.typeinterv.find(item =>item.code === code) ;   
      ;
        if (resu !== undefined) {
          return resu.label;
        }
        else return '';
      }
      else return '';  
     }

    componentDidMount() {
      this.typeinterv = this.props.typeintervs;
      this.typeintervactif = this.props.typeintervsactif;
   

    
      this.setState({
        numclient :this.props.numclient,
       },()=>{
        var numsearch = parseInt(this.props.numclient);
        this.props.getListeLastIntervParClient (numsearch,10,0)
        .then ( (response) => {
       
         this.afficheInterv(response.value);
         
      })
    
      }, (error) => {
        console.warn('error ',error);
        
      })

    }
       
     

      
      
        
    
   
    componentDidUpdate(prevProps, prevState) {  
    
       
      if ((this.state.numclient !== this.props.numclient) || 
         (this.state.reaffiche)) {
         var tout = this.state.affichetout;  
         var nb =10;
         if (tout) { nb =150;}
        this.setState({
          numclient :this.props.numclient,
          reaffiche: false,
          affichetout :false,
          newid:1,
         },()=>{
          var numsearch = parseInt(this.props.numclient);
          this.props.getListeLastIntervParClient (numsearch,nb,0)
          .then ( (response) => {
       
           this.afficheInterv(response.value);
          
    
        })
      
        }, (error) => {
          console.warn('error ',error);
          
        })
      }
    }



   

    clickEdit= () => {   

      const displayCode = (localStorage.getItem('Suiviclients_Search_ajoutPossible') === '1') 
      if (displayCode) {
 
            this.setState({
              editMode: true,  
              addMode: false,       
              idencours:this.state.newid,            
            })

            localStorage.setItem('Suiviclients_Search_modifEncours', 1);
            
          var datx = this.state.interv.date_iso;
          
         
            var tzoffset = (new Date(datx)).getTimezoneOffset(); //offset in minues
         
            var datz = new Date(datx);
            var datz1 = new Date(datx);
       
            var mn = new Date(datz).getMinutes();
        
            datz.setMinutes(mn -tzoffset);
        
            var localISOTime = new Date(datz).toISOString().slice(0, 19);
       
     
            try {
            // this.state.selectedDateTime = new Date((this.state.interv.date_iso)).toISOString().slice(0, 16)
            this.setState({
              selectedDateTime : localISOTime,
              startDate : datz1,
            })    
        
            } catch(e) {
              this.setState({
                selectedDateTime :"",
                startDate :"",
              })
              
            }
      
        }
        else {
          this.setState({
            ajoutPossible :false,
          },()=>{
           
          
          })
        }
      
    }

     clickAjout= () => {   
      const displayCode = (localStorage.getItem('Suiviclients_Search_ajoutPossible') === '1') 
      if (displayCode) {
        var dateiso = new Date().toISOString(); 
        var datelocale = new Date().toLocaleString();
        var tmp = this.state.zinterv;
         tmp.numero = this.state.numclient;
         tmp.date_iso = dateiso;
         tmp.date_interv =datelocale;       
         var len = dateiso.length;       
         dateiso = dateiso.substring(0,len-4);       
         dateiso +='000Z';       
        this.setState({
          zinterv : tmp,
        })
        localStorage.setItem('Suiviclients_Search_modifEncours', 1);;
        const interv = this.state.zinterv;
      
  
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, 19);
        var datj = new Date(Date.now());
 
        this.setState({
          addMode: true,   
          editMode:true,      
          idencours:this.state.newid,  
          
        })
  
        this.setState({
          interv: interv,   
          selectedDateTime : localISOTime, 
          startDate: datj,
        })
     
      
      }
      else {
        this.setState({
          ajoutPossible :false,
        },()=>{
         
        
        })

      }
   
   
     }

    clickTout = () => {

      this.setState({
        editMode: false,
        addMode:false,
        idencours :50,  
        reaffiche:true,  
        affichetout :true,
       })
    } 

    clickValid= () => {    

      var field = {}
      var fieldName = "numero";
      field[fieldName] =  this.state.interv.numero;
      this.listmodif.push(field);
      fieldName = "date_interv";
      
          
var datx= new Date(this.state.interv.date_iso);
var tzoffset = datx.getTimezoneOffset(); //offset in minues
var mn = datx.getMinutes();
datx.setMinutes(mn -tzoffset);
var datz = new Date(datx).toISOString(); 
var len = datz.length;
datz = datz.substring(0,len-4);
datz +='000Z';


      field[fieldName] =  datz;     
      this.listmodif.push(field);
      if (this.state.addMode) {
        fieldName = "dur_interv";
        field[fieldName] =  this.state.interv.dur_interv;
        this.listmodif.push(field);
        fieldName = "code_interv";
        field[fieldName] =  this.state.interv.code_interv;
        this.listmodif.push(field);
        fieldName = "comment_interv";
        field[fieldName] =  this.state.interv.comment_interv;
        this.listmodif.push(field);
        fieldName = "login_interv";
        field[fieldName] =  this.state.interv.login_interv;
        this.listmodif.push(field);
      }

      var changes = {};
      for (var key in this.listmodif) {
        var obj = this.listmodif[key];
        for (var prop in obj) {
   
          changes[prop] = obj[prop];
          }            
        };  
     
      while(this.listmodif.length > 0) {
        this.listmodif.pop();
      }
      let interv = this.state.interv;
      interv.libel_interv = this.recupLibel(interv.code_interv);
     
      let values =  this.state.Rows;    
    
      if (this.state.addMode) {
        interv.numero = this.state.numclient;
      }

      values = values.map((x) => x);
      values[this.state.newid-1] = interv;
        
      if (!this.state.addMode) {

        this.props.updateInterv (changes)
        .then ( (resu) => {  
      
              
          if (resu.value ===1) {        
                                          
              localStorage.setItem('Suiviclients_Search_modifEncours', 0);
              
              this.setState({
                  editMode: false,
                  addMode:false,
                  idencours :50,       
                  Rows : values,
              }) 
            
                        
            }
            else {
              
            }
        }
        , (error) => {
          console.warn('Erreur update', error)
          
        })
      }
      else
      {
        this.props.insertInterv (changes)
        .then ( (resu) => {     
          
           if (resu.value === true) {        
              localStorage.setItem('Suiviclients_Search_modifEncours', 0);
              
              this.setState({
                  editMode: false,
                  addMode:false,
                  idencours :50,       
                  Rows : values,
                  reaffiche:true,   // il faut recharger la liste des interv
              })   
             
            }
            else {
              
            }
        }
        , (error) => {
          console.warn('Erreur update', error)
          
        })

      }
    
    }

   

    

   
    clickSearch= () => {

    }

    clickAnnul= () => {    
      let values =  this.state.Rows;  
      this.setState({
        editMode: false,   
        addMode:false,  
       
        idencours:50,     
        ajoutPossible :1,  
      })
      if (values.length >0) {
        this.setState({
           interv : values[this.state.newid-1] ,   
        })
      }
      else {
        //this.state.interv.comment_interv ='';
        this.setState(Object.assign(this.state.interv,{comment_interv:''}));
      }

      localStorage.setItem('Suiviclients_Search_modifEncours', 0);
      
    }

    resetClick = () => {

    
      this.setState({
        ajoutPossible: true,
      })  
      this.clickAnnul();
    }

    handleInfosChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      } 
     
      handleInputChange(evt) {
        const field = {}
        const fieldName= evt.target.name          
        field[fieldName] =  evt.target.value;

        let dejaexist = false;
        for (var key in this.listmodif) {
          var obj = this.listmodif[key];
          for (var prop in obj) {
       
            if (prop === fieldName) {
              obj[prop] = evt.target.value;
              dejaexist = true;
            }               
                         
          };  
        }
        if (!dejaexist) {
          this.listmodif.push (field);
        }
   

       
        const interv = Object.assign({}, this.state.interv, field)
        this.setState({
          interv: interv,
         })

  

    }

    handleChange = date => {

      var date_iso = date.toISOString();
      var len = date_iso.length;  
      date_iso = date_iso.substring(0,len-4);       
      date_iso +='000Z';   
 
      this.setState ({
        startDate : date,
      })
      var field = {}
      var fieldName= 'date_interv';  
      field[fieldName] = date;

      let dejaexist = false;
      for (var key in this.listmodif) {
        var obj = this.listmodif[key];
        for (var prop in obj) {
     
          if (prop === fieldName) {
           // obj[prop] = evt.target.value;
            dejaexist = true;
          }               
                       
        };  
      }
      if (!dejaexist) {
        this.listmodif.push (field);
      }
 
     
      var fieldiso = {};
      fieldName= 'date_iso';
      fieldiso[fieldName] = date_iso;

      var interv = this.state.interv;
      interv.date_interv = date;
      interv.date_iso = date_iso;
      
    this.setState({
      interv: interv,
     })


    }


      updateSelectedDateTime = evt => {

        this.setState({ selectedDateTime: evt.target.value });
     
        var field = {}
        var fieldName= evt.target.id;  
        field[fieldName] = new Date(evt.target.value);

        let dejaexist = false;
        for (var key in this.listmodif) {
          var obj = this.listmodif[key];
          for (var prop in obj) {
       
            if (prop === fieldName) {
             // obj[prop] = evt.target.value;
              dejaexist = true;
            }               
                         
          };  
        }
        if (!dejaexist) {
          this.listmodif.push (field);
        }
   
        var datz = evt.target.value;
        var date_iso = new Date(datz).toISOString();

        var fieldiso = {};
        fieldName= 'date_iso';
        fieldiso[fieldName] = date_iso;

        var interv = this.state.interv;
        interv.date_interv = new Date(evt.target.value);
        interv.date_iso = date_iso;
        
      this.setState({
        interv: interv,
       })


      }

      handleSelectedRow = (row)  => {
  
      }       
     
      handlePageChange = (event) => {
  
    
    }

      handleClickRow = (event)  => {
       
        if (!this.state.editMode)
        {
          
          const interv = this.state.Rows[event.id-1];        
         
      
          this.setState({
            newid:event.id,
            idencours:50, 
            interv : interv,      
          });
      
        
          
      
        }
        else {
      
          if (this.state.idencours!==50) {

          }
          else {
        
          this.setState({
            newid:event.id,
            idencours:event.id, 
           
          });
        }
       }
     
      };


      handleOverRow = (event)  => {
 
        /*
        this.setState({
          newid:event.id,
        
        });
        */
      } 

      handleCellChange =(event)  => {
     
   
        
      } 
      
      handleCellChangeCommitted =(event)  => {
 
       
       
      } 

      setSelection=(selections) => {
           
      
      }
     


  Columns = [
  { field: 'numero',
    headerName: 'Numero',
    headerClassName: 'super-app-theme--header',  
    headerAlign: 'left',
    sortable:false,
    hide:true,
    width: 100, editable: false,
  },
  { field: 'date_interv',
   headerName: 'date intervention', 
   headerClassName: 'super-app-theme--header',  
   type: 'dateTime', 
   sortable:false,width: 180,
   editable: true 
  },
  {
    field: 'code_interv',
    headerName: 'Code',
    headerClassName: 'super-app-theme--header', 
    type: 'text',
    width: 80,
    sortable:false,
    editable: true,
  },
  {
    field: 'libel_interv',
    headerName: 'Intervention',
    headerClassName: 'super-app-theme--header', 
    type: 'text',
    width: 170,
    sortable:false,
    editable: true,
  },
  {
    field: 'dur_interv',
    headerName: 'Durée',
    headerClassName: 'super-app-theme--header', 
    type: 'number',
    width: 75,
    sortable:false,
    editable: true,
  },
  {
    field: 'login_interv',
    headerName: 'Login',
    headerClassName: 'super-app-theme--header', 
    type: 'text',
    width: 80,
    sortable:false,
    editable: true,
  }
];


      
     render() {
    
           const selectionModel = [this.state.newid];
          
  
      
            return (
              <Container className={this.props.classes.root}>
              {!this.state.ajoutPossible ? 
                <div className="outlined_encours">  
                    <CircularProgress className="margin-t-15 margin-l-5" />
                    <label className="label margin-t-15">  
                        Modification en cours - Action Non Autorisée                    
                    </label>                        
                    <ResizableIconButton   title="Cliquer pour annuler"
                        onClick={this.resetClick}>
                        <CancelIcon   style= {{ color: "#ff0000",visibility:"visible", fontSize: 48}} />                                                                 
                    </ResizableIconButton>
                </div>:
                <div>
              
                  <Container>
                    
               
                  
                    <SplitPane split="vertical" defaultSize="60%" >
                         <div className = "boxRight" >  
                            <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>
                               
                                <div style={(!this.state.addMode && !this.state.editMode)?{ color: "#ffffff",visibility:"hidden",height:0 }
                                            :{ color: "#000000",visibility:"visible"}} >

                                      
                                      <FormControl className = {this.props.classes.formControlInterv} >      
                                        <div>
                                        <label className="label margin-l-5">
                                            Modification de l'Intervention : 
                                          </label>
                                       </div>                
                                       <div >   
                                          <label className="label margin-l-5">
                                            Date intervention : 
                                          </label>
                                          <DatePicker className = {this.props.classes.root} 
                                              locale="fr"
                                              id="date_interv" 
                                              disabled={!this.state.addMode} 
                                              selected={ this.state.startDate }
                                              onChange={ this.handleChange }
                                              showTimeSelect                                                                               
                                              timeFormat="HH:mm:ss"
                                              timeIntervals={15}
                                              timeCaption="heure"
                                              dateFormat="dd/MM/yyyy HH:mm:ss" />
                                       </div>                                        
                                        <div>
                                        <label className="label margin-l-5">
                                            Type intervention :
                                            <select name="code_interv" value={this.state.interv.code_interv}
                                            disabled={!this.state.editMode} className= "inputinterv width200 gras"
                                          
                                                onChange={this.handleInputChange}>
                                                {this.typeinterv.map((option) => (
                                                    <option value={option.code}>{option.label}</option>
                                                    ))}
                                            </select> 
                                        </label>       
                                        </div>
                                        <div>
                                          <label className="label margin-l-5">
                                            Durée intervention :
                                            <input  className= "inputinterv width60 gras"
                                                name="dur_interv"
                                                type="number"  min={0}
                                                disabled={!this.state.editMode}                              
                                                value={this.state.interv.dur_interv}
                                                onChange={this.handleInputChange} />
                                            </label>
                                        </div>
                                        <div>
                                        <label className="label margin-l-5">
                                            Intervention effectuée par :
                                            <select name="login_interv" value={this.state.interv.login_interv}
                                            disabled={!this.state.editMode} className= "inputinterv width200 gras"
                                                onChange={this.handleInputChange}>
                                                {this.typeutilisateur.map((option) => (
                                                    <option value={option.code}>{option.label}</option>
                                                    ))}
                                            </select> 
                                        </label>       
                                        </div>
                                        <div>
                                        <label className= {(localStorage.getItem('Suiviclients_Search_modifEncours') !== 0)? "label-hidden": "label-red"}> 
                                          Email non valide !  
                                        </label>
                                        </div>

                                      </FormControl>
                                    
                                </div> 
                                
                                <div style={(this.state.addMode || this.state.editMode)?{ color: "#ffffff",visibility:"hidden" }
                                            :{ color: "#000000",visibility:"visible"}} >
                               
                              
                                   
                                    <Grid item lg className={this.props.classes.classgrid}>
                                        <DataGrid className={this.props.classes.root} id="gridinterv"
                                          components={{
                                            NoRowsOverlay: CustomNoRowsOverlay,
                                            
                                          }}
                                          rows={this.state.editMode?this.state.Rows:this.state.Rows}                                            
                                          columns={this.Columns} 
                                          disableColumnMenu
                                          headerHeight={45}
                                          rowHeight = {50}
                                          isRowSelectable={(params) => true}
                                          onRowSelected={(e) => this.handleSelectedRow(e)} 
                                          onSelectionChange={(newSelection) => {
                                                
                                              
                                            }}
                                          onRowOver=  {(rowData) => this.handleOverRow(rowData.row)} 
                                          hideFooter
                                          onRowClick={(rowData) => this.handleClickRow(rowData.row)} 
                                          onEditCellChange ={(cellData) => this.handleCellChange(cellData)} 
                                          onEditCellChangeCommitted ={(cellData) => this.handleCellChangeCommitted(cellData)} 
                                          selectionModel ={selectionModel}
                                          isCellEditable={(params) => params.row.id === this.state.idencours}
                                          onPageChange={(page) => this.handlePageChange(page)} >
                                        </DataGrid> 
                                                                
                                    </Grid>      
                                
                                </div>
                                
                            
                            </CustomScrollbars>                   
                        </div>            
                        <div className= {this.state.editMode?"boxRight":"boxInterv" }>
                            <CustomScrollbars1 autoHide autoHideTimeout={500} autoHideDuration={200}>
                            
                                <Toolbar className={this.props.classes.toolbar}>
                                        
                                        <Typography className={this.props.classes.title} variant="h5">Intervention</Typography>
                                       
                                        <ButtonOw 
                                            title='Modificationn' 
                                            onClick={this.clickEdit}>
                                            <EditIcon style={(this.state.editMode || this.state.nbrows===0)?{ color: "#ffffff",visibility:"hidden",width:0 }
                                            :{ color: "#000000",visibility:"visible"}} />
                                        </ButtonOw>
                                        <ButtonOw 
                                            title='Ajouter une intervention'   
                                            disabled= {this.state.editMode}                                                  
                                            onClick={this.clickAjout}>
                                            <AddIcon style={this.state.editMode?{ color: "#ffffff",visibility:"hidden",width:0 }:{ color: "#000000",visibility:"visible"}} />
                                          </ButtonOw> 
                                          <ButtonOw 
                                            title='Toutes les Interventions'   
                                            disabled= {this.state.editMode}                                                  
                                            onClick={this.clickTout}>
                                            <FormatLineSpacingIcon style={this.state.editMode?{ color: "#ffffff",visibility:"hidden",width:0 }:{ color: "#000000",visibility:"visible"}} />
                                          </ButtonOw> 

                                          
                                        <ButtonOw 
                                            title='Valider' 
                                            onClick={this.clickValid}>
                                            <DoneIcon style={this.state.editMode?{ color: "#f0621f",visibility:"visible" }
                                            :{ color: "#f0621f",visibility:"hidden"}} />
                                        </ButtonOw>
                                        <ButtonOw 
                                            title='Annuler' 
                                        disabled= {!this.state.editMode}  
                                            onClick={this.clickAnnul}>
                                            <CancelIcon style={this.state.editMode?{ color: "#f0621f",visibility:"visible" }
                                            :{ color: "#f0621f",visibility:"hidden"}} />
                                        </ButtonOw>
                                       
                                        <ButtonOw 
                                            title='Recherche' 
                                            disabled={this.state.editMode}  
                                            onClick={this.clickSearch}>
                                             <SearchIcon style={{ color: "#0ee73f",visibility:"hidden"} }/>
                                        </ButtonOw>
                                      
                                        
                                        
                                </Toolbar>                           
                                
                         
                                <FormControl fullWidth 
                                className={this.state.editMode?this.props.classes.formControlInfosModif:this.props.classes.formControlInfos}>
                                  <TextField 
                                   style ={this.state.editMode?{backgroundColor:"#ffffff"}:{}}
                                   variant ={this.state.editMode?"outlined":"filled"} 
                                     name="comment_interv"                                                                          
                                     disabled={!this.state.editMode} 
                                     multiline
                                     inputProps={{style: {fontWeight:"bold"}}} 
                                     rowsMax={10}
                                     value={this.state.interv.comment_interv}
                                     onChange={this.handleInputChange}
                                  />
          
                                </FormControl>
                              

                          </CustomScrollbars1>
                        </div>
                  
                    
                    </SplitPane>

                
                   
                  </Container>
                </div>  
              }    
              </Container>  
       
         )
    }     
}  


const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 10,
      backgroundColor: 'rgba(252, 36, 89, 0.8)',
      width:10,
      height : 10,
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  
  const renderThumb1 = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 10,
      backgroundColor: 'rgba(22, 36, 89, 0.8)',
      width:10,
      height : 10,
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  const CustomScrollbars = props => (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...props}
    />
  );


  const CustomScrollbars1 = props => (
    <Scrollbars
      renderThumbHorizontal={renderThumb1}
      renderThumbVertical={renderThumb1}
      {...props}
    />
  );

  const mapStateToProps = state => ({
    isConnected : state.identity.isConnected,
    numClientEnCours : state.identity.numClientEnCours,
    clients : state.identity.clients,
    typeintervs :  state.identity.typeintervs,
    typeintervsactif :  state.identity.typeintervsactif,
    typemotifs :  state.identity.typemotifs,
    identityLoading : state.identity.loading,
    loading : false,
   
})
      



const mapDispatchToProps = (dispatch) => {
    return {
      getListeLastIntervParClient : bindActionCreators (getListeLastIntervParClient, dispatch),   
      updateInterv : bindActionCreators (updateInterv, dispatch),   
      insertInterv : bindActionCreators (insertInterv, dispatch),   
      setTitle : bindActionCreators (setTitle, dispatch),
    }  
    
  }

    const classes = theme => ({
      bkcol: {
        /*
        backgroundColor: '#DBFCFB',// bleu pastel
        backgroundColor: '#92ECEB',// bleu ciel
        backgroundColor: '#F9D7D7',// rose pastel 
        backgroundColor: '#F49090',// rose 
        backgroundColor: '#BBD3F7',// bleu 
        backgroundColor: '#93F7E1', 
        backgroundColor: '#CFC961',// marron
        backgroundColor: '#A0F950',//vert
        backgroundColor: '#5ef2e2', 
       */
      },
      
      classgrid: {
        '& .super-app-theme--header': {
          backgroundColor: theme.bkcolor.bleuciel,
          fontWeight: 'bold',
          color:theme.bkcolor.noir,
          
        },
        scrollBehavior: 'smooth',
        flexGrow: 1,
        backgroundColor: theme.bkcolor.jaunepastel,
        height:'35vh',
      },
    
      root: {
        '& .zMuiDataGrid-row': {
          display: 'flex',
          width: 'fit-content',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          '&.Mui-selected': {
            backgroundColor: theme.bkcolor.marron,
            color:theme.bkcolor.blanc,
          },
          '@media (hover: none)': {
            backgroundColor:theme.bkcolor.rose,
           
          },
        },
        '&.react-datepicker__input-container input': {
          display: 'block',
          width: 200,
          backgroundCcolor:  theme.bkcolor.marron,
        },
        
        
        flexGrow: 1,
        backgroundColor: theme.bkcolor.jaunepastel,
        height:'35vh',
      },
          
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: theme.bkcolor.bleuciel,
        height:45,
        minHeight : 20,
        
      },
        title: {
            color:'inherit',
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            
         },       
        memo: {
            textAlign: 'justify',
            marginBottom: '1.3em',
        },
        btn_class :{
            color:"primary",
        },          
        customColor: {
          // or hex code, this is normal CSS background-color
          color:theme.bkcolor.rosepastel,
          backgroundColor: theme.bkcolor.rosepastel,
          height:30,         
          maxHeight: 300,
        },   
        formControl: {         
          color: 'primary',    
          margin: theme.spacing(2),   
         // backgroundColor: theme.bkcolor.rosepastel,      
        } ,
        formControlInterv: {         
          color: 'primary',    
          margin: theme.spacing(2),                
       
       },   
       formControlInfos: {         
         color: 'primary',    
         margin: theme.spacing(2),   
        // backgroundColor: theme.bkcolor.rosepastel,      
       
       },   
      formControInfosModif: {         
       color: 'primary',    
       margin: theme.spacing(2),   
      // backgroundColor: theme.bkcolor.rosepastel,      
       } ,
        textField: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          width:220,
        },
        textFieldDate: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(2),
          marginBottom:theme.spacing(1),
          marginTop :5,
          width:220,
          backgroundColor: "white", 
          color: "black",   
        },
        grid : {
        '&& .MuiDataGrid-hover': {
            cursor: 'pointer',
          },
          height:'90vh',
          backgroundColor: theme.bkcolor.jaunepastel,
          '& .MuiDataGrid-cellEditable': {
            backgroundColor: theme.bkcolor.rose,
          },
          '&.MuiDataGrid-row.Mui-odd': {
            backgroundColor: theme.bkcolor.rose,
          },          
          '& .MuiDataGrid-row-selected': {
            color: 'white',
            backgroundColor: theme.bkcolor.rose,
           },
           '& .Mui-cellEditable': {
            backgroundColor: theme.bkcolor.rose,
          },
          '& .MuiTableRow-selected': {
            color: 'white',
            backgroundColor: theme.bkcolor.rose,
           },
  
         },
     
        formtextfield: {
          margin: theme.spacing(1),
        },
        textfieldinterv: {
          backgroundColor: theme.bkcolor.jaunepastel,
        },
        
        headergrid : {
          backgroundColor: theme.bkcolor.rosepastel,
        },
        superappthemeheader: {
          backgroundColor: 'rgba(255, 7, 0, 0.55)',
        },
        tableRow: {
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "purple",
            "& > .MuiTableCell-root": {
              color: "yellow"
            }
          }
        }
         
         
})

export default connect ( mapStateToProps, mapDispatchToProps) (withStyles(classes) (IntervForm))

