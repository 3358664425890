import ClientService from '../../../services/SuiviclientsService'

/**
 * Suffixes utilisés par redux-promise-middleware
 */
export const PENDING = '_PENDING'
export const FULFILLED = '_FULFILLED'
export const REJECTED = '_REJECTED'

export const IDENT_GET_CLIENTS = 'IDENT_GET_CLIENTS'
export const IDENT_GET_CLIENTS_ACTIFS = 'IDENT_GET_CLIENTS_ACTIFS'
export const IDENT_SET_ID_CLIENT = 'IDENT_SET_ID_CLIENT'
export const IDENT_GET_TYPE_INTERV = 'IDENT_GET_TYPE_INTERV'
export const IDENT_GET_TYPE_MOTIF = 'IDENT_GET_TYPE_MOTIF'

const serviceClient = new ClientService()




export const getClients = () => ({

    type : IDENT_GET_CLIENTS,
    payload : () => {
        return serviceClient.getClients ()
    }
})

export const getClientsActifs = () => ({

    type : IDENT_GET_CLIENTS_ACTIFS,
    payload : () => {
        return serviceClient.getClientsActifs ()
    }
})

export const choisirClient = (numClient) => {

    return (dispatch, getState) => {
            
        dispatch ({
            type : IDENT_SET_ID_CLIENT,
            payload : numClient
        })
        return dispatch ({
              //  type: IDENT_SET_CLIENT,
                payload: serviceClient.setClient (numClient)
       
        }).then ( value => {           
           
        return value
        })
        
    }

}

export const updateClient = (changes) => {

    return (dispatch) => {
      
        return dispatch ({
              //  type: IDENT_SET_CLIENT,
                payload: serviceClient.updateClient (changes)
       
        }).then ( value => {           
            
        return value
        })
        
    }


}

export const insertClient = (changes) => {

    return (dispatch) => {
      
        return dispatch ({
              
                payload: serviceClient.insertClient (changes)
       
        }).then ( value => {           
           
        return value
        })
        
    }

}


export const getLastInterv = (nb) => ({

   
    payload : () => {
        return serviceClient.getLastInterv (nb)
    }
})


export const getListeLastIntervParClient = (numClient,nb,ofs) => ({

   
    payload : () => {
        return serviceClient.getListeLastIntervParClient (numClient,nb,ofs)
    }
})

export const getListeLastIntervDepuisle = (date_debut) => ({

   
    payload : () => {
        return serviceClient.getListeLastIntervDepuisle (date_debut)
    }
})


export const getListeSansIntervDepuisle = (date_debut) => ({

   
    payload : () => {
        return serviceClient.getListeSansIntervDepuisle (date_debut)
    }
})

export const getListeIntervEntreDates = (date_debut,date_fin) => ({

   
    payload : () => {
        return serviceClient.getListeIntervEntreDates (date_debut,date_fin)
    }
})



export const updateInterv = (changes) => {

    return (dispatch) => {
      
        return dispatch ({
            
                payload: serviceClient.updateInterv (changes)
       
        }).then ( value => {           
           
        return value
        })
        
    }


}

export const insertInterv = (changes) => {

    return (dispatch) => {
      
        return dispatch ({
              
                payload: serviceClient.insertInterv (changes)
       
        }).then ( value => {           
           
        return value
        })
        
    }

}

export const getNextAppels = (nb) => ({

   
    payload : () => {
        return serviceClient.getNextAppels (nb)
    }
})


export const getListeAppelsparDate = (date_appel,opt) => ({
    
    payload : () => {
        return serviceClient.getListeAppelsparDate (date_appel,opt)
    }
})

export const getListeAppelsparClient = (numClient) => ({
    
    payload : () => {
        return serviceClient.getListeAppelsparClient (numClient)
    }
})


export const updateAppel = (changes) => {

    return (dispatch) => {
      
        return dispatch ({
            
                payload: serviceClient.updateAppel (changes)
       
        }).then ( value => {           
           
        return value
        })
        
    }


}

export const insertAppel = (changes) => {

    return (dispatch) => {
      
        return dispatch ({
              
                payload: serviceClient.insertAppel (changes)
       
        }).then ( value => {           
           
        return value
        })
        
    }

}

export const deleteAppel = (changes) => {

    return (dispatch) => {
      
        return dispatch ({
            
                payload: serviceClient.deleteAppel (changes)
       
        }).then ( value => {           
           
        return value
        })
        
    }


}


export const getNextMaint = (nb) => ({
   
    payload : () => {
        return serviceClient.getNextMaint (nb)
    }
})


export const getListeMaintparDate = (date_deb,opt) => ({

   
    payload : () => {
        return serviceClient.getListeMaintparDate (date_deb,opt)
    }
})

export const getListeMaintentreDates = (date_deb,date_fin) => ({

   
    payload : () => {
        return serviceClient.getListeMaintentreDates (date_deb,date_fin)
    }
})


export const getListeMaintparClient = (numClient) => ({
    
    payload : () => {
        return serviceClient.getListeMaintparClient (numClient)
    }
})


export const updateMaint = (changes) => {

    return (dispatch) => {
      
        return dispatch ({
            
                payload: serviceClient.updateMaint (changes)
       
        }).then ( value => {           
           
        return value
        })
        
    }


}

export const insertMaint = (changes) => {

    return (dispatch) => {
      
        return dispatch ({
              
                payload: serviceClient.insertMaint (changes)
       
        }).then ( value => {           
           
        return value
        })
        
    }

}


export const getListeParamInterv = (opt) => ({
    type : IDENT_GET_TYPE_INTERV,   
    payload : () => {
        return serviceClient.getListeParamInterv (opt)
    }
})

export const getListeParamMotif = (opt) => ({

    type : IDENT_GET_TYPE_MOTIF,
    payload : () => {
        return serviceClient.getListeParamMotif (opt)
    }
})

