import { Container, withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import React, { Component } from 'react';
import ResizableIconButton from '../../core/components/window/ResizableIconButton';
import '../../stylesheets/splitpane.css';
import ChoixInterv from '../Home/ChoixInterv';

class Intervs extends Component  {

    constructor(props) {
        super(props);
        this.state = {
            etatClient:0,
            num:0,
            client:null,
            titre:'Liste des Interventions',
            radio :1,
            typeliste:{
                code:1,
                titre :'',
                qte :10,
                datedeb : "2021-07-01",
                datefin : "2021-07-01",
            },
            changeliste :false,
            listemodif:{}
            ,
            selectedDateTimeDeb: "2021-06-25",
            selectedDateTimeFin: "2021-06-25",
            datedujour :"2021-07-28",
            datemoins3 :"2021-04-28",
        }
        this.handleInputChange  = this.handleInputChange.bind(this)
        this.handleQteChange  = this.handleQteChange.bind(this)
        this.lanceClick  = this.lanceClick.bind(this)
    }
    
    typedeliste = [
        {label:"Liste des dernières interventions", code:"1"},
        {label:"Liste des clients sans intervention", code:"2"},
        {label:"Liste des interventions effectuées", code:"3"},
     //   {label:"Nb interventions/client/date", code:"4"},
     //   {label:"Nb Total interventions par mois", code:"54"},
      ];


    afficheClient (id) {
    
        this.props.afficheClientClick(id);
    }
    
    afficheTitre (titz) {
    
        this.setState ({
          
            titre: titz,
        },()=>{
     
        })

    }  
    
    handleInputChange(evt) {
        let zliste ={};
        zliste = this.state.typeliste;
   
        var code = evt.target.value;
       // eslint-disable-next-line
        if ((code ==2) ||(code ===2)) {
            this.setState({ selectedDateTimeDeb: this.state.datemoins3 });          
            zliste.datedeb = this.state.datemoins3;
          
        }
        if ((code ==3) ||(code ===3)) {
            this.setState({ selectedDateTimeFin: this.state.datedujour});          
            zliste.datefin = this.state.datedujour;
          
        }
        zliste.code = code;
        var neword= code -1;
        zliste.titre = this.typedeliste[neword].label;
        this.setState({
            typeliste: zliste,      
            changeliste : false,       
        })
     
      
        






    }
    
    handleQteChange(evt) {
       let zliste ={};
       zliste = this.state.typeliste;
       var nb = evt.target.value;
       zliste.qte = nb;
       this.setState({
            typeliste: zliste,    
            changeliste : false,         
        })
        
    }

    lanceClick = () => {

        let zliste = this.state.typeliste;

        this.setState({
            listemodif : zliste,
            changeliste : true,
        },()=>{
          
        })
       
    }


    updateSelectedDateTime = event => {
        let zliste ={};
     
        if (event.target.id ==='date_depuis') {
            this.setState({ selectedDateTimeDeb: event.target.value });
            zliste = this.state.typeliste;
            zliste.datedeb = event.target.value;
            this.setState({
                typeliste : zliste,
                changeliste : false,
            }) 

        }
        if (event.target.id ==='date_deb') {
            this.setState({ selectedDateTimeDeb: event.target.value });
            zliste = this.state.typeliste;
            zliste.datedeb = event.target.value;
            this.setState({
                typeliste : zliste,
                changeliste : false,
            }) 
        }
        if (event.target.id ==='date_fin') {
            this.setState({ selectedDateTimeFin: event.target.value });
            zliste = this.state.typeliste;
            zliste.datefin = event.target.value;
            this.setState({
                typeliste : zliste,
                changeliste : false,
            }) 
        }
    }



    componentDidMount() {

        var today = new Date();  
        let date_debut = today.toISOString().slice(0,10);
        var dat3 = new Date();
        const month = dat3.getMonth();  
        dat3.setMonth(dat3.getMonth() - 3);
        while (dat3.getMonth() === month) {
            dat3.setDate(dat3.getDate() - 1);
        }
        let date_moins3 = dat3.toISOString().slice(0,10);
      
        var tousclients = localStorage.getItem('Suiviclients_Search_tousClients');
        var zliste = this.state.typeliste;
        var neword= zliste.code -1;
        zliste.titre = this.typedeliste[neword].label;   
        this.setState ({
           radio :  tousclients,
           listemodif :zliste,
           datedujour :date_debut,
           datemoins3 : date_moins3,
           selectedDateTimeDeb:date_debut,
           selectedDateTimeFin:date_debut,
        })
        this.lanceClick();
      }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.num !== this.props.num) { 
  
             this.setState({
              num: this.props.num,
              client : this.props.client,
             });    
          }
   
      }
    render() {
      
        return (


            <Container className={this.props.classes.root}>  

                <FormControl className = {this.props.classes.formControlInterv} >      
                    <div>
                    <label className="label margin-l-10 margin-r-50">
                         {this.state.titre} </label>
                    </div>
                
                    {(this.state.typeliste.code == 1) && 
                    <div>
                        <label className="label margin-l-5">
                           
                            <select name="code" value={this.state.typeliste.code}
                            disabled={false} className= "inputinterv width300 gras"
                            
                                onChange={this.handleInputChange}>
                                {this.typedeliste.map((option) => (
                                    <option value={option.code}>{option.label}</option>
                                    ))}
                            </select> 
                        </label>  
                      
                        <label className="label">
                                  Nombre :
                                  <input  className= "input width50"
                                      name="nb_inter"
                                      type="number"  min={0}
                                      disabled={false}                              
                                      value={this.state.typeliste.qte}
                                      onChange={this.handleQteChange} />
                        </label>   
                        <ResizableIconButton   title="Cliquer pour lancer la liste"
                            onClick={this.lanceClick}>
                            <SendIcon   style= {{ color: "#ff0000",visibility:"visible",marginRight :0, fontSize: 24}} />                                                                 
                        </ResizableIconButton>
                       
                                     
                    </div>
                    }
                   
                    {(this.state.typeliste.code == 2) && 
                    <div>
                        <label className="label margin-l-5">
                           
                            <select name="codeb" value={this.state.typeliste.code}
                            disabled={false} className= "inputinterv width300 gras"
                            
                                onChange={this.handleInputChange}>
                                {this.typedeliste.map((option) => (
                                    <option value={option.code}>{option.label}</option>
                                    ))}
                            </select> 
                        </label>  
                       
                        <TextField
                                  id="date_depuis"       
                                  type="date"    
                                  label ='depuis le'   
                                  disabled={false}                                         
                                  value={this.state.selectedDateTimeDeb}
                                  onChange={this.updateSelectedDateTime}       
                                  className={this.props.classes.textFieldDate}                                             
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{ min: "2020-01-01", max: this.state.datedujour ,style: {fontSize: 14,height:12,fontWeight:"bold"}}} 
                                />  
                            <ResizableIconButton   title="Cliquer pour lancer la liste"
                                onClick={this.lanceClick}>
                                <SendIcon   style= {{ color: "#ff0000",visibility:"visible",marginRight :0, fontSize: 24}} />                                                                 
                            </ResizableIconButton>  
                                     
                    </div>
                    }
                     
                    {(this.state.typeliste.code == 3) && 
                    <div>
                        <label className="label margin-l-5">
                           
                            <select name="codeb" value={this.state.typeliste.code}
                            disabled={false} className= "inputinterv width300 gras"
                            
                                onChange={this.handleInputChange}>
                                {this.typedeliste.map((option) => (
                                    <option value={option.code}>{option.label}</option>
                                    ))}
                            </select> 
                        </label>  
                       
                        <TextField
                                  id="date_deb"       
                                  type="date"    
                                  label ='entre le'   
                                  disabled={false}                                         
                                  value={this.state.selectedDateTimeDeb}
                                  onChange={this.updateSelectedDateTime}       
                                  className={this.props.classes.textFieldDate}                                             
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{ min: "2020-01-01", max: this.state.datedujour ,style: {fontSize: 14,height:12,fontWeight:"bold"}}} 
                                />  
                     
                              <TextField
                                  id="date_fin"       
                                  type="date"    
                                  label ='et le '   
                                  disabled={false}                                         
                                  value={this.state.selectedDateTimeFin}
                                  onChange={this.updateSelectedDateTime}       
                                  className={this.props.classes.textFieldDate}                                             
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{ min: this.state.selectedDateTimeDeb, max: this.state.datedujour ,style: {fontSize: 14,height:12,fontWeight:"bold"}}} 
                                />   
                             <ResizableIconButton   title="Cliquer pour lancer la liste"
                                onClick={this.lanceClick}>
                                <SendIcon   style= {{ color: "#ff0000",visibility:"visible",marginRight :0, fontSize: 24}} />                                                                 
                            </ResizableIconButton> 
                                     
                    </div>
                    }

                </FormControl>

                            
                    <ChoixInterv 
                      changeliste = {this.state.changeliste}
                      listemodif = {this.state.listemodif}
                      afficheTitre = {(titz) =>this.afficheTitre(titz)}
                      afficheClientClick= {(id) =>this.afficheClient(id)}>
                     </ChoixInterv>
              
        
              
                
            </Container>
        )
    
    }
}


    const classes = theme => ({
        root: {
            scrollBehavior: 'smooth',
            flexGrow: 1,            
            height:'5vh',            
            backgroundColor: theme.bkcolor.rosepastel,
            display :'table-row',
            width:'100vh',
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            },
        memo: {
            textAlign: 'justify',
            marginBottom: '1.3em',
        },
        btn_class :{
            color:"primary",
        },
        input: {
        lineHeight: '2rem',
        },
        label: {
        display: 'inline-block',
        width: '12em',
        },
        headcontrol: {
            height:'30px',
            backgroundColor: theme.bkcolor.rosepastel,
            color: theme.bkcolor.noir,
            width :'100vh',
        },
        textFieldDate: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(2),
            marginBottom:theme.spacing(1),
            marginTop :10,
            width:120,
            backgroundColor: "white", 
            color: "black",   
                        
          },
    })
    
    export default  (withStyles(classes) (Intervs))
    
    