import { Container, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import IntervForm from '../../core/components/resources/IntervForm';
import AppelForm from '../../core/components/resources/AppelForm';
import MaintForm from '../../core/components/resources/MaintForm';
import MaterielForm from '../../core/components/resources/MaterielForm';
import '../../stylesheets/splitpane.css';
import '../../stylesheets/tabpanel.css';

class TabPanel extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        selectedTabIndex: props.children.length === 0 ? null : 0,
        
      };
  
      this._handleClick = this._handleClick.bind(this);

    }
  
    _handleClick(index) {
      this.setState({
        selectedTabIndex: index
      });
      
      this.props.onTabChange(index);
    }
  
    _renderTabs() {
      let tabs = [];
      
      for (let i = 0; i < this.props.children.length; i++) {
        tabs.push(
          <Tab
            key={`tab-${i}`}
            label={this.props.children[i].props.title}
            index={i}
            disabled 
            isSelected={i === this.state.selectedTabIndex}
            onClick={this._handleClick} />
        );
      }
  
      return tabs;
    }
    
    _renderTabContent() {
      return this.props.children[this.state.selectedTabIndex].props.children;
    }
  
    render() {
      if (this.state.selectedTabIndex == null) {
        return 'nope';
      }
      
      return (
        <div className="tab-panel_interv">
          <div className="tab-panel_interv__header">
            {this._renderTabs()}
          </div>
          <div className="tab-panel_interv__content">
            {this._renderTabContent()}
          </div>
        </div>
     );
    }
  }
  class Tab extends React.Component {
    constructor(props) {
      super(props);
      
      this._handleClick = this._handleClick.bind(this);
      
    }
  
    _handleClick() {
      const displayCode = (localStorage.getItem('Suiviclients_Search_modifEncours') === '0') 
      if ( displayCode) {
       this.props.onClick(this.props.index);
      }
    }
  
    render() {
      const tabClassName = this.props.isSelected ? "tab_interv tab_interv--selected" : "tab_interv";
  
      return (
        <span className={tabClassName} onClick={this._handleClick}>
          <span className="tab__label">{this.props.label}</span>
        </span>
      );
    }
  }
  
class Interventions extends Component  {

    constructor(props) {
        super(props);
        this.state = {         
                etatClient:0,               
            }

    this._handleTabChange = this._handleTabChange.bind(this);
     }

     _handleTabChange(index) {
        const displayCode = (localStorage.getItem('Suiviclients_Search_modifEncours') === '0') 
        if (displayCode) {

     
        }  
      }
    render() {
       
      
        return (


            <Container className={this.props.classes.root}>                                  
                     <TabPanel  onTabChange={this._handleTabChange}>
                    
                         <div  title="Interventions" >
                         <IntervForm numclient = {this.props.numclient}></IntervForm>
                        </div>
                        <div  title="A appeler" >
                        <AppelForm numclient = {this.props.numclient}></AppelForm>
                         </div>
                         <div  title="Maintenance" >
                         <MaintForm numclient = {this.props.numclient}></MaintForm>
                         </div>
                         <div  title="Matériels" >
                       
                         </div>
                       
                      </TabPanel>
                
            </Container>
        )
    
    }
}
    const classes = theme => ({
        root: {
            scrollBehavior: 'smooth',
            flexGrow: 1,
            backgroundColor: theme.bkcolor.rosepastel,
            height:'100vh',
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            },
        memo: {
            textAlign: 'justify',
            marginBottom: '1.3em',
        },
        btn_class :{
            color:"primary",
        },
        input: {
        lineHeight: '2rem',
        },
        label: {
        display: 'inline-block',
        width: '12em',
        }
    })
    
    export default  (withStyles(classes) (Interventions))
    
    