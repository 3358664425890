import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Button} from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import {setError} from '../core/redux/actions/WindowAppActions'

/**
 * Affichage de l'erreur rencontrée
 */
class Error extends Component {

  deleteError = () => {
    this.props.setError(null)
  }
  render() {
    let sError = 'Erreur rencontrée'
    let wAlert

    if (this.props.error) {
      
      switch (typeof(this.props.error)) {
        case 'string':
          sError = this.props.error
          break

        case 'object':
          console.warn ('Error object', this.props.error)
          for (const [key, value] of Object.entries(this.props.error)) {
            console.debug('elt', key, typeof(value))
          }
          break

        default:
          console.warn('Error:', this.props.error)
          sError = 'Erreur non traitée'
          break
      }

      wAlert = (
        <Alert severity="error">
            <AlertTitle>Erreur</AlertTitle>
            <p>{sError}</p>
            <p>Vous pouvez tenter de renouveler votre action ou contacter notre service d'assistance.</p>
            <Button onClick={this.deleteError}>J'ai compris</Button>
        </Alert>
      )
    } 

    return (
        <>{wAlert}</>
    )
  }
}

const mapStateToProps = state => ({
    error : state.windowApp.error
})
const mapDispatchToProps = ({
  setError,
})

export default connect (mapStateToProps, mapDispatchToProps) (Error)
