/**
 * Personnalisation du thème
 * 
 * @see https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 * @see https://material.io/develop/web
 */

const primaryColor = '#092F6A' //bleu sobmre
const secondaryColor = '#ffc400'// jaune
const primaryColorLight = '#3a5887'// bleu sombre
const primaryColorDark = '#06204a' // bleu noir
const secondaryColorLight = '#FFEDB3'// jaune pastel
const secondaryColorDark = '#FFEDB3'

const colorBleupastel = '#dbfcfb'// bleu pastel
const colorBleuciel = '#92eceb'// bleu ciel
const colorRosepastel = '#f9d7d7'// rose pastel 
const colorRose =  '#f49090'// rose 
const colorBleu =  '#bbf3f7'// bleu 
const colorBleuclair = '#93f7e1'// bleu clair 
const colorMarronclair = '#cfc961' // marron
const colorVertpastel = '#a0f950'//vert pastel
const colorJaunepastel = '#ffedB3'// jaune pastel
const colorBleusombre = '#3a5887'// bleu sombre
const colorBlanc = '#ffffff' //blanc
const colorNoir = '#000000' // noir

export const LightTheme = {
    bkcolor: {
      bleupastel : colorBleupastel ,
      bleuciel : colorBleuciel,
      bleusombre : colorBleusombre, 
      rosepastel : colorRosepastel,
      rose : colorRose,
      bleu : colorBleu,
      bleuclair : colorBleuclair,
      marron:  colorMarronclair,
      vertpastel : colorVertpastel,
      jaunepastel :colorJaunepastel,
      blanc : colorBlanc,
      noir : colorNoir,

    },
    palette: {
      primary: {
        main: primaryColor, 
        light: primaryColorLight,
        dark: primaryColorDark,
      },
      secondary: {
        main: secondaryColor,
        light: secondaryColorLight,
        dark: secondaryColorDark,
      }
    },
    overrides: {
      MuiContainer: {
        root: {
          backgroundColor: 'white',
        }
      },
      MuiButtonBase: {
        root: {
            color: primaryColor,
        }
      },
      MuiGrid: {
        root: {
          '&& span': {
            color: primaryColor,
          }
        }
      },
      MuiIconButton: {
        root: {
          color: primaryColorLight,
        }
      },
      MuiPaper: {
        root: {
          color: primaryColor,
        }
      },
      MuiTypography: {
        root: {
          color: primaryColor,
        },
        subtitle1: {
          backgroundColor: secondaryColorLight,
        }
      },
      MuiInputBase: {
        root: {
          color: primaryColor,
        }
      },
      MuiRadio: {
        root: {
          color: primaryColorLight,

        }
      },
      MuiTab: {
        root: {
          color: primaryColor,
        },
        wrapper: {
          color: primaryColor,
        }
      },
      MuiTable: {
        root: {
          color: primaryColor,
        },
      },
      MuiTableCell: {
        root: {
          color: primaryColor,
        },
        body: {
          color: primaryColor,
        },
        head: {
          color: primaryColor,
          fontWeight: 'bold',
        }
      },
      MuiTableSortLabel: {
          root: {
              color: primaryColor,
              '&.MuiTableSortLabel-active': {
                color: primaryColor,
              },
              '&:focus': {
                color: primaryColor,
              }
          },
          icon: {
            color: primaryColor,
          }
      },
      MuiTablePagination: {
          root: {
              color: primaryColor,
          }
      }
    }
  }