import { applyMiddleware, combineReducers, createStore } from "redux";
/*
 * Console de débogage
 */
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import PromiseMiddleware from 'redux-promise-middleware';
import ReduxThunk from 'redux-thunk';
import { apiMiddleware } from './middleware/apiMiddleware';
import IdentityReducer from "./reducers/IdentityReducer";
import WindowAppReducer from "./reducers/WindowAppReducer";


export const store = createStore(combineReducers(
    {
        identity: IdentityReducer,
        windowApp: WindowAppReducer,
     
    }
), composeWithDevTools(applyMiddleware(ReduxThunk, PromiseMiddleware, apiMiddleware) ));
// devToolsEnhancer());