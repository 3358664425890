import {
    CircularProgress, Container, Paper, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow,
    TableSortLabel, withStyles
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ResizableIconButton from '../../core/components/window/ResizableIconButton';
import WaitBlock from '../../core/components/window/WaitBlock';
import { getListeAppelsparDate, getNextAppels } from '../../core/redux/actions/ClientActions';
import { setTitle } from '../../core/redux/actions/WindowAppActions';
import '../../stylesheets/ChoixClient.css';




class ChoixAppels extends Component {

    /**
     * Champs avec un filtre
     */
    headerCells = [
        {
            field : 'date_appel',
            label : 'Date',
        },
        {
            field : 'numero',
            label : 'Client',
        },        
        {
            field : 'nom client',
            label : 'Société',
        },
        {
            field : 'motif_appel',
            label : 'motif', 
        },
        {
            field : 'libel_appel',
            label : 'libellé', 
        },
        {
            field : 'comment_appel',
            label : 'Commentaire',
        },
       
    ]

    
    valuesRef = []
   
    constructor(props) {
        super(props);
        this.state = {
            choice: false,
            modifEncours : false,
            displayedHelp : false,
            initialsValues: [],
            values : [],
            order : 'desc',
            columnSort : 'date_appel',
            filtersEnable : true,
            filters : {
                numero: '',
                date_appel: '',
                motif_appel:'',                
            },
            filtre :'',        
            appel:{
                id:0,
                numero :0,
                nom_appel:'',
                date_iso : new Date(),
                date_appel : new Date(),
                motif_appel:'INST',
                libel_appel:this.recupLibel("INST"),
                ordre_appel:1,
                comment_appel:'',
                login_appel:'NME',
            },
            begin : false,
            numclientselect:0,
            dateselect: new Date(),       
            evt:null,
            evtclick:null,
        }
        this.onClickRow = this.onClickRow.bind (this)
        this.handlerSort = this.handlerSort.bind (this)
        this.handleInputChange =this.handleInputChange(this)
     
      
        this.clickSearch = this.clickSearch.bind(this);
                
    }
    typemotif = [];
    ztypemotif = [
        {label:"", code:"0"},
        {label:"Dernier MAJ reçue", code:"DENV"},
        {label:"Inter clôturée", code:"OFIC"},            
        {label:"Pb en Cours", code:"KFIC"},
        {label:"Installation", code:"INST"},
        {label:"PB logiciel Résolu", code:"OLOG"},
        {label:"Pb logiciel", code:"KLOG"},
       ];

    componentDidMount() {
        this.typemotif = this.props.typemotifs; 
  
        /*
         * Contrôle accès
         */
             
        
        // eslint-disable-next-line
      

            if (!this.props.isConnected) {
                console.warn('Not connected')
               // this.props.history.push ('/')
            }
            this.props.setTitle ("LIste des Prochains appels à effectuer")
           
            
            this.recupdonnees();
            
    } 
    
    


 
  
    recupdonnees = () => {
       /* var nbj = 170; //15
        var today = new Date();              
        let jour_nbj = today.setDate(today.getDate()-nbj);
        let date_nbj =  new Date(jour_nbj);        
        let date_debut = date_nbj.toISOString();
      */  
        let nombre = 25;
        let datavalues = [];  
        this.props.getNextAppels (nombre)
        .then ( (response) => {
         datavalues = response.value;
         this.afficheAppels(datavalues);
        }, (error) => {
        console.warn('error ',error);
        
        })       
        
    }
    

    afficheAppels (datas) {
     
        const nbrows = datas.length;
        
        let items = [];
        if (nbrows ===0) {
         
          this.setState({
            columnContract: false,
            filtersEnable: false,  
            values:items,            
          })
  
        } 
        else {
            for (var i=0;i<nbrows;i++) {
              let tmpdat = datas[i].date_appel;          
              let datz = tmpdat.substr(3,2)+'/'+ tmpdat.substr(0,2)+'/'+tmpdat.substr(6,4);
                        
              let item ={
                id:i+1,
                numero :datas[i].numero,      
                date_iso : new Date(datz).toISOString(),        
                date_appel :new Date(datz).toLocaleString().slice(0,10),
                motif_appel:datas[i].motif_appel,
                nom_appel : this.recupNom(datas[i].numero),
                libel_appel:this.recupLibel(datas[i].motif_appel),
                ordre_appel:datas[i].ordre_appel,
                comment_appel:datas[i].comment_appel,
                login_appel:datas[i].login_appel  
              };       
            
              items.push(item);
            }
            this.setState ({
               order : 'asc',
               columnSort : 'date_appel',
               values:items,
               appel:items[this.state.newid -1],
           })

            this.triAscendant ('date_appel', items)

            
        }
          
        try {
        
          this.setState({
            selectedDateTime : (this.state.appel.date_appel).toISOString().slice(0, 16),
          })
      
        } catch(e) {
          
          this.setState({
            selectedDateTime :"",
          })
        
        }
    }
  
      recupLibel (code) {
    
        if (code !=='') {
          const resu = this.typemotif.find(item =>item.code === code) ;   
        ;
          if (resu !== undefined) {
            return resu.label;
          }
          else return '';
        }
        else return '';  
       }
  
       recupNom (code) {
    
        if (code !=='') {
            let num = parseInt(code);            
            let infosClient =  this.props.clients.find(element => element.numero === num);            
            const resu = infosClient.societe;                 
            if (resu !== undefined) {
             return resu;
          }
          else return '';
        }
        else return '';  
       } 

    handlerSort = (property) => {

        
        /*
         * inversion de l'ordre de tri de la propriété précédemment sélectionnée
        */
      if (property === 'date_appel') {
            if (this.state.columnSort === property) {    

                let initval = this.state.values;

                this.setState({
                   values:[],
                   },()=>{
                    
                    let reverseval = initval.reverse();
                    this.setState({
                        order : (this.state.order === 'asc') ? 'desc' : 'asc',
                        values : reverseval,
                       },()=>{
                    
                        
                      })
                    
                  })
               

                /*
                * Changement de propriété de tri
                */
                } else {
                    this.triAscendant (property, this.state.values)
                    this.setState ({            
                        order : 'asc',
                        columnSort : property,
                    })
                }
            }
    }

    triAscendant = (property, values) => {
        
        /*
        values.sort ((a, b) => {
     
            if (a[property] < b[property]) {
      
                return -1
            } else if (a[property] > b[property]) {
      
                return 1
            } else {
      
                return 0
            }
        })
        */
    }

    handlerFilters = () => {       

       
        this.setState({
          
            filtersEnable : !this.state.filtersEnable,                
        })
        
    }



    resetClick = () => {

    
        this.setState({
            modifEncours: false
        })  
    }

     

    componentDidUpdate(prevProps, prevState) {
          // eslint-disable-next-line   
        
    }

    onClickRow (num,date, event) {
     
       this.setState({
        evtclick :event,
       })
       
        event.preventDefault ();
        const displayCode = (localStorage.getItem('Suiviclients_Search_modifEncours') === '0')
           
        if (displayCode) {
            this.setState({
                choice: true,
                numclientselect:num,
                dateselect:date,
            })
            
            if (this.props.clients) {
                let infosClient =  this.props.clients.find(element => element.numero === num);
         
                this.props.setTitle(infosClient.societe)
            }
            
            this.props.afficheClientClick(num);

        }
        else { 
            this.setState({
                modifEncours: true
            })
        }
    }

    clickSearch ()
    {
  
       var val = this.state.filters[this.state.filtre];
  
       
        if (val !== undefined && val !== null) {
             // eslint-disable-next-line
            if (val.length !=0) {
                this.handlerSearch (this.state.filtre,this.state.evt);
            }
        }
       
       
          
       
    }

    handleInputChange(evt) {
      //  const field = {}
      //  const fieldName= evt.target.name     
      //  var str = evt.target.value;
    }

    handlerSearch = (field, evt) => {
        
        evt.preventDefault ()
        let filters = this.state.filters
        filters[field] = evt.target.value      
        
        let values = JSON.parse(JSON.stringify(this.state.initialsValues))

        for (let fieldName in filters) {
            let search = filters[fieldName];

            if (search && typeof(search) === 'string' && search !== '') {
               
                values = values.filter (elt => { 
                   
                   if ((fieldName ==='numero') || (fieldName ==='numcompta')) {
                       let numsearch = search.toString();
                       numsearch = numsearch.trim();                     
                     
                    return ((elt[fieldName]).toString()).includes (numsearch)
                   
                   }
                   else {
                     
                       
                   return elt[fieldName] !== null && elt[fieldName].includes(search)
                   }
                })
                
            }
        }
                   
        this.setState ({
            filters : filters,
            values : values
        })

        if (values.length === 1) {
            var num = values[0].numero;
            var ev = new Event('input', { bubbles: true });
            ev = this.state.evtclick;
            this.onClickRow(num,ev);

        }
    }


   

   
      
    render() {
       
        if (  (!this.props.clients)      
        || this.props.clientLoading
       
        ) 
        {
            return (
            <Container >
              
                <WaitBlock text="Chargement en cours..."/> 
            </Container>)
       
        } else {      
               
            return (
                <Container className={this.props.classes.root}>
                  
                    <Paper >
                    {this.state.modifEncours ? 
                    <div className="outlined_encours">  
                        <CircularProgress className="margin-t-15 margin-l-5" />
                        <label className="label margin-t-15">  
                            Modification en cours - Action Non Autorisée                    
                        </label>                        
                        <ResizableIconButton   title="Cliquer pour annuler"
                            onClick={this.resetClick}>
                            <CancelIcon   style= {{ color: "#ff0000",visibility:"visible", fontSize: 48}} />                                                                 
                        </ResizableIconButton>
                    </div>:
                        <TableContainer component={Paper} className="containerChoixclient">    
                            <FormControl className={this.props.classes.headcontrol}>                        
                            <label className="label margin-l-10">
                                  Liste des prochains appels à effectuer </label>
                            </FormControl>

                            <Table  stickyHeader size ="small" aria-label="choix client" >  
                          
                                <TableHead >   
                                                 
                                    <TableRow >
                                        {                                                                   
                                            this.headerCells.map ( (cell) => {
                                                return (
                                                    <TableCell  key={cell.field}
                                                        sortDirection={this.state.order}
                                                        className={this.props.classes.head}
                                                        align='left'>
                                                        <div>
                                                            <TableSortLabel active={this.state.columnSort === cell.field} 
                                                                direction={this.state.order}
                                                                hideSortIcon
                                                                onClick={() => {this.handlerSort (cell.field)}} >
                                                                {cell.label}       
                                                            </TableSortLabel>
                                                        </div>
                                                        
                                                            
                                                    </TableCell>
                                                )
                                            })
                                        }
                                        
                                    </TableRow>
                                    
                                </TableHead>
                                <TableBody  >
                                {this.state.values.length === 0 ?
                                    <TableRow key='vide'>
                                        <TableCell>Aucun choix possible.</TableCell>
                                    </TableRow> :
                                    this.state.values.map( (inter) => (
                                      
                                        <TableRow  className = {this.props.classes.root}
                                         key={inter.numero} 
                                                                                      
                                                selected={inter.numero === this.state.numclientselect &&
                                                 inter.date_appel === this.state.dateselect}
                                                onClick={(evt) => {this.onClickRow (inter.numero,inter.date_appel, evt)}}>
                                            <TableCell component="th" scope="row">{inter.date_appel}</TableCell>
                                            <TableCell align="left">{inter.numero}</TableCell>
                                            <TableCell align="left">{inter.nom_appel}</TableCell>
                                            <TableCell align="left">{inter.motif_appel}</TableCell>                                            
                                            <TableCell align="left">{inter.libel_appel}</TableCell>
                                            <TableCell align="left">{inter.comment_appel}</TableCell>
                                        
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </TableContainer>
                        }
                    </Paper>  
                        
                </Container>
            
            )
        }

    }

}

  

const mapStateToProps = state => ({
    isConnected : state.identity.isConnected,
    numClientEnCours : state.identity.numClientEnCours,
    clients : state.identity.clients,
    typemotifs: state.identity.typemotifs,
    identityLoading : state.identity.loading,
    
})

const mapDispatchToProps = dispatch => {
    return {
        getListeAppelsparDate : bindActionCreators (getListeAppelsparDate, dispatch),     
        getNextAppels : bindActionCreators (getNextAppels, dispatch),     
        setTitle : bindActionCreators (setTitle, dispatch),   
    }
}

const classes = theme => ({
    bkcol: {
        /*
        backgroundColor: '#DBFCFB',// bleu pastel
        backgroundColor: '#92ECEB',// bleu ciel
        backgroundColor: '#F9D7D7',// rose pastel 
        backgroundColor: '#F49090',// rose 
        backgroundColor: '#BBD3F7',// bleu 
        backgroundColor: '#93F7E1', 
        backgroundColor: '#CFC961',// marron
        backgroundColor: '#A0F950',//vert
        backgroundColor: '#5ef2e2', 
       */
      },    
        root: {
            '&:nth-of-type(odd)': {
                           
                backgroundColor: theme.bkcolor.bleupastel, 
            },
            '&:nth-of-type(zeven)': {
                           
                backgroundColor: theme.bkcolor.vertpastel, 
            },
            "&.Mui-selected, &.Mui-selected:hover": {
                color: theme.bkcolor.noir,
                backgroundColor: theme.bkcolor.rose,
                cursor: 'none', 
            },
            '&.MuiTableRow-hover': {
                cursor: 'pointer',  
                backgroundColor: theme.bkcolor.rose, 
             
            } , 
               scrollBehavior: 'smooth',
               flexGrow: 1,
               backgroundColor: theme.bkcolor.vertpastel,
               display :'table-row',
               width :'100vh',
            } , 
        head: {
         
            backgroundColor: theme.bkcolor.jaunepastel,
            color: theme.bkcolor.noir,            
        },
        headcontrol: {
            height:'30px',
            backgroundColor: theme.bkcolor.jaunepastel,
            color: theme.bkcolor.noir,
            width :'100vh',
        },
      
        body: {
            fontSize: 14,
        },
        zformControl: {
            margin: theme.spacing.unit * 1,
            fontSize: 10,
          },
        zgroup: {
        margin: `${theme.spacing.unit}px 0`,
        fontSize: 10,
        },
        formControl: {
            margintop:1,
            fontSize: 8,
          },
        group: {
            margintop:1,
        fontSize: 6,
       
        },
        icon: {
            borderRadius: '50%',
            width: 16,
            height: 16,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
              outline: '2px auto rgba(19,124,189,.6)',
              outlineOffset: 2,
            },
            'input:hover ~ &': {
              backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
              boxShadow: 'none',
              background: 'rgba(206,217,224,.5)',
            },
          },
          checkedIcon: {
            backgroundColor: '#137cbd',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
              display: 'block',
              width: 16,
              height: 16,
              backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
              content: '""',
            },
            'input:hover ~ &': {
              backgroundColor: '#106ba3',
            },
          },

        
    })

 

export default connect (mapStateToProps, mapDispatchToProps) (withStyles(classes)  (ChoixAppels))