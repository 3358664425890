
import AuthService from '../../../services/AuthenticationService'
import ClientService from '../../../services/SuiviclientsService'
import { setError } from './WindowAppActions'

/**
 * Suffixes utilisés par redux-promise-middleware
 */
export const PENDING = '_PENDING'
export const FULFILLED = '_FULFILLED'
export const REJECTED = '_REJECTED'

/**
 * Actions liées à l'identité
 */
export const IDENT_LOGIN = 'IDENT_LOGIN'
export const IDENT_GET_IDENTITY = 'IDENT_GET_IDENTITY';

export const IDENT_RECONNECT = 'IDENT_RECONNECT'
export const IDENT_LOGOUT = 'IDENT_LOGOUT';

const serviceAuth = new AuthService()
const serviceClient = new ClientService()

export const login = (payload) => {

    return dispatch => {
        
        return dispatch ({
            type : IDENT_LOGIN,
            payload : serviceAuth.login (payload)

        }).then ( () => {
            
            return dispatch (getIdentity ())             
           
            
        }, (error) => {
            
            if (error === 401) {
                return Promise.reject(401)
            } else {
              dispatch (setError(error))  
            }
        }).then ( value => {           
          
            return value
        })
    }
}   

export const getIdentity = () => {

    return dispatch => {

        return dispatch ({
            type : IDENT_GET_IDENTITY,
            payload : serviceClient.getIdentity ()
        }).then ( () => {
           // return dispatch (getClients ())                /* récupère la liste des clients gérables */
            
            
        })
      
    }
    
}


export const logout = (payload) => {

    return dispatch => {        
        sessionStorage.removeItem('TOKEN')
        sessionStorage.removeItem('numClient')
        return dispatch ({
            type: IDENT_LOGOUT,
            payload: serviceAuth.logout ()
        }).then ( () => {            

            return 'disconnected'
            }
        , (error) => {      
            console.warn('error ',error);      
            if (error === 401) {
                return Promise.reject(401)
            } else {
              dispatch (setError(error))  
            }
        }).then ( value => {           

            return 'logout'
        })
    }
}


