
import * as cliact from "../actions/ClientActions";
import * as act from "../actions/IdentityActions";

/**
 * État initial
 */
let numClientInitial = sessionStorage.getItem ('numClient')
const INITIAL_STATE = {
    error : null,
    loading : true,
    isConnected: sessionStorage.getItem('TOKEN') !== null,
    token: sessionStorage.getItem('TOKEN'),
    user: null,
    clients : null,
    clientsactifs : null,
    typeintervs : null,
    typeintervsactif : null,
    typemotifs :null,
    numClientEnCours : (numClientInitial) ? parseInt(numClientInitial) : 0
}


const reducer = (state = INITIAL_STATE, action) => {

    if (action.type.endsWith (act.REJECTED)) {
        console.warn('REJECTED', action.payload)
        return Object.assign ({}, state, {
            error : action.payload,
            loading: false
        })

    } else {
        switch (action.type) {

            // case action.type.endsWith (act.REJECTED):
            //     console.info('REJECTED', action.payload)
            //     return Object.assign ({}, state, {
            //         error : action.payload,
            //         loading: false
            //     })

            /*
            * Sauvegarde du token
            * en vue d'une réutilisation dans une autre application
            */
            case act.IDENT_LOGIN + act.PENDING:
                return Object.assign({}, state, {
                    loading: true,
                    isConnected: false,
                    token: null,
                    user: null,
                    clients: null,
                    clientsactifs: null,
                    numClientEnCours : 0,
                })

            case act.IDENT_LOGIN + act.FULFILLED:
                sessionStorage.setItem('TOKEN', action.payload);
                sessionStorage.setItem('numClient', 0)
              
                return Object.assign({}, state, {
                    loading: false, 
                    isConnected: action.payload.length > 0,
                    token : action.payload,
                });
                
            /*
            * conservation de l'identité
            */
            case act.IDENT_GET_IDENTITY + act.PENDING:
                return Object.assign({}, state, {
                    loading: true,
                    user: null,
                    clients: null,
                    clientsactifs:null,
                })

            case act.IDENT_GET_IDENTITY + act.FULFILLED:
              
                return Object.assign ({}, state, {
                    loading : false,
                    user : action.payload.utilisateur,
                    clients : null,
                    clientsactifs:null,
                });

            /*
            * conservation de la liste des clients accessibles
            */
            case cliact.IDENT_GET_CLIENTS + act.PENDING:
                return Object.assign ({}, state, {
                    loading : true,
                    clients : null,
                })
            case cliact.IDENT_GET_CLIENTS + act.FULFILLED:
               
                return Object.assign ({}, state, {
                    loading: false,
                    clients: action.payload,
                })

            case cliact.IDENT_GET_CLIENTS_ACTIFS + act.PENDING:
                return Object.assign ({}, state, {
                    loading : true,
                    clientsactifs : null,
                    clients : null,
                })
            case cliact.IDENT_GET_CLIENTS_ACTIFS + act.FULFILLED:
                
                return Object.assign ({}, state, {
                    loading: false,
                    clientsactifs: action.payload,
                    clients: action.payload,
                })    

            /*
            * conservation de l'id du client en cours de traitement
            */
            case cliact.IDENT_SET_ID_CLIENT:

                sessionStorage.setItem ('numClient', action.payload)
                return Object.assign({}, state, {
                    loading: true,
                    numClientEnCours: action.payload,
                })

           
            /*
            * conservation de la liste des types d'intervention
            */
            case cliact.IDENT_GET_TYPE_INTERV + act.PENDING:

                return Object.assign ({}, state, {
                  
                    typeintervs : null,
                })
            case cliact.IDENT_GET_TYPE_INTERV + act.FULFILLED:
                let values = action.payload;
                let typz =[];
                let typzactif =[];
                let item ={};
             
                item ={                    
                    label:'',
                    code:0,  
                  };
                 typz.push(item);
                 typzactif.push(item); 
                for (var i =0; i<values.length;i++) {
                  
                    item ={                    
                        label:values[i].libel_interv,
                        code:values[i].code_interv,  
                      }; 
                    typz.push(item);     
                    if (values[i].actif === null) {
                         typzactif.push(item);  
                    }
                }
               
                return Object.assign ({}, state, {
                   
                    typeintervs: typz,
                    typeintervsactif: typzactif,
                })

             /*
            * conservation de la liste des types de mmotifs
            */
             case cliact.IDENT_GET_TYPE_MOTIF + act.PENDING:
                return Object.assign ({}, state, {
                  
                    typemotifs : null,
                })
            case cliact.IDENT_GET_TYPE_MOTIF + act.FULFILLED:
                let values_motif = action.payload;
                let typz_motif =[];
            
                let item_motif ={};
             
                item_motif ={                    
                    label:'',
                    code:0,  
                  };
                 typz_motif.push(item_motif);
                ; 
                for (var j =0; j<values_motif.length;j++) {
                  
                    item_motif ={                    
                        label:values_motif[j].libel_motif,
                        code:values_motif[j].code_motif,  
                      }; 
                    typz_motif.push(item_motif);     
                 }   
              
               
                return Object.assign ({}, state, {
                   
                    typemotifs : typz_motif,
                })

            



            /*          
            * déconnexion totale
            */
            case act.IDENT_LOGOUT + act.PENDING:
                return Object.assign({}, state, {
                    loading: true
                })
            case act.IDENT_LOGOUT + act.FULFILLED:
                
                return { 
                    isConnected: false,
                    token : null,
                    user: null,
                    clients : [],
                    numClientEnCours : 0,
                };

            default:
                return state;
        }
    }
}

export default reducer