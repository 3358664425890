/**
 * initialisation des paramètres par défaut
 */
//export const URL_API_AUTH = 'https://api.suiviclients.athmo.eu'
//export const URL_API = 'https://api.suiviclients.athmo.eu'

export const URL_API_AUTH = process.env.REACT_APP_URL_API_AUTH
export const URL_API = process.env.REACT_APP_URL_API
export default class AppInit {

    initialize () {
      
        /*
        * paramètres 
        */
        const fields = [               
            {   name : 'modifEncours',
            value : 0, 
            },
            { name : 'ajoutPossible',
            value : 1, 
            },
            {   name : 'reAfficheListe',
                value : 0, 
            },
            {   name : 'testjjb',
                value : 0, 
            },
            {   name : 'tousClients',
            value : 0,            
            },

        ]
        fields.forEach (field => {
            const destination = `Suiviclients_Search_${field.name}`
            let value = localStorage.getItem(destination)

            if (value === null)  {
                localStorage.setItem(destination, field.value)
            }
            if (field.name === 'modifEncours')  {
                localStorage.setItem(destination, "0")
            }
            if (field.name === 'ajoutPossible')  {
                localStorage.setItem(destination, "1")
            }
            if (field.name === 'reAfficheListe')  {
                localStorage.setItem(destination, "0")
            }
            if (field.name === 'testjjb')  {
                localStorage.setItem(destination, field.value)
            }
            if (field.name === 'tousClients')  {
                localStorage.setItem(destination, field.value)
            }
        })

    }

}