import {
  Container, Toolbar,
  Typography, withStyles
} from '@material-ui/core';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import BuildIcon from '@material-ui/icons/Build';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import DescriptionIcon from '@material-ui/icons/Description';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import EventNoteIcon from '@material-ui/icons/EventNote';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ResizableIconButton from '../../core/components/window/ResizableIconButton';
import { setError, setTitle } from '../../core/redux/actions/WindowAppActions';
import '../../stylesheets/ChoixClient.css';
import ChoixAppels from '../Home/ChoixAppels';
import Clients from '../resources/Clients';
import Intervs from '../resources/Intervs';
import Maints from '../resources/Maints';




class MenuListes extends Component {

    state = {
        displayEditDriver: false,
        displayAddDriver: false,
        editDriverId: 0,
        refreshDataGrid: 0,
        refClick:1,
        reAffiche :0,
      }
    
      clientClick= () => {       
        this.setState({
          refClick: 1,         
        })
      }
      intervClick= () => {       
        this.setState({
          refClick: 2,         
        })
      }
      appelClick= () => {       
        this.setState({
          refClick: 3,         
        })
      }
      maintClick= () => {       
        this.setState({
          refClick: 4,         
        })
      }
      rapportClick= () => {       
        this.setState({
          refClick: 5,         
        })
      }
      paramClick= () => {       
        this.setState({
          refClick: 6,         
        })
      }
     

    

     
      
    componentDidMount() {
      
     
    }

    componentDidUpdate(prevProps, prevState) {

      
    }

    

    
    
    
    render() {
        
            
        return (
            <Container className={this.props.classes.root}>
              <Toolbar className={this.props.classes.toolbar}>
                          
                            <Typography className={this.props.classes.title} variant="h5">Listes</Typography>
                            
                            <ResizableIconButton  title="Liste des Clients"
                                onClick={this.clientClick}>
                                <ContactPhoneIcon 
                                 style={this.state.refClick===1?{ color: "#ff0000",visibility:"visible", fontSize: 32 }:{ color: "#000000",visibility:"visible", fontSize: 24}} />
                             </ResizableIconButton>                            
                          
                            <ResizableIconButton  title='Denières Interventions'                          
                              onClick={this.intervClick}>
                               <DescriptionIcon
                                 style={this.state.refClick===2?{ color: "#ff0000",visibility:"visible", fontSize: 32 }:{ color: "#000000",visibility:"visible", fontSize: 24}} />
                             </ResizableIconButton>

                             <ResizableIconButton   title='Clients à Appeler' 
                               onClick={this.appelClick}>
                               <AddIcCallIcon
                                style={this.state.refClick===3?{ color: "#ff0000",visibility:"visible", fontSize: 32 }:{ color: "#000000",visibility:"visible", fontSize: 24}} />
                             </ResizableIconButton>

                             <ResizableIconButton  title='Dates de Maintenance' 
                               onClick={this.maintClick}>
                               <EventNoteIcon
                                 style={this.state.refClick===4?{ color: "#ff0000",visibility:"visible", fontSize: 32 }:{ color: "#000000",visibility:"visible", fontSize: 24}} />
                             </ResizableIconButton>
                             <ResizableIconButton    title='Matériels' 
                               onClick={this.rapportClick}>
                               <DevicesOtherIcon  
                                style={this.state.refClick===5?{ color: "#ff0000",visibility:"visible", fontSize: 32 }:{ color: "#000000",visibility:"visible", fontSize: 24}} />
                             </ResizableIconButton>
                            
                             <ResizableIconButton    title='Paramètrese' 
                               onClick={this.paramClick}>
                               <BuildIcon  
                                style={this.state.refClick===6?{ color: "#ff0000",visibility:"visible", fontSize: 32 }:{ color: "#000000",visibility:"visible", fontSize: 24}} />
                             </ResizableIconButton>                      
                            
                        </Toolbar>
              
                    {this.state.refClick===1 &&

                     <Clients reAfficheListe = {this.props.reAfficheListe}
                     afficheClientClick= {(id) =>this.props.onClick(id)}>
                     
                       
                     </Clients>
                    }
                    {this.state.refClick===2 &&
                    
                        <Intervs
                        afficheClientClick= {(id) =>this.props.onClick(id)}>
                        </Intervs>
                    }
                    {this.state.refClick===3 &&
                     <ChoixAppels
                     afficheClientClick= {(id) =>this.props.onClick(id)}>
                     </ChoixAppels>
                    }
                    {this.state.refClick===4 &&
                    <Maints
                    afficheClientClick= {(id) =>this.props.onClick(id)}>
                    </Maints>
                    
                    }
                     {this.state.refClick===5 &&
               <div></div>
                    
                    }

                    {this.state.refClick===6 &&
                     <div></div>
                    
                    } 
            </Container>
        )
    }

}


const mapStateToProps = state => ({    
    actualUser: state.identity.user,
})
const mapDispatchToProps = ({
    setTitle,
    setError,
   
})

const classes = theme => ({
  bkcol: {
   /* backgroundColor: '#DBFCFB',// bleu pastel
    backgroundColor: '#92ECEB',// bleu ciel
    backgroundColor: '#F9D7D7',// rose pastel 
    backgroundColor: '#F49090',// rose 
    backgroundColor: '#BBD3F7',// bleu 
    backgroundColor: '#93F7E1', 
    backgroundColor: '#CFC961',// marron
    backgroundColor: '#A0F950',//vert
    backgroundColor: '#5ef2e2', 
  */ 
  },
  root: {
    scrollBehavior: 'smooth',
    flexGrow: 1,  
    backgroundColor: theme.bkcolor.bleuciel,
    height:'95vh',
  
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.bkcolor.marron,
    height:40,
    minHeight : 20,
    
  },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
      },
    memo: {
        textAlign: 'justify',
        marginBottom: '1.3em',
    },
    btn_class :{
        color:"primary",
    }
})

export default connect(mapStateToProps, mapDispatchToProps) (withStyles(classes) (MenuListes))
