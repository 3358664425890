
import DateAdapter from '@date-io/date-fns';
import { CircularProgress, Container, Toolbar, Typography, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/AddCircleOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import 'bootstrap/dist/css/bootstrap.min.css';
import { addMonths } from 'date-fns';
import fr from 'date-fns/locale/fr';
import EmailValidator from 'email-validator';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import SplitPane from "react-split-pane";
import { bindActionCreators } from 'redux';
import ResizableIconButton from '../../../core/components/window/ResizableIconButton';
import WaitBlock from '../../../core/components/window/WaitBlock';
import { setTitle } from '../../../core/redux/actions/WindowAppActions';
import '../../../stylesheets/Datepick.css';
import '../../../stylesheets/splitpane.css';
import '../../../stylesheets/tabpanel.css';
import { choisirClient, getClients, insertClient, updateClient } from '../../redux/actions/ClientActions';
import ButtonOw from '../window/ButtonOw';

registerLocale('fr', fr)

class TabPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: props.children.length === 0 ? null : 0,
      
    };

    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick(index) {
  
    this.setState({
      selectedTabIndex: index
     
   },()=>{
    this.props.onTabChange(index);
   })
  }

  _renderTabs() {
    let tabs = [];
    
    for (let i = 0; i < this.props.children.length; i++) {
      tabs.push(
        <Tab
          key={`tab-${i}`}
          label={this.props.children[i].props.title}
          index={i}
          isSelected={i === this.state.selectedTabIndex}
          onClick={this._handleClick} />
      );
    }

    return tabs;
  }
  
  _renderTabContent() {

  
    return this.props.children[this.state.selectedTabIndex].props.children;
  }

  render() {
    if (this.state.selectedTabIndex == null) {
      return 'nope';
    }
    
    return (
      <div className="tab-panel">
        <div className="tab-panel__header">
          {this._renderTabs()}
        </div>
        <div className="tab-panel__content">
          {this._renderTabContent()}
        </div>
      </div>
   );
  }
}

class Tab extends React.Component {
  constructor(props) {
    super(props);

    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick() {
    this.props.onClick(this.props.index);
  }

  render() {
    const tabClassName = this.props.isSelected ? "tab tab--selected" : "tab";

    return (
      <span className={tabClassName} onClick={this._handleClick}>
        <span className="tab__label">{this.props.label}</span>
      </span>
    );
  }
}






function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};




    class ClientForm extends Component {
      constructor(props) {
        super(props);
        this.state = {  
              num: 0,                   
              editMode:false,
              addMode:false,
              numerovalid:true,
              numerozero:false,
              nomvalid : true,
              pasdeModif:false,
              begin : null,
              end : null,            
              idx:0,
              idx1:0,
              modifEncours :false,
              ajoutPossible : true,
              numberformat: '1320',
              selectedDateTime: "2021-06-25",
              datedujour :"2020-07-28",
              errorEmail:{
                error:false,
                name:'',
              },
              localvalue:{
                label:"ARRET",  
                code:"1",
                index:1,
              },          
              zclient:{        
                numero:'',
                societe:'',
                adr1:'',
                adr2:'',
                cp:'',
                ville:'',		
                pays:'',
                lat:0,
                lng:0,
                numcompta :'',
                abr_admin :0,
                nom_admin :'',
                fct_admin :'',
                obs_admin :'',
                ind_admin :'',
                tel_admin :'',
                mob_admin :'',
                fax_admin :'',
                mail_admin :'',
                abr_compta :0,
                nom_compta :'',
                fct_compta :'',
                obs_compta :'',
                tel_compta :'',
                mob_compta :'',
                fax_compta :'',
                mail_compta :'',
                abr_sceinfo :0,
                nom_sceinfo :'',
                fct_sceinfo :'',
                obs_sceinfo :'',
                tel_sceinfo :'',
                mob_sceinfo :'',
                fax_sceinfo :'',
                mail_sceinfo :'',
                abr_utilisat :0,
                nom_utilisat :'',
                fct_utilisat :'',
                obs_utilisat :'',
                tel_utilisat :'',
                mob_utilisat :'',
                fax_utilisat :'',
                mail_utilisat :'',
                abr_utilisat2 :0,
                nom_utilisat2 :'',
                fct_utilisat2 :'',
                obs_utilisat2 :'',
                tel_utilisat2 :'',
                mob_utilisat2 :'',
                fax_utilisat2 :'',
                mail_utilisat2 :'',
                optigest :'',
                optimalpc :'',
                bornedtco :'',
                borneoptimalweb :'',
                bornelafon :'',
                autreborne :'',
                bornescanner :'',
                optimalweb :'',
                webinfrac :'',
                webpaie  :'',
                webgeoloc  :'',
                codeinst :'',
                typeinst :'',
                codeform :'',
                typeform :'',
                notes :'',              
                infos :'',
                cloture :'N',
                cause :'',
                satisfait :'',
                date_inst:new Date(),
                equipement_geoloc :'',
                boitiers_geoloc :0,
                fournisseur_geoloc :'',
                infos_geoloc :'',
                boitiers_tachy :0,
                nb_vehic:0,
                nb_chauf :0,
                nb_tachy_num :0,
                nb_tachy_com :0,              
              },client:null
              ,          
              longclient:{        
                numero:0,
                societe:36,
                adr1:35,
                adr2:35,
                cp:0,
                ville:30,		
                pays:20,
                lat:0,
                lng:0,
                numcompta :0,
                abr_admin :0,
                nom_admin :20,
                fct_admin :20,
                obs_admin :30,
                ind_admin :0,
                tel_admin :0,
                mob_admin :0,
                fax_admin :0,
                mail_admin :50,
                abr_compta :0,
                nom_compta :20,
                fct_compta :20,
                obs_compta :30,
                tel_compta :0,
                mob_compta :0,
                fax_compta :0,
                mail_compta :50,
                abr_sceinfo :0,
                nom_sceinfo :20,
                fct_sceinfo :20,
                obs_sceinfo :30,
                tel_sceinfo :15,
                mob_sceinfo :15,
                fax_sceinfo :15,
                mail_sceinfo :50,
                abr_utilisat :0,
                nom_utilisat :20,
                fct_utilisat :20,
                obs_utilisat :30,
                tel_utilisat :0,
                mob_utilisat :0,
                fax_utilisat :0,
                mail_utilisat :50,
                abr_utilisat2 :0,
                nom_utilisat2 :20,
                fct_utilisat2 :20,
                obs_utilisat2 :30,
                tel_utilisat2 :0,
                mob_utilisat2 :0,
                fax_utilisat2 :0,
                mail_utilisat2 :50,
                optigest :0,
                optimalpc :0,
                bornedtco :0,
                borneoptimalweb :0,
                bornelafon :0,
                autreborne :0,
                bornescanner :0,
                optimalweb :0,
                webinfrac :0,
                webpaie  :0,
                webgeoloc  :0,
                codeinst :0,
                typeinst :0,
                codeform :0,
                typeform :0,
                notes :0,              
                infos :0,
                cloture :0,
                cause :0,
                satisfait :0,
                date_inst:0,
                equipement_geoloc :0,
                boitiers_geoloc :0,
                fournisseur_geoloc :30,
                infos_geoloc :0,
                boitiers_tachy :0,
                nb_vehic:0,
                nb_chauf :0,
                nb_tachy_num :0,
                nb_tachy_com :0,              
              },
            }
        
            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
            this.handleEmailChange = this.handleEmailChange.bind(this);         
            this.handleTestChange  = this.handleTestChange.bind(this);            
            this._handleTabChange1 = this._handleTabChange1.bind(this);
            this._handleTabChange = this._handleTabChange.bind(this);
            this.modifdatenulle = this.modifdatenulle.bind(this);
            this.numeroIsvalid = this.numeroIsvalid.bind(this);
            this.nomIsvalid = this.nomIsvalid.bind(this);
       }   
        _handleTabChange1(index) {
         
          this.setState({
            idx1 : index,
            }); 
        
        }

        _handleTabChange(index) {
     
          this.setState({
            idx : index,
            }); 
        
        }

        datesInit = () => {

          const dateAdapter = new DateAdapter ()
              
          let begin = dateAdapter.startOfDay (new Date())
          let end = dateAdapter.endOfDay (new Date ())
          begin = addMonths (begin, -1)
          
          return {
            begin: begin,
            end: end,
          }
        }  
          dateChange = (value, name) => {
            this.setState ({
              [name]: value
            })
          }

         
          typeinstallateur = [
            {label:"", code:"0"},
            {label:"Nicolas MEDINA", code:"NME"},
            {label:"Jean Jacques BARET", code:"JJB"},
            {label:"Claude CASTELLO", code:"CLC"},
          ];
           typeinstal = [
            {label:"", code:"0"},
            {label:"Sur Site", code:"SI"},
            {label:"PEM A Distance", code:"PM"},            
            {label:"Par Téléphone", code:"TL"},
           ];
           typeformateur = [
            {label:"", code:"0"},
            {label:"Nicolas MEDINA", code:"NME"},
            {label:"Jean Jacques BARET", code:"JJB"},
            {label:"Claude CASTELLO", code:"CLC"},
          ];
           typeformation = [
            {label:"", code:"0"},
            {label:"Sur Site", code:"SI"},
            {label:"PEM A Distance", code:"PM"},            
            {label:"Par Téléphone", code:"TL"},
           ];

           typeabreviation = [
            {label:"", code:0},
            {label:"Mr", code:1},
            {label:"Mme", code:2},
            {label:"Mle", code:3},
            
          ];
          
           typesatisfait = [
            {label:"", code:"0"},
            {label:"Très Mécontent", code:"1"},
            {label:"Mécontent", code:"2"},
            {label:"Moyen.", code:"3"},
            {label:"Satisfait", code:"4"},
            {label:"Très Satisfait", code:"5"},
          ];
          typecloture = [
            {label:"", code:"0"},
            {label:"ARRET", code:"1"},
            {label:"PRIX", code:"2"},
            {label:"CONCURRENT", code:"3"},
            {label:"PB MAINT", code:"4"},
          ];

          listmodif = [];
            
          clientinit={        
            numero:5356,
            societe:'04 FRANCE EXPRESS (CALBERSON)',
            adr1:'ZA du Mardaric',
            adr2:'',
            cp:'04310',          
            ville:'PEYRUIS',		
            pays:'FR',
            lat:0,
            lng:0,
            numcompta :5356,
            abr_admin :1,
            nom_admin :'COUET',
            fct_admin :'',
            obs_admin :'',
            ind_admin :'33',
            tel_admin :'04.92.68.07.08',
            mob_admin :'BAILLE Agnès"',
            fax_admin :'',
            mail_admin :'exploit.04express@geodis.com',
            abr_compta :1,
            nom_compta :'IZIKI Khalid',
            fct_compta :'',
            obs_compta :'',
            tel_compta :'01.71.15.62.19',
            mob_compta :'06.99.02.56.15',
            fax_compta :'',
            mail_compta :'khalid.iziki@geodis.com',
            abr_sceinfo :0,
            nom_sceinfo :'MACHADO Christian',
            fct_sceinfo :'',
            obs_sceinfo :'',
            tel_sceinfo :'',
            mob_sceinfo :'',
            fax_sceinfo :'',
            mail_sceinfo :'exploit.04express@geodis.com',
            abr_utilisat :2,
            nom_utilisat :'BAILLE Agnès',
            fct_utilisat :'',
            obs_utilisat :'DIRECTRICE',
            tel_utilisat :'04.92.68.00.19',
            mob_utilisat :'06.28.60.37.59',
            fax_utilisat :'',
            mail_utilisat :'agnes.baille@geodis.com',
            abr_utilisat2 :0,
            nom_utilisat2 :'',
            fct_utilisat2 :'',
            obs_utilisat2 :'',
            tel_utilisat2 :'',
            mob_utilisat2 :'',
            fax_utilisat2 :'',
            mail_utilisat2 :'',
            optigest :'N',
            optimalpc :'N',
            bornedtco :'N',
            borneoptimalweb :'O',
            bornelafon :'N',
            autreborne :'N',
            bornescanner :'N',
            optimalweb :'O',
            webinfrac :'O',
            webpaie  :'N',
            webgeoloc  :'N',
            codeinst :'NME',
            typeinst :'SI',
            codeform :'JJB',
            typeform :'TL',
            notes :'Notes Techniques et inforamtions importantes',
            infos :'inforamtions importantes',
            cloture :'N',
            cause :'PRIX',
            satisfait :'4',
            date_inst:new Date('2008-11-26'),
            equipement_geoloc :'N',
            boitiers_geoloc :0,
            fournisseur_geoloc :'',
            infos_geoloc :'',
            boitiers_tachy :0,
            nb_vehic:0,
            nb_chauf :0,
            nb_tachy_num :0,
            nb_tachy_com :0,
          }


         componentDidMount() {
             this.clientinit = this.state.client;
             var today = new Date();                     
             var sYear = today.getFullYear().toString ();
             var sMonth = ('0'+(today.getMonth() + 1)).slice(-2);
             var sDay =   ('0'+today.getDate()).slice(-2);
             var datejour =   sYear+'-'+sMonth +'-'+sDay;             

              this.setState({
               datedujour : datejour,
               }); 
             
          
        }

        componentDidUpdate(prevProps, prevState) {
        
          if ((this.props.clients) && (this.props.num !==0)) {
            if (this.state.num !== this.props.num) {   
              let client = this.props.client.value[0];
            
            
              for (let elt in client) {
                
                if (client.hasOwnProperty(elt)) {
                 
                    if (client[elt] === null) {
                      if (typeof(elt) === 'string') {
                           client[elt] ="";                
                      }
                      else {
                        client[elt] =0;
                      }
                    }                    
                  }
                
               }
             
             
               this.setState({
                num: this.props.num,
                client : client,
                numerovalid : true,
                nomvalid:true,
             
             },()=>{
              
               this.changementClient ();
             })


              
            }    
          }        
        }
        
        resetClick = () => {

    
          this.setState({
            ajoutPossible: true,
          })  
        }
  
        changementClient () {
            this.verifdateinst();
            this.clientinit = this.state.client;
            
        }
        verifdateinst () {
            
              var  tmpdat = this.state.client.date_inst;  
              
              var datz = "";
            
                try {
                                     
                  if (tmpdat != null) {
                    datz = tmpdat.substr(6,4)+'-'+ tmpdat.substr(3,2)+'-'+tmpdat.substr(0,2);                  
            
                  }
                  else  { 
                    datz = '';    
                  }
                
                } catch(e) {
                  datz = '';  
            
                
                }
              //  localdate = '2021-07-27';
                
                this.setState({
                  selectedDateTime : datz,
                 
               },()=>{
                  // It is an callback function.
                  // Here you can access the update value
            
               
               })
            
        }
        
        modifdatenulle () {
          var datz = '';  
          this.setState({
            selectedDateTime : datz,
           
         },()=>{
            // It is an callback function.
            // Here you can access the update value
         
          
         })
         const field = {}
         const fieldName= 'date_inst';  
          field[fieldName]=  null;
    
         let dejaexist = false;
         for (var key in this.listmodif) {
           var obj = this.listmodif[key];
           for (var prop in obj) {
    
             if (prop === fieldName) {
               //obj[prop] = event.target.value;     
               dejaexist = true;
             }               
                          
           };  
         }
         if (!dejaexist) {
           this.listmodif.push (field);

         }

         const client = Object.assign({}, this.state.client, field)                
         this.setState({
             client: client,
         })
    

        }

        numeroIsvalid (num) {
   
          var znum = parseInt(num);
          let refclient = this.props.clients.find(item =>item.numero === znum);  
          
    
          if (refclient === undefined)  {
            if (num ===0) {
              return false;
            }
            else return true ;
          }
          else return false;
        }

        nomIsvalid (nom) {
   
          let refclient = this.props.clients.find(item =>item.societe === nom);  
       
          if (refclient === undefined)  {return true;}
          else return false;
        }

         handleInputChange(evt) {
            const field = {}
            const fieldName= evt.target.name     
            var str = evt.target.value;
            if (fieldName ==='numero') {
              
              var ctln = this.numeroIsvalid(evt.target.value);
              this.setState({
                numerovalid: ctln,
               })
               if (evt.target.value !== '') {
               let num = parseInt(evt.target.value);
             
                if  (num ===0) {
             
                 
                  this.setState({
                    numerozero : true,
                   })
                }
                else {
                  this.setState({
                    numerozero : false,
                   })
                }

               }
              
              

              
            }
            if (fieldName ==='societe') {
              str = (str).toUpperCase();
              var ctls = this.nomIsvalid(str);
              this.setState({
                nomvalid: ctls,
               })
            }

            if (fieldName ==='ville') {
              str = (str).toUpperCase();
           
            }

            str = str.replace(/'/g,'"');     
            field[fieldName] =  str;
         
            var long =this.state.longclient[fieldName]          
            if ((long>0) && (str.length > long)) {
              str = str.substring(0,long);
              field[fieldName] =  str;              
            }  
            const client = Object.assign({}, this.state.client, field)            
          
            let dejaexist = false;
            for (var key in this.listmodif) {
              var obj = this.listmodif[key];
              for (var prop in obj) {                
                if (prop === fieldName) {
                  obj[prop] = str;
                  dejaexist = true;
                }               
                             
              };  
            }
            if (!dejaexist) {
              this.listmodif.push (field);
            }
        
            /*
            let refclient = this.props.clients.find(item =>item.numero === this.props.num);  
            let id = driver.contrats.findIndex(c => c.id_contrat === contract.id_contrat)
          
            */
         
            this.setState({
              client: client,             
             })
         
         
        }
        handleEmailChange(evt) {
            const field = {}
            const fieldName= evt.target.name          
            field[fieldName] =  evt.target.value;           
           
            const client = Object.assign({}, this.state.client, field)
            let dejaexist = false;
            for (var key in this.listmodif) {
              var obj = this.listmodif[key];
              for (var prop in obj) {
                
                if (prop === fieldName) {
                  obj[prop] = evt.target.value;
                  dejaexist = true;
                }               
                             
              };  
            }
            if (!dejaexist) {
              this.listmodif.push (field);
            }
            this.setState({
              client: client,
            })
   
        }

       

        handleTestChange = name => event => {
            this.setState({
              [name]: event.target.value,
            });
        };
        

        handleCheckboxChange(evt) {
            
            const checked = evt.target.checked;                  
            const field = {}
            const fieldName= evt.target.name   
            const value = (checked?'O':'N');
            field[fieldName] =  value;   
            const client = Object.assign({}, this.state.client, field);
       
            let dejaexist = false;
            for (var key in this.listmodif) {
              var obj = this.listmodif[key];
              for (var prop in obj) {
       
                if (prop === fieldName) {
                  obj[prop] = evt.target.value;
                  dejaexist = true;
                }            
              };  
            }
            if (!dejaexist) {
              this.listmodif.push (field);
            }
       
            this.setState({
              client: client,
            })
       
            
        }

       

        

        //For checking if a string is empty, null or undefined I use:

        isEmpty(str) {
            return (!str || str.length === 0 );
        }

        //For checking if a string is blank, null or undefined I use:

        isBlank(str) {
            return (!str || /^\s*$/.test(str));
        }

          
        updateSelectedDateTime = event => {
          this.setState({ selectedDateTime: event.target.value });
     
          const field = {}
          const fieldName= event.target.id;  
          if (!this.isEmpty(event.target.value)) {          
            
            field[fieldName] = new Date(event.target.value);
          }
          else {
            field[fieldName] = null;
          
          }
     
          let dejaexist = false;
          for (var key in this.listmodif) {
            var obj = this.listmodif[key];
            for (var prop in obj) {
     
              if (prop === fieldName) {
                obj[prop] = event.target.value;     
                dejaexist = true;
              }               
                           
            };  
          }
          if (!dejaexist) {
            this.listmodif.push (field);

          }

          const client = Object.assign({}, this.state.client, field)                
          this.setState({
              client: client,
          })
         
        }


        
          switchEdit = () => {
            localStorage.setItem('Suiviclients_Search_modifEncours', 1);                                 

           this.setState({
              editMode: true,
           },()=>{
            this.clientinit = this.state.client;
           })
          
        }
        switchCancel = () => {        
                 
          localStorage.setItem('Suiviclients_Search_modifEncours', 0);
          localStorage.setItem('Suiviclients_Search_ajoutPossible', 1);
                 
          while(this.listmodif.length > 0) {
            this.listmodif.pop();
          }
          this.setState({
              editMode: false,
              addMode:false,
              numerovalid:true,
              nomvalid:true,
              pasdeModif:false,
              ajoutPossible :true,
          })   
          this.setState({
            client: this.clientinit,
         },()=>{
            // It is an callback function.
            // Here you can access the update value
            this.verifdateinst ();
         })
             
        }
        switchAdd = () => {
          const displayCode = (localStorage.getItem('Suiviclients_Search_modifEncours') === '0') 
          if (displayCode) {

              localStorage.setItem('Suiviclients_Search_modifEncours', 1);
              localStorage.setItem('Suiviclients_Search_ajoutPossible', 0);    
            this.setState({
                client: this.state.zclient,
                numerovalid :false,
                nomvalid:false,
              },()=>{
                // It is an callback function.
                // Here you can access the update value
                this.verifdateinst ();
                
                this.setState({
                  editMode: true,
                  addMode:true,
              })
             })
          }else {
            this.setState({
              ajoutPossible :false,
            },()=>{
             
            
            })
          

          }
        
          
        }

        switchValid = () => {


          const field = {}
          const fieldName = "numero";
          field[fieldName] =  this.state.client.numero;
          
          this.listmodif.push(field);
          var nbmodif =0;
          var changes = {};
          var chglist = false;
          
          for (var key in this.listmodif) {
            var obj = this.listmodif[key];
            for (var prop in obj) {
          
                 // eslint-disable-next-line
              if ((prop =='ville') || (prop === 'cp') || (prop === 'numcompta') || (prop ==='societe')) {
                  chglist = true;
              }
              changes[prop] = obj[prop];
              if (prop !=='numero') {
                nbmodif ++;
              }
            
              }            
            };  
         
            while(this.listmodif.length > 0) {
              this.listmodif.pop();
            }
            
          if (!this.state.addMode) {
        
           
            if (nbmodif  === 0)   {
              this.setState({
                 pasdeModif :true,
               })
            
            }
            else {
                this.props.updateClient (changes)
                .then ( (resu) => {          
            
                  if (resu.value ===1) {        
                                                  
                      localStorage.setItem('Suiviclients_Search_modifEncours', 0);
                      localStorage.setItem('Suiviclients_Search_ajoutPossible', 1);
                      this.setState({
                          editMode: false,
                          addMode:false,
                          pasdeModif :false,
                      }) 
                      if (chglist) {
                        this.props.afficheListeClients(this.state.client.numero); 
                      }
                                
                    }
                    else {
                      
                    }
                }
                , (error) => {
                  console.warn('Erreur update', error)
                  
                })
              }
          }
          else
          {
            this.props.insertClient (changes)
            .then ( (resu) => {          
           
              if (resu.value === true) {        
                                               
                  localStorage.setItem('Suiviclients_Search_modifEncours', 0);
                  
                  this.setState({
                      editMode: false,
                      addMode:false,
                      pasdeModif :false,
                  })   
                  
                  // il faut recharger la liste des clients
                  this.props.afficheListeClients(this.state.client.numero);
                }
                else {
                  
                }
            }
            , (error) => {
              console.warn('Erreur update', error)
              
            })

          }
          
        }

    
        

    render() {

      if ( this.state.num === 0)      
            {
              return (
              <Container >
                   
                  <WaitBlock text="Chargement en cours..."/> 
              </Container>
              )
          } else {                 

        return (
      
         
        <Container className={this.props.classes.root}>
          
            <Toolbar className={this.props.classes.toolbar}>
              
                <Typography className={this.props.classes.title} variant="h5">Client</Typography>
                <ButtonOw 
                    title='Modification' 
                    disabled= {this.state.editMode} 
                    onClick={this.switchEdit}>
                    <EditIcon style={!this.state.editMode?{ color: "#000000",visibility:"visible" }:{ color: "#f0621f",visibility:"hidden"}} />
                  </ButtonOw>
                  <ButtonOw 
                    title='Valider' 
                    disabled= {!this.state.editMode ||!this.state.numerovalid  || !this.state.nomvalid}
                    onClick={this.switchValid}>                       
                    <DoneIcon style={!this.state.editMode?{ color: "#ffffff",visibility:"hidden" }:{ color: "#f0621f",visibility:"visible"}} />
                  </ButtonOw>
                  <ButtonOw 
                    title='Annuler' 
                    disabled={!this.state.editMode}
                    onClick={this.switchCancel}>                          
                    <CancelIcon style={!this.state.editMode?{ color: "#ffffff",visibility:"hidden" }:{ color: "#f0621f",visibility:"visible"}} />
                  </ButtonOw>
                  <ButtonOw 
                    title='Ajouter un client'   
                    disabled= {this.state.editMode}                                                  
                    onClick={this.switchAdd}>
                    <AddIcon style={this.state.editMode?{ color: "#ffffff",visibility:"hidden" }:{ color: "#000000",visibility:"visible"}} />
                  </ButtonOw>  
                  <ButtonOw 
                    title='Accès à INES' 
                    onClick={this.switchInes}>
                    <SearchIcon style={{ color: "#0ee73f"} }/>
                  </ButtonOw>
                                    
                
                
            </Toolbar>
            <Container className={this.props.classes.root}>
            {!this.state.ajoutPossible ? 
              <div className="outlined_encours">  
                  <CircularProgress className="margin-t-15 margin-l-5" />
                  <label className="label margin-t-15">  
                      Modification en cours - Action Non Autorisée                    
                  </label>                        
                  <ResizableIconButton   title="Cliquer pour annuler"
                      onClick={this.resetClick}>
                      <CancelIcon   style= {{ color: "#ff0000",visibility:"visible", fontSize: 48}} />                                                                 
                  </ResizableIconButton>
              </div>:
               <div>
               {((!this.state.editMode) || (this.state.numerovalid  && this.state.nomvalid) ) &&
                    <br></br> 
               }     
                 
               {((this.state.addMode) && (!this.state.numerovalid) ) &&
                
                 <div>
                    <label className="label-red"> 
                    {!this.state.numerozero?'Numéro déjà existant !': 'Numéro doit être supérieur à 0 !'}  
                 </label>  
                 </div>
               }  
               {((this.state.addMode) && (this.state.numerovalid  && !this.state.nomvalid) ) &&
                
                <div>
                   <label className="label-red">
                   {this.state.client.societe !==""?'Nom société déjà existant  !': 'nom Société ne peut être vide !'}  
                     
                </label>  
                </div>
               }  
                {((this.state.editMode) && (this.state.pasdeModif) ) &&
                
                <div>
                   <label className="label-red">
                   Pas de modification de données, Validation inutile !  
                     
                </label>  
                </div>
               }
               



                     

                <FormControl key={`frmCtrl_${this.state.num}`} className="formLabel" >
                <div>
                  <label className="label"> 
                    Code :  
                      <NumberFormat  className="input width70" name ="numero"
                        isNumericString format="######" min={1}
                        disabled={!this.state.addMode}
                        required={true}                       
                        value={this.state.client.numero}                         
                        onChange = {this.handleInputChange }/>

                    </label>
                    
                    <label className="label" > 
                    Société :
                      <input className="input width300"
                        name="societe"
                        type="text"
                        disabled={!this.state.editMode}
                        required={true}
                        value={this.state.client.societe}
                        onChange={this.handleInputChange} />
                    </label>
                    <label className="label">
                    N° Compta :                     
                      <NumberFormat  className="input width70" name ="numcompta"
                        isNumericString format="######"
                        disabled={!this.state.editMode}
                        required={true}
                        value={this.state.client.numcompta} 
                        onChange = {this.handleInputChange }/>  
                    </label>
                  </div>
                       
                  <div>
                    <label className="label">
                    Adresse :
                    <input className= "input width300" 
                        name="adr1"
                        type="text"
                        value={this.state.client.adr1}
                        disabled={!this.state.editMode}
                        onChange={this.handleInputChange} />
                    <input className="input width300"
                        name="adr2"
                        type="text"
                        disabled={!this.state.editMode}
                        value={this.state.client.adr2}
                        onChange={this.handleInputChange} />    
                    </label>
                  </div>
                  <div>
                    <label className="label">
                    C.Postal :                    
                    <NumberFormat  className="input width70" name ="cp"
                    isNumericString format="#######"
                    disabled={!this.state.editMode}
                    value={this.state.client.cp} 
                    onChange = {this.handleInputChange }/>
                     
                    </label>    
                    <label className="label">
                    Ville :       
                    <input className="input width300"
                        name="ville"
                        type="text"
                        disabled={!this.state.editMode}
                        value={this.state.client.ville}
                        onChange={this.handleInputChange} />    
                    </label>
                    <label className="label">
                    Pays :       
                    <input className="input width200"
                        name="pays"
                        type="text"
                        disabled={!this.state.editMode}
                        value={this.state.client.pays}
                        onChange={this.handleInputChange} />    
                    </label>
                  </div>                 
                               
                  <TabPanel  onTabChange={this._handleTabChange1}>
                    <div  title="Contacts" >
                      <TabPanel  onTabChange={this._handleTabChange}>
                          <div title="Administratif">
                              <div>   
                                <br></br>                        
                                <label className="label margin-l-5">
                                  Nom :                                       
                                  <select name="abr_admin" value={this.state.client.abr_admin}
                                     disabled={!this.state.editMode}
                                      onChange={this.handleInputChange}>
                                      {this.typeabreviation.map((option) => (
                                          <option value={option.code}>{option.label}</option>
                                          ))}
                                </select> 

                                  <input className="input width200"
                                      name="nom_admin"
                                      type="text"
                                      disabled={!this.state.editMode}
                                      value={this.state.client.nom_admin}
                                      onChange={this.handleInputChange} />
                                </label>
                                <label className="label">
                                  Fonction :
                                  <input className="input width200"
                                      name="fct_admin"
                                      type="text"
                                      disabled={!this.state.editMode}
                                      value={this.state.client.fct_admin}
                                      onChange={this.handleInputChange} />
                                </label>
                              </div>
                              <div>                                                      
                                <label className="label margin-l-5">
                                  Obs :
                                  <input className="input width300"
                                      name="obs_admin"
                                      type="text"
                                      disabled={!this.state.editMode}
                                      value={this.state.client.obs_admin}
                                      onChange={this.handleInputChange} />
                                </label>
                              </div>
                              <div>
                                <label className="label margin-l-5"> 
                                  Tel :
                                  <NumberFormat  className="input width130" name ="tel_admin"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.tel_admin}
                                    onChange = {this.handleInputChange }/>                                  
                                </label>                            
                                <label className="label"> 
                                  Mobile :
                                  <NumberFormat  className="input width130" name ="mob_admin"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.mob_admin}
                                    onChange = {this.handleInputChange }/>                               
                                </label>
                                <label className="label">
                                Fax :                                                                  
                                    <NumberFormat  className="input width130" name ="fax_admin"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.fax_admin}
                                    onChange = {this.handleInputChange }/> 
                                </label>
                              </div>
                              <div>                   
                                <label className="label margin-l-5">
                                  Email :
                                  <input className="input width300"
                                      name="mail_admin"
                                      type="text"
                                      disabled={!this.state.editMode}
                                      value={this.state.client.mail_admin}
                                      onChange={this.handleEmailChange} />
                                </label>      
                                <label className= {(this.state.client.mail_admin  && EmailValidator.validate(this.state.client.mail_admin))? "label-hidden": "label-red"}> 
                                   Email non valide !  
                                </label>   
                              </div>  
                          </div>   
                          <div title="Utilisateur 1">
                            <div>   
                              <br></br>                        
                              <label className="label margin-l-5">
                                Nom :                                    
                                <select name="abr_utilisat" value={this.state.client.abr_utilisat}
                                     disabled={!this.state.editMode}
                                      onChange={this.handleInputChange}>
                                      {this.typeabreviation.map((option) => (
                                          <option value={option.code}>{option.label}</option>
                                          ))}
                                </select>                         
                                <input className="input width200"
                                    name="nom_utilisat"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.nom_utilisat}
                                    onChange={this.handleInputChange} />
                              </label>
                              <label className="label">
                                Fonction :
                                <input className="input width200"
                                    name="fct_utilisat"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.fct_utilisat}
                                    onChange={this.handleInputChange} />
                              </label>
                            </div>
                            <div>                                                
                              <label className="label margin-l-5">
                                Obs :
                                <input className="input width300"
                                    name="obs_utilisat"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.obs_utilisat}
                                    onChange={this.handleInputChange} />
                              </label>
                            </div>
                            <div> 
                              <label className="label margin-l-5"> 
                               Tel :
                                <NumberFormat  className="input width130" name ="tel_utilisat"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.tel_utilisat}
                                    onChange = {this.handleInputChange}/>                                  
                              </label>                            
                              <label className="label"> 
                                Mobile :
                                  <NumberFormat  className="input width130" name ="mob_utilisat"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.mob_utilisat}
                                    onChange = {this.handleInputChange}/>                               
                                </label>
                              <label className="label">
                              Fax :                             
                                 <NumberFormat  className="input width130" name ="fax_utilisat"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.fax_utilisat}
                                    onChange = {this.handleInputChange }/>   
                              </label>
                            </div>
                            <div>                   
                              <label className="label margin-l-5">
                                Email :
                                <input className="input width300"
                                      name="mail_utilisat"
                                      type="text"
                                      disabled={!this.state.editMode}
                                      value={this.state.client.mail_utilisat}
                                      onChange={this.handleEmailChange} />
                              </label>
                              <label className= {(this.state.client.mail_utilisat  && EmailValidator.validate(this.state.client.mail_utilisat))? "label-hidden": "label-red"}> 
                                   Email non valide !  
                               </label> 
                            </div>
                          </div>
                          <div title="Utilisateur 2">
                            <div>   
                              <br></br>                        
                              <label className="label margin-l-5">
                                Nom :
                                <select name="abr_utilisat2" value={this.state.client.abr_utilisat2}
                                     disabled={!this.state.editMode}
                                      onChange={this.handleInputChange}>
                                      {this.typeabreviation.map((option) => (
                                          <option value={option.code}>{option.label}</option>
                                          ))}
                                </select>   
                                <input className="input width200"
                                    name="nom_utilisat2"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.nom_utilisat2}
                                    onChange={this.handleInputChange} />
                              </label>
                              <label className="label">
                                Fonction :
                                <input className="input width200"
                                    name="fct_utilisat2"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.fct_utilisat2}
                                    onChange={this.handleInputChange} />
                              </label>
                            </div>
                            <div>                                                     
                              <label className="label margin-l-5">
                                Obs :
                                <input className="input width300"
                                    name="obs_utilisat2"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.obs_utilisat2}
                                    onChange={this.handleInputChange} />
                              </label>
                            </div>
                            <div>
                              <label className="label margin-l-5"> 
                               Tel :
                                <NumberFormat  className="input width130" name ="tel_utilisat2"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.tel_utilisat2}
                                    onChange = {this.handleInputChange}/>                                  
                              </label>                            
                              <label className="label"> 
                                Mobile :
                                  <NumberFormat  className="input width130" name ="mob_utilisat2"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.mob_utilisat2}
                                    onChange = {this.handleInputChange}/>                               
                                </label>
                              <label className="label">
                              Fax :                              
                                 <NumberFormat  className="input width130" name ="fax_utilisat2"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.fax_utilisat2}
                                    onChange = {this.handleInputChange }/>    
                              </label>
                            </div>
                            <div>                   
                              <label className="label margin-l-5">
                                Email :
                                <input className="input width300"
                                    name="mail_utilisat2"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.mail_utilisat2}
                                    onChange={this.handleEmailChange} />
                              </label>
                              <label className= {((this.state.client.mail_utilisat2 ==='') || EmailValidator.validate(this.state.client.mail_utilisat2))? "label-hidden": "label-red"}> 
                                   Email non valide !  
                              </label> 
                            </div>
                          </div>
                          <div title="Sce Informatique">
                            <div>   
                              <br></br>                        
                              <label className="label margin-l-5">
                                Nom :                                    
                                <select name="abr_sceinfo" value={this.state.client.abr_sceinfo}
                                     disabled={!this.state.editMode}
                                      onChange={this.handleInputChange}>
                                      {this.typeabreviation.map((option) => (
                                          <option value={option.code}>{option.label}</option>
                                          ))}
                                </select>                            
                                <input className="input width200"
                                    name="nom_sceinfo"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.nom_sceinfo}
                                    onChange={this.handleInputChange} />
                              </label>
                              <label className="label">
                                Fonction :
                                <input className="input width200"
                                    name="fct_sceinfo"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.fct_sceinfo}
                                    onChange={this.handleInputChange} />
                              </label>
                            </div>
                            <div>                                                      
                              <label className="label margin-l-5">
                                Obs :
                                <input className="input width300"
                                    name="obs_sceinfo"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.obs_sceinfo}
                                    onChange={this.handleInputChange} />
                              </label>
                            </div>
                            <div>
                              <label className="label margin-l-5"> 
                               Tel :
                               <NumberFormat  className="input width130" name ="tel_sceinfo"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.tel_sceinfo}
                                    onChange = {this.handleInputChange }/>                                  
                              </label>                            
                              <label className="label"> 
                                Mobile :
                                <NumberFormat  className="input width130" name ="mob_sceinfo"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.mob_sceinfo}
                                    onChange = {this.handleInputChange }/>                               
                              </label>
                              <label className="label">
                              Fax :                             
                                <NumberFormat  className="input width130" name ="fax_sceinfo"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.fax_sceinfo}
                                    onChange = {this.handleInputChange }/>      
                              </label>
                            </div>
                            <div>                   
                              <label className="label margin-l-5">
                                Email :
                                <input className="input width300"
                                    name="mail_sceinfo"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.mail_sceinfo}
                                    onChange={this.handleEmailChange} />
                              </label>
                              <label className= {(this.state.client.mail_sceinfo  && EmailValidator.validate(this.state.client.mail_sceinfo))? "label-hidden": "label-red"}> 
                                   Email non valide !  
                              </label> 
                            </div>
                          </div>
                          <div title="Comptabilité">
                            <div>   
                              <br></br>                        
                              <label className="label margin-l-5">
                                Nom :
                                <select name="abr_compta" value={this.state.client.abr_compta}
                                     disabled={!this.state.editMode}
                                      onChange={this.handleInputChange}>
                                      {this.typeabreviation.map((option) => (
                                          <option value={option.code}>{option.label}</option>
                                          ))}
                                </select>                              
                                <input className="input width200"
                                    name="nom_compta"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.nom_compta}
                                    onChange={this.handleInputChange} />
                              </label>
                              <label className="label">
                                Fonction :
                                <input className="input width200"
                                    name="fct_compta"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.fct_compta}
                                    onChange={this.handleInputChange} />
                              </label>
                            </div>
                            <div>                                                     
                              <label className="label margin-l-5">
                                Obs :
                                <input className="input width300"
                                    name="obs_compta"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.obs_compta}
                                    onChange={this.handleInputChange} />
                              </label>
                            </div>
                            <div>
                              <label className="label margin-l-5"> 
                                Tel :
                                <NumberFormat  className="input width130" name ="tel_compta"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.tel_compta}
                                    onChange = {this.handleInputChange }/>                                  
                              </label>                            
                              <label className="label"> 
                                Mobile :
                                <NumberFormat  className="input width130" name ="mob_compta"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.mob_compta}
                                    onChange = {this.handleInputChange }/>                               
                              </label>
                              <label className="label">
                              Fax :                             
                                <NumberFormat  className="input width130" name ="fax_compta"
                                    format="##.##.##.##.##"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.fax_compta}
                                    onChange = {this.handleInputChange }/>     
                              </label>
                            </div>
                            <div>                   
                              <label className="label margin-l-5">
                                Email :
                                <input className="input width300"
                                    name="mail_compta"
                                    type="text"
                                    disabled={!this.state.editMode}
                                    value={this.state.client.mail_compta}
                                    onChange={this.handleEmailChange} />
                                </label>
                                <label className= {(this.state.client.mail_compta  && EmailValidator.validate(this.state.client.mail_compta))? "label-hidden": "label-red"}> 
                                   Email non valide !  
                                </label> 
                            </div>
                          </div>
                      </TabPanel>                  
                    </div>
                    <div title="Modules">                     
                      <FormControl className = {this.props.classes.formControl}>
                        <FormLabel component="legend">Applications</FormLabel>
                        <FormGroup>
                          <FormControlLabel className = "LabelCheckBox"
                            disabled={!this.state.editMode}
                            control={
                            <Checkbox                              
                              checked={this.state.client.optigest ==='O'}  
                              value={this.state.client.optigest}
                              onChange={this.handleCheckboxChange}    
                              color="primary"                         
                              size="small"                                                    
                              name="optigest" />}
                            label="Optigest"
                          />
                          <FormControlLabel 
                          disabled={!this.state.editMode}
                            control={
                            <Checkbox className = "LabelCheckBox"
                              value={this.state.client.optimalpc} 
                              checked={this.state.client.optimalpc ==='O'}  
                              onChange={this.handleCheckboxChange}  
                              color="primary"
                              size="small" 
                              label=" Informations"
                                  inputProps={{
                                      style: {fontWeight: 1000} 
                                    }}
                              name="optimalpc" />}
                            label="OptimalPC"
                          />
                          <FormControlLabel disabled={!this.state.editMode}
                            control={
                            <Checkbox 
                              value={this.state.client.optimalweb}
                              checked={this.state.client.optimalweb ==='O'}                            
                              onChange={this.handleCheckboxChange} 
                              color="primary"
                              size="small" 
                              name="optimalweb" />}
                            label="OptimalWeb"
                          />
                        </FormGroup>
                        
                      </FormControl>
                      <FormControl className = {this.props.classes.formControl}>
                        <FormLabel component="legend">Modules</FormLabel>
                        <FormGroup  >
                          <FormControlLabel disabled={!this.state.editMode}
                            control={
                            <Checkbox 
                              value={this.state.client.webinfrac} 
                              checked={this.state.client.webinfrac ==='O'} 
                              onChange={this.handleCheckboxChange} 
                              color="primary"
                              size="small" 
                              inputProps={{ 'aria-label': 'checkbox with small size' }}                                                                                                                                
                              name="webinfrac"/>}
                            label="Infractions"
                          />
                          <FormControlLabel disabled={!this.state.editMode}
                            control={
                            <Checkbox 
                             value={this.state.client.webpaie} 
                             checked={this.state.client.webpaie ==='O'} 
                             onChange={this.handleCheckboxChange} 
                             color="primary"
                             size="small" 
                             name="webpaie"/>}
                            label="Paie"
                          />
                          <FormControlLabel disabled={!this.state.editMode}
                            control={
                            <Checkbox 
                             value={this.state.client.webgeoloc} 
                             checked={this.state.client.webgeoloc ==='O'} 
                             onChange={this.handleCheckboxChange} 
                             color="primary"
                             size="small" 
                             name="webgeoloc"/>}
                            label="Géolocalisation"
                          />
                        </FormGroup>
                        
                      </FormControl>
                      <FormControl className = {this.props.classes.formControl}>
                        <FormLabel component="legend">Bornes</FormLabel>
                        <FormGroup>
                          <FormControlLabel disabled={!this.state.editMode}
                            control={
                            <Checkbox 
                              checked={this.state.client.borneoptimalweb === 'O'}
                              value={this.state.client.borneoptimalweb}
                             onChange={this.handleCheckboxChange} 
                             color="primary"
                             size="small" 
                             name="borneoptimalweb"/>}
                            label="Borne OptimalWeb"
                          />
                          <FormControlLabel disabled={!this.state.editMode}
                            control={
                            <Checkbox 
                             value={this.state.client.bornedtco} 
                             checked={this.state.client.bornedtco ==='O'} 
                             onChange={this.handleCheckboxChange} 
                             color="primary"
                             size="small" 
                             name="bornedtco"/>}
                            label="BorneDTCO"
                          />
                          <FormControlLabel disabled={!this.state.editMode}
                            control={<
                              Checkbox 
                               value={this.state.client.bornelafon} 
                               checked={this.state.client.bornelafon ==='O'} 
                               onChange={this.handleCheckboxChange} 
                               color="primary"
                               size="small" 
                               name="bornelafon"/>}
                            label="Borne Lafon"
                          />
                          <FormControlLabel disabled={!this.state.editMode}
                            control={
                            <Checkbox 
                             value={this.state.client.bornescanner} 
                             checked={this.state.client.bornescanner ==='O'} 
                             onChange={this.handleCheckboxChange} 
                             color="primary"
                             size="small" 
                             spacing="1"
                             name="bornescanner"/>}
                            label="Borne Scanner"
                          />
                          <FormControlLabel disabled={!this.state.editMode}
                            control={
                            <Checkbox 
                             value={this.state.client.autreborne} 
                             checked={this.state.client.autreborne ==='O'} 
                             onChange={this.handleCheckboxChange} 
                             color="primary"
                             size="small" 
                             name="autreborne"/>}
                            label="Autre Borne"
                          />
                        </FormGroup>
                        
                      </FormControl>
                     
                    </div>
                    <div title="Informations">
                      <TabPanel onTabChange={this._handleTabChange}>
                        
                        <div title="Situation" >    
                          <FormControl className = {this.props.classes.formControl} >                      
                            <div className="Datepick">   
                              <label className="label margin-l-5">
                                 Date installation : 
                              </label>
                              <TextField
                                  id="date_inst"       
                                  type="date"    
                                  label ={this.state.selectedDateTime?'':'date nulle'}   

                                  disabled={!this.state.editMode}                                         
                                  value={this.state.selectedDateTime}
                                  onChange={this.updateSelectedDateTime}       
                                  className={this.state.selectedDateTime?this.props.classes.textFieldDate:this.props.classes.textFieldDateNulle}                                             
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{ min: "2000-01-01", max: this.state.datedujour ,style: {fontSize: 14,height:12,fontWeight:"bold"}}} 
                                /> 
                                 <ButtonOw 
                                  title='Date nulle' 
                                  disabled= {!this.state.editMode}  
                                  onClick={this.modifdatenulle}>                          
                                  <CancelIcon style={!this.state.editMode?{ color: "#ffffff",visibility:"hidden" }:{ color: "#000000",visibility:"visible"}} />
                                </ButtonOw>
                              <label className="label margin-l-5">
                                Indice Satisfaction :
                                <select name="satisfait" value={this.state.client.satisfait}
                                     disabled={!this.state.editMode}
                                      onChange={this.handleInputChange}>
                                      {this.typesatisfait.map((option) => (
                                          <option value={option.code}>{option.label}</option>
                                          ))}
                                </select> 
                               </label>
                            </div>
                            
                           
                            <div>       
                              <FormControlLabel disabled={!this.state.editMode}
                                control={
                                <Checkbox 
                                value={this.state.client.cloture} 
                                checked={this.state.client.cloture ==='O'} 
                                onChange={this.handleCheckboxChange} 
                                color="primary"
                                size="small" 
                                name="cloture"/>}
                                label="Clôturé ?"
                              />
                               <label className="label margin-l-5">
                                  Cause :
                                  <select name="cause" value={this.state.client.cause}
                                  disabled={!this.state.editMode}
                                      onChange={this.handleInputChange}>
                                       {this.typecloture.map((option) => (
                                          <option value={option.label}>{option.label}</option>
                                          ))}
                                  </select>
                                </label>   
                                
                           </div> 
                           <div>
                              <label className="label margin-l-5">
                                  Installateur :

                                  <select name="codeinst" value={this.state.client.codeinst}
                                  disabled={!this.state.editMode}
                                      onChange={this.handleInputChange}>
                                      {this.typeinstallateur.map((codeinst) => (
                                          <option value={codeinst.code}>{codeinst.label}</option>
                                          ))}
                                  </select> 
                              </label>
                              <label className="label margin-l-5">
                                  Type installation :
                                  <select name="typeinst" value={this.state.client.typeinst}
                                  disabled={!this.state.editMode}
                                      onChange={this.handleInputChange}>
                                      {this.typeinstal.map((typeinst) => (
                                          <option value={typeinst.code}>{typeinst.label}</option>
                                          ))}
                                  </select> 
                              </label>
                           </div>  
                           <div>
                              <label className="label margin-l-5">
                                  Formateur :
                                  <select name="codeform" value={this.state.client.codeform}
                                  disabled={!this.state.editMode}
                                      onChange={this.handleInputChange}>
                                     {this.typeformateur.map((codeform) => (
                                          <option value={codeform.code}>{codeform.label}</option>
                                          ))}
                                  </select> 
                              </label>
                              <label className="label margin-l-5">
                                  Type formation :
                                  <select name="typeform" value={this.state.client.typeform}
                                  disabled={!this.state.editMode}
                                      onChange={this.handleInputChange}>
                                       {this.typeformation.map((typeform) => (
                                          <option value={typeform.code}>{typeform.label}</option>
                                          ))}
                                  </select> 
                              </label>
                           </div> 
                          </FormControl>     
                        </div>                      
                                 
                                          
                   
                        <div title="Infos Générales">
                          <SplitPane split="vertical" defaultSize="40%" >
                              <FormControl className = {this.props.classes.formControl} >
                              
                                <FormGroup>
                                <label className="label">
                                  Nb Véhicules :
                                  <input  className= "input width50"
                                      name="nb_vehic"
                                      type="number"  min={0}
                                      disabled={!this.state.editMode}                              
                                      value={this.state.client.nb_vehic}
                                      onChange={this.handleInputChange} />
                                  </label>
                  
                                  <label className="label">
                                    Nb Conducteurs :
                                    <input  className= "input width50"
                                        name="nb_chauf"
                                        type="number" min={0}  
                                        disabled={!this.state.editMode}                              
                                        value={this.state.client.nb_chauf}
                                        onChange={this.handleInputChange} />
                                    </label>
                                    <label className="label">
                                      Nb_TachyNum :
                                      <input  className= "input width50"
                                          name="nb_tachy_num"
                                          type="number" min={0}
                                          disabled={!this.state.editMode}
                                          value={this.state.client.nb_tachy_num}
                                          onChange={this.handleInputChange} />
                                      </label>
                                      <label className="label">
                                        Nb TachyCom :
                                        <input  className="input width50"
                                            name="nb_tachy_com"
                                            type="number" min={0}
                                            disabled={!this.state.editMode}
                                            value={this.state.client.nb_tachy_com}
                                            onChange={this.handleInputChange} />
                                        </label>
                                </FormGroup>
                          
                              </FormControl>      
                              <FormControl fullWidth className={classes.margin}  >                              
                                <TextField className= {this.props.classes.textField}
                                  name="infos"
                                  label=" Informations"
                                  inputProps={{
                                      style: {fontWeight: 1000} 
                                    }}
                                  disabled={!this.state.editMode}
                                    multiline
                                    rowsMax={20}
                                    value={this.state.client.infos}
                                    onChange={this.handleInputChange}
                                  />
                              </FormControl>
                          </SplitPane>

                        </div>   
                        <div title="Notes Techniques">
                           <FormControl fullWidth className = {this.props.classes.formControl} >                           
                              
                                <TextField className= {this.props.classes.textField}
                                  name="notes"
                                
                                    disabled={!this.state.editMode}
                                    inputProps={{
                                      style: {fontWeight: 1000} 
                                    }}
                                    multiline
                                    rowsMax={10}
                                    value={this.state.client.notes}
                                    onChange={this.handleInputChange}
                                  />
                            </FormControl>
                        </div>
                         
                        <div title="Informatique Embarquée">
                          <FormControl className = {this.props.classes.formControl} >                           
                            <div>       
                                  <FormControlLabel  disabled={!this.state.editMode}
                                    control={
                                    <Checkbox 
                                    value={this.state.client.equipement_geoloc} 
                                    checked={this.state.client.equipement_geoloc ==='O'} 
                                    onChange={this.handleCheckboxChange} 
                                    color="primary"
                                    size="small" 
                                    name="equipement_geoloc"/>}
                                    label="Equipement Géolocalisation ?"
                                  />
                                  <label className="label">
                                    Nb boitiers :
                                    <input  className="input width50"
                                        name="boitiers_geoloc"
                                        type="number"    min={0}
                                        disabled={!this.state.editMode}                             
                                        value={this.state.client.boitiers_geoloc}
                                        onChange={this.handleInputChange} />
                                    </label>
                                    <label className="label">
                                    Nb boitiers avec liaison tachygraphe :
                                    <input  className= "input width50"
                                        name="boitiers_tachy"
                                        type="number" min={0} 
                                        disabled={!this.state.editMode}                              
                                        value={this.state.client.boitiers_tachy}
                                        onChange={this.handleInputChange} />
                                    </label>  
                            </div>
                            <div>                                                      
                                <label className="label margin-l-5">
                                  Fournisseur :
                                  <input className="input width300"
                                      name="fournisseur_geoloc"
                                      type="text"
                                      disabled={!this.state.editMode}
                                      value={this.state.client.fournisseur_geoloc}
                                      onChange={this.handleInputChange} />
                                </label>
                              </div>
                              <div>
                              <FormControl fullWidth className={classes.margin}  >
                                
                                  <TextField
                                    name="infos_geoloc"
                                    label ="infos"
                                    inputProps={{
                                      style: {fontWeight: 1000} 
                                    }}
                                    disabled={!this.state.editMode}
                                      multiline
                                      rowsMax={20}
                                      value={this.state.client.infos_geoloc}
                                      onChange={this.handleInputChange}
                                    />
                              </FormControl>     

                              </div>
                          </FormControl>         
                        </div>   
                        <div title="Positions GPS">
                        <FormControl className = {this.props.classes.formControl} >             
                            <div>
                              <label className="label" >
                                    Latitude   :
                                    <NumberFormat  className="input width100" name ="lat"
                                          isNumericString 
                                          disabled={!this.state.editMode}                                          
                                          value={this.state.client.lat} 
                                          onChange = {this.handleInputChange }/>      
                                </label>
                            </div>
                            <div>   
                              <label className="label" >
                                    Longitude :                                   
                                    <NumberFormat  className="input width100" name ="lng"
                                          isNumericString  
                                          disabled={!this.state.editMode}                                          
                                          value={this.state.client.lng} 
                                          onChange = {this.handleInputChange }/>      
                                    </label>
                            </div>
                          </FormControl>   
                        </div>   

                      </TabPanel>
                    </div>
                  </TabPanel>
                </FormControl>
              </div>
            }
         </Container>
        </Container>
        )
     }
    }
    
}




  const mapStateToProps = state => ({
    
    isConnected : state.identity.isConnected,
    numClientEnCours : state.identity.numClientEnCours,
    clients : state.identity.clients,
    newclient :state.identity.client,
    identityLoading : state.identity.loading,
    
    //isHelpUser: state.identity.user.supportAthmo,

  })
  
  const mapDispatchToProps = (dispatch) => ({
    setTitle : bindActionCreators(setTitle, dispatch),
    getClients : bindActionCreators (getClients, dispatch),
    choisirClient : bindActionCreators (choisirClient, dispatch),
    updateClient : bindActionCreators (updateClient, dispatch),
    insertClient : bindActionCreators (insertClient, dispatch),

  })
  


    const classes = theme => ({
      bkcol: {
        /*
        backgroundColor: '#DBFCFB',// bleu pastel
        backgroundColor: '#92ECEB',// bleu ciel
        backgroundColor: '#F9D7D7',// rose pastel 
        backgroundColor: '#F49090',// rose 
        backgroundColor: '#BBD3F7',// bleu 
        backgroundColor: '#93F7E1', 
        backgroundColor: '#CFC961',// marron
        backgroundColor: '#A0F950',//vert
        backgroundColor: '#5ef2e2', 
       */
      },
      root: {
        scrollBehavior: 'smooth',
        flexGrow: 1,
        backgroundColor: theme.bkcolor.bleupastel,
        height:'55vh',
      },
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: theme.bkcolor.jaunepastel,
        height:40,
        minHeight : 20,
        
      },
        title: {
            color:'inherit',
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            
         },       
        memo: {
            textAlign: 'justify',
            marginBottom: '1.3em',
        },
        btn_class :{
            color:"primary",
        },          
        customColor: {
          // or hex code, this is normal CSS background-color
          color:theme.bkcolor.rosepastel,
          backgroundColor: theme.bkcolor.rosepastel,
          height:30,         
          maxHeight: 300,
        },   
        formControl: {         
          color: "primary",
          margin: theme.spacing(2),         
        } ,       
       
       
        textFieldDate: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(2),
          marginBottom:theme.spacing(1),
          marginTop :10,
          width:130,
          backgroundColor: "white", 
          color: "black",   
                       
        },
        textFieldDateNulle: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(2),
          marginBottom:theme.spacing(1),
          marginTop :10,
          width:120,
          backgroundColor: "#F9D7D7", 
          color: 'secondary',   
                       
        },
        

         
})

export default connect (mapStateToProps, mapDispatchToProps) (withStyles(classes) (ClientForm))
