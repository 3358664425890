 import { Container, Typography, withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { fade } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SplitPane from "react-split-pane";
import { bindActionCreators } from 'redux';
import ClientForm from '../../core/components/resources/ClientForm';
import WaitBlock from '../../core/components/window/WaitBlock';
import { choisirClient, getClients,getClientsActifs, getLastInterv, getListeParamInterv, getListeParamMotif } from '../../core/redux/actions/ClientActions';
import { login } from '../../core/redux/actions/IdentityActions';
import { setTitle } from '../../core/redux/actions/WindowAppActions';
import '../../stylesheets/ChoixClient.css';
import '../../stylesheets/splitpane.css';
import Interventions from '../resources/Interventions';
import MenuListes from '../resources/MenuListes';






  


class SplitPage extends Component { 

    state= {
        numEnCours :0,   
        reAffiche :0,     
        etat:{
          tab: '1',
          tabSelected: '1',
          changeclient:'0',
        },
        client:null,
        errorAP:false,
       } 
    
    constructor () {
      super ();  
      this.handleClientClick = this.handleClientClick.bind (this)
      this.handleListeClients =  this.handleListeClients.bind (this)
    }                
     
  

    
    handleClientClick(num) { 
      if (num != null) {                 
        this.props.setTitle ("Choix du Client")
          this.props.choisirClient (num)
          .then ( (newclient) => {        
           
            var reaffiche = this.state.reAffiche;            
            localStorage.setItem('Suiviclients_Search_reAfficheListe', reaffiche);    
            reaffiche = localStorage.getItem('Suiviclients_Search_reAfficheListe');
      
              this.setState({
              reAffiche : 0,           
              numEnCours:num,
              client : newclient,
            })
            this.props.setTitle (newclient.value[0].societe);
          })
        }
   
    }

    rechercheinfos () {
      this.handleListeTypeInterv ();

    }
    handleListeTypeInterv () {
     
      this.props.getListeParamInterv ('N')
      .then ( (response) => {
     
        this.handleListeTypeMotif () ;
        

      
      }, (error) => {
        console.warn('Erreur splitPage', error)
        this.setState ({
          errorAPI: true,
        })
    } )

    }

    handleListeTypeMotif () {
      this.props.getListeParamMotif ('N')
      .then ( (response) => {
       
        this.handleListeClients(0);
      }, (error) => {
        console.warn('Erreur splitPage', error)
        this.setState ({
          errorAPI: true,
        })
    } )

    }

    handleListeClients (newnum) {
    
      var lastnum = 0;
      var req = null;
      var tousclients = localStorage.getItem('Suiviclients_Search_tousClients');
      // eslint-disable-next-line
      if (tousclients == 1) {       
        req =this.props.getClients ();
      }
      else {      
        req = this.props.getClientsActifs ();
      }

      req.then ( (response) => {
     
         if (newnum ===0)   { 
          
            this.props.getLastInterv (1)
            .then ( (response) => {
     
              lastnum = response.value[0].numero;   
             
              this.setState({
                reAffiche : 0,
                errorAPI: false,
               },()=>{
                this.handleClientClick(lastnum)   ;   
              })
            }, (error) => {
     
                
            } )        
          }
          else {
       
            this.setState({
              reAffiche : 1,
              errorAPI: false,
             },()=>{
              this.handleClientClick(newnum)   ;   
            })
          }

      }, (error) => {
          console.warn('Erreur splitPage', error)
          this.setState ({
            errorAPI: true,
          })
      } )



    }

   

    componentDidMount() {
   
        this.props.setTitle ("Suivi des Clients ATHMO")
        this.rechercheinfos();       
        
    }
    
    componentDidUpdate(prevProps, prevState) {
    
    
      
    }
    
    render() {
         
      if (  (!this.state.numEnCours>0)     
      ) 
            {
               if (( this.state.errorAPI) )
              {
                return (
                  <Container >
                      <Typography align="center" variant="h2">
                          Accès Impossible !
                        </Typography>
                  </Container>
                  )
              }
              else
              return (
              <Container >
                 
                  <WaitBlock text="Chargement en cours..."/> 
              </Container>
              )
         
          } else {    
      
       
        return (
          <Box className="mainbox"> 
          <SplitPane split="vertical" minSize={50} maxSize={1500} defaultSize="55%">
          
              <SplitPane split="horizontal" primary="first"  defaultSize="50%">
                <SplitPane split="vertical" defaultSize="99%" >
                  <div className = "boxLeft" >  
                    <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>
                     {this.state.numEnCours>0 && 
                      <ClientForm num = {this.state.numEnCours} client = {this.state.client}                        
                       afficheListeClients= {(id) =>this.handleListeClients(id)}>
                      </ClientForm>
                   
                     }
                    </CustomScrollbars>                   
                  </div>            
                  <div className = "boxCenter" >
                   
                  </div>            
                  
                </SplitPane>     
                <div className = "boxBottom" >                   
                    <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>
                    {this.state.numEnCours>0 && 
                      <Interventions numclient = {this.state.numEnCours} >  </Interventions>
                    }       
                    </CustomScrollbars>
                </div>  
              </SplitPane>
              <div className = "boxRight" >
                <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>
                {this.state.numEnCours ===0 &&   
                <Container >                    
                    <WaitBlock text="Chargement en cours..."/> 
                </Container>    
                }
                {this.state.numEnCours>0 && 
                  <MenuListes reAfficheListe = {this.state.reAffiche}
                    onClick= {this.handleClientClick}>                    
                  </MenuListes>
                }
                </CustomScrollbars>                 
              </div>
          
         </SplitPane>
         </Box>
        )}
      }

}


const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 10,
      backgroundColor: 'rgba(252, 36, 89, 0.8)',
      width:10,
      height : 10,
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  
 
  const CustomScrollbars = props => (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...props}
    />
  );


const mapStateToProps = state => ({
    isConnected : state.identity.isConnected,
    numClientEnCours : state.identity.numClientEnCours,
    clients : state.identity.clients,
    typeintervs : state.identity.typeintervs,
    typemotifs : state.identity.typemotifs,
    identityLoading : state.identity.loading,
    loading : false,
   
})
      



const mapDispatchToProps = (dispatch) => {
    return {
        login : bindActionCreators (login, dispatch),
        getClients : bindActionCreators (getClients, dispatch),
        getClientsActifs : bindActionCreators (getClientsActifs, dispatch),
        getLastInterv : bindActionCreators (getLastInterv, dispatch),
        getListeParamInterv : bindActionCreators (getListeParamInterv, dispatch),
        getListeParamMotif : bindActionCreators (getListeParamMotif, dispatch),
        choisirClient : bindActionCreators (choisirClient, dispatch),
        setTitle : bindActionCreators (setTitle, dispatch),
    }  
    
  }

const classes = theme => ({
    root: {
        scrollBehavior: 'smooth',
        flexGrow: 1,
    },
    toolbar1: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyItems: 'space-between',
        
        
    },
    toolbar2: {
        display: 'flex',
        flexDirection: 'row',
        justifyItems: 'space-between',
        
        
    },
    grid: {
        '&& .MuiTableRow-hover': {
            cursor: 'pointer',
        }
    },
    memo: {
        textAlign: 'justify',
        marginBottom: '1.3em',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
      },
      title: {
        flexGrow: 1,
      },
})

export default connect (mapStateToProps, mapDispatchToProps) (withStyles(classes) (withRouter (SplitPage)))

