import axios from 'axios';
import { URL_API_AUTH } from './../core/components/Init';
import BaseService from './BaseService';

/**
 * Service d'accès à l'API d'authentification
 */
class AuthenticationService extends BaseService {

    /**
     * Identification auprès du serveur via API Auth
     * @param {object} credential {login : {string}, password: {string}}
     */
    login (credential) {
        
        let texteErreur = 'Erreur générique API';
        //return axios.post(`${URL_API_AUTH}/identite/identifier`, credential)
        return axios.post(`${URL_API_AUTH}/identity/auth`, credential)
        .then ( (resp) => {   
        
            return Promise.resolve(resp.data);

        }).catch( (err) => {
           
            if (err.response && err.response.status === 401) {
                return Promise.reject(401);

            } else if (err.response && err.response.status === 500) {
                console.warn ('Erreur serveur', err);
                return Promise.reject("Accès application impossible (500). Contacter l'assistance sans tarder.");

            } else {
                console.warn ('Erreur réseau', err);
                texteErreur = (err.response && err.response.message) ? err.response.message : err;
                return Promise.reject(`Accès API impossible : ${texteErreur}`);
            }
        })

    }

  
    /**
     * Déconnexion 
    */
    logout () {
        //return this.put (`${URL_API_AUTH}/identity/disconnect`);
        let texteErreur = 'Erreur générique API';
        //return axios.post(`${URL_API_AUTH}/identite/identifier`, credential)
        return axios.put(`${URL_API_AUTH}/identity/disconnect`)
        .then ( (resp) => {   

            localStorage.setItem('Suiviclients_Search_modifEncours', 0);
            
            return Promise.resolve(resp.data);

        }).catch( (err) => {
           
            if (err.response && err.response.status === 401) {
                return Promise.reject(401);

            } else if (err.response && err.response.status === 500) {
                console.warn ('Erreur serveur', err);
                return Promise.reject("Accès application impossible (500). Contacter l'assistance sans tarder.");

            } else {
                console.warn ('Erreur réseau', err);
                texteErreur = (err.response && err.response.message) ? err.response.message : err;
                return Promise.reject(`Accès API impossible : ${texteErreur}`);
            }
        })


    }
}



export default AuthenticationService;