export const W_APP_SAVE_TITLE  = 'WINDOW_APP_SAVE_TITLE'
export const W_APP_SET_ERROR = 'WINDOW_APP_SET_ERROR'

export const setError = payload => ({
    payload : payload,
    type : W_APP_SET_ERROR
})
export const setTitle = (payload) => ({
    payload : payload,
    type : W_APP_SAVE_TITLE
})