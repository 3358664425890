import {
    CircularProgress, Container,
    InputBase,
    Paper, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow,
    TableSortLabel, withStyles
} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import CancelIcon from '@material-ui/icons/Cancel';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ButtonOw from '../../core/components/window/ButtonOw';
import ResizableIconButton from '../../core/components/window/ResizableIconButton';
import WaitBlock from '../../core/components/window/WaitBlock';
import { choisirClient, getClients, getClientsActifs } from '../../core/redux/actions/ClientActions';
import { setTitle } from '../../core/redux/actions/WindowAppActions';
import '../../stylesheets/ChoixClient.css';






class ChoixClient extends Component {

    /**
     * Champs avec un filtre
     */
    headerCells = [
        {
            field : 'societe',
            label : 'Société',
        },
        {
            field : 'numero',
            label : 'Code',
        },
        {
            field : 'numcompta',
            label : 'N°compta', 
        },
        {
            field : 'cp',
            label : 'C.Postal',
        },
        {
            field : 'ville',
            label : 'Ville'
        }
    ]

    /**
     * Liste des entreprises (stockage temporaire des entreprises)
     */
    valuesRef = []
    

   
    constructor(props) {
        super(props);
        this.state = {
            choice: false,
            modifEncours : false,
            displayedHelp : false,
            initialsValues: [],
            values : [],
            order : 'desc',
            columnSort : 'societe',
            filtersEnable : true,
            titre:'Liste des Clients',
            radio :1,
            filters : {
                numero: '',
                societe: '',
                numcompta:'',
                cp: '',
                ville:'',
                cloture: false,
            },
            filtre :'',        
            columnContract: true,
            begin : false,
            numclientselect:0,           
            evt:null,
            evtclick:null,
        }
        this.onClickRow = this.onClickRow.bind (this)
        this.handlerSort = this.handlerSort.bind (this)
        this.handleRadioChange  = this.handleRadioChange.bind(this)
     
      
        this.clickSearch = this.clickSearch.bind(this);
                
    }

    componentDidMount() {
      
        /*
         * Contrôle accès
         */
           
        
        // eslint-disable-next-line
     

            if (!this.props.isConnected) {
                console.warn('Not connected')
               // this.props.history.push ('/')
            }
            var tousclients = localStorage.getItem('Suiviclients_Search_tousClients');
            this.setState ({
               radio :  tousclients,
            })
            this.props.setTitle ("Choix du Client")
       
            if (!this.props.clients) {

                var req = null;
           
                // eslint-disable-next-line
                if (tousclients == 1) {       
                    req =this.props.getClients ();
                }
                else {      
                    req = this.props.getClientsActifs ();
                }

                
                req.then ( (response) => {
                        
            
                }, (error) => {
                    console.warn('Erreur choixclient', error)
                    this.setState ({
                    errorAPI: true,
                    })
                } )
                        
            } else {
               

                this.hydrate ();
                if ((this.state.numclientselect ===0) && (this.props.numClientEnCours!==0)) {
                    

                    this.setState({
                        choice: true,
                        numclientselect:this.props.numClientEnCours,
                        },()=>{
                        
                  
                            var ev = new Event('input', { bubbles: true });
                            this.onClickRow(this.props.numClientEnCours,ev);

                        
                    })

                   
                }
            }
      
    }
 
    /**
     * Travail sur une copie des données
     */
    hydrate = () => {
        
        let clients = JSON.parse(JSON.stringify(this.props.clients))
    
        
        let values =  Object.values (clients)
        let nbrows =0;
        let titz = '';
    
        /*
         * uniquement les clients avec un contrat Optimal Web en cours
         */
       // values = values.filter(elt => elt.sous_contrat)

        /*
         * si aucun client, affichage de tous les clients
         */
        if (values.length === 0) {
            values = Object.values(clients)
            this.setState({
                columnContract: false,
                filtersEnable: false,
            })
        }

        /*
         * uniquement les clients avec une carte entreprise
         */
        //values = values.filter(elt => elt.code_societe)

        /*
         * tri par défaut
         */
        this.triAscendant ('societe', values)
        nbrows = values.length;
        titz = 'Liste des Clients '; 
        titz += '  ........  ( ';
        titz +=nbrows.toString();
        titz +=  '  lignes ) ';
        this.setState ({
            order : 'asc',
            columnSort : 'societe',
            values : values,
            initialsValues : values.concat([]),
            titre: titz,
        },()=>{
         
        this.props.afficheTitre (titz);
        })
      

        /*
         * si une seule société disponible, 
         * redirection
         */
        if (values.length === 1) {
            this.setState({
                choice: true,
            })
            this.props.choisirClient (values.pop().numero)
        }
       
    }
    
    handleRadioChange = (event) => {
       var val = this.state.radio;
        // eslint-disable-next-line    
       if (val == 0) {val =1;} else val = 0;
       this.setState ({
           radio :val,
       })
      
       
    }

    handlerSort = (property) => {

        
        /*
         * inversion de l'ordre de tri de la propriété précédemment sélectionnée
         */
       
        if (this.state.columnSort === property) {            
            
            this.setState ({
                order : (this.state.order === 'asc') ? 'desc' : 'asc',
                values : this.state.values.reverse ()
            })

        /*
         * Changement de propriété de tri
         */
        } else {
            this.triAscendant (property, this.state.values)
            this.setState ({            
                order : 'asc',
                columnSort : property,
            })
        }
        
    }

    triAscendant = (property, values) => {
      
        values.sort ((a, b) => {
            
            if (a[property] < b[property]) {
                return -1
            } else if (a[property] > b[property]) {
                return 1
            } else {
                return 0
            }
        })
    }

    handlerFilters = () => {       

   
        this.setState({
          
            filtersEnable : !this.state.filtersEnable,                
        })
        
    }



    resetClick = () => {

    
        this.setState({
            modifEncours: false
        })  
    }

     

    componentDidUpdate(prevProps, prevState) {
       
          var tousclients = localStorage.getItem('Suiviclients_Search_tousClients');
          // eslint-disable-next-line     
          if (this.state.radio != this.props.radiostate) {
         
             
            localStorage.setItem('Suiviclients_Search_tousClients',this.props.radiostate);
            tousclients = localStorage.getItem('Suiviclients_Search_tousClients');
            this.setState({
                radio : this.props.radiostate,
                },()=>{
                    var req = null;
                
                    // eslint-disable-next-line
                    if ((tousclients === 1) || (tousclients == 1)) {      
                    
                        req =this.props.getClients ();
                    }
                    else {     
                  
                        req = this.props.getClientsActifs ();
                    }

                    
                    req.then ( (response) => {
                        this.setState({          
                            filtersEnable : !this.state.filtersEnable,                
                        })
                    }, (error) => {
                        console.warn('Erreur choixclient', error)
                        this.setState ({
                        errorAPI: true,
                        })
                    } )
                })     
            }
            var reaffiche = 0;
            reaffiche =  localStorage.getItem('Suiviclients_Search_reAfficheListe');
       
        
            /*
            * désactivation des filtres
            */
            // eslint-disable-next-line
            if ((reaffiche == 1) || (prevState.filtersEnable && !this.state.filtersEnable)) {
           
                /*
                * récupération du jeu de données initial
                */

                    this.hydrate ()

                /*
                * Réinitialisation des filtres
                */
                let filters = {};
                for (let field in this.state.filters) {            
                    if (typeof(this.state.filters[field]) === 'string') {
                        filters[field] = ''
                    } else if (typeof(this.state.filters[field]) === 'number') {
                        filters[field] =  0    
                    } else if (typeof(this.state.filters[field]) === 'boolean') {
                        filters[field] = false
                    }
                }
                this.setState({
                    filters: filters,
                     filtersEnable :true,
                })
            }
            // eslint-disable-next-line
            if  ((reaffiche == 1) || 
                ((this.state.numclientselect ===0) && (this.props.numClientEnCours!==0))) {
               
                
                this.setState({
                    choice: true,
                    numclientselect:this.props.numClientEnCours,
                    },()=>{
                       
                   
            
                var ev = new Event('input', { bubbles: true });
                ev = this.state.evtclick;
                this.onClickRow(this.props.numClientEnCours,ev);

            
                localStorage.setItem('Suiviclients_Search_reAfficheListe', 0);
                /*
                var numsearch = parseInt(this.state.numclientselect);
                let infosClient =  this.state.values.find(elt => elt.numero === numsearch);  
                let id = this.state.values.findIndex(elt => elt.numero === numsearch);
           
                */
                })     



                
            }
  
        
    }

    onClickRow (num, event) {
     
       this.setState({
        evtclick :event,
       })
        event.preventDefault ();
        const displayCode = (localStorage.getItem('Suiviclients_Search_modifEncours') === '0')
           
        if (displayCode) {
            this.setState({
                choice: true,
                numclientselect:num,
            })
            /*
            if (this.props.clients) {
                let infosClient =  this.props.clients.find(element => element.numero === num);
         
                this.props.setTitle(infosClient.societe)
            }
            */
            this.props.afficheClientClick(num);

        }
        else { 
            this.setState({
                modifEncours: true
            })
        }
    }

    clickSearch ()
    {
  
       var val = this.state.filters[this.state.filtre];
  
       
        if (val !== undefined && val !== null) {
             // eslint-disable-next-line
            if (val.length !=0) {
                this.handlerSearch (this.state.filtre,this.state.evt);
            }
        }
       
       
          
       
    }

    handlerSearch = (field, evt) => {
        
        evt.preventDefault ()
        let filters = this.state.filters
        filters[field] = (evt.target.value).toUpperCase();     
        
        let values = JSON.parse(JSON.stringify(this.state.initialsValues))
        let nbrows =0;
        let titz = '';
        for (let fieldName in filters) {
            let search = filters[fieldName];

            if (search && typeof(search) === 'string' && search !== '') {
               
                values = values.filter (elt => { 
                   
                   if ((fieldName ==='numero') || (fieldName ==='numcompta')) {
                       let numsearch = search.toString();
                       numsearch = numsearch.trim();   
                   
                       let resultat = ((elt[fieldName]).toString()).includes (numsearch);
                    
                   
                    return resultat;
                   
                   }
                   else {
                     let resultat2 = elt[fieldName] !== null && elt[fieldName].includes(search);
                   
                   return resultat2;
                   }
                })
                
            }
        }
       
        nbrows =  values.length;
        titz = 'Liste des Clients '; 
        titz += '  ........  ( ';
        titz +=nbrows.toString();
        titz +=  '  lignes ) ';
        this.setState ({
            filters : filters,
            values : values,
            titre : titz,
        },()=>{
        
        this.props.afficheTitre (titz);
        })


        if (values.length === 1) {
            var num = values[0].numero;
            var ev = new Event('input', { bubbles: true });
            ev = this.state.evtclick;
            this.onClickRow(num,ev);

        }
    }


   

    displayHelp = () => {
        this.setState ({
            displayedHelp : !this.state.displayedHelp
        })
    }

 
  
      
    render() {
        /*
     
        */
        if (  (!this.props.clients)      
        || this.props.clientLoading
       
        ) 
        {
            return (
            <Container >
              
                <WaitBlock text="Chargement en cours..."/> 
            </Container>)
       
        } else {      
               
            return (
                <Container className={this.props.classes.root}>
                    <Paper >
                    {this.state.modifEncours ? 
                    <div className="outlined_encours">  
                        <CircularProgress className="margin-t-15 margin-l-5" />
                        <label className="label margin-t-15">  
                            Modification en cours - Action Non Autorisée                    
                        </label>                        
                        <ResizableIconButton   title="Cliquer pour annuler"
                            onClick={this.resetClick}>
                            <CancelIcon   style= {{ color: "#ff0000",visibility:"visible", fontSize: 48}} />                                                                 
                        </ResizableIconButton>
                    </div>:
                        <TableContainer component={Paper} className="containerChoixclient">    
                            
                        
                            <Table  stickyHeader size ="small" aria-label="choix client" >                            
                                <TableHead >                            
                                    <TableRow >
                                        {                                                                   
                                            this.headerCells.map ( (cell) => {
                                                return (
                                                    <TableCell  key={cell.field}
                                                        sortDirection={this.state.order}
                                                        className={this.props.classes.head}
                                                        align='left'>
                                                        <div>
                                                            <TableSortLabel active={this.state.columnSort === cell.field} 
                                                                direction={this.state.order}
                                                                hideSortIcon
                                                                onClick={() => {this.handlerSort (cell.field)}} >
                                                                {cell.label}       
                                                            </TableSortLabel>
                                                        </div>
                                                        {this.state.filtersEnable && (
                                                        <div className="outlined">                                                            
                                                                
                                                                {cell.field==="societe" &&
                                                                <ButtonOw 
                                                                size ="small"
                                                                title='Liste complète' 
                                                                onClick={this.handlerFilters}>
                                                                 <CachedIcon color="primary"/>
                                                                </ButtonOw>
                                                                 }
                                                               
                                                                {cell.field !== "numero" && cell.field !== "numcompta" && (                
                                                                <InputBase 
                                                                    value={this.state.filters[cell.field]}
                                                                    placeholder="Filtrer..."
                                                                    onChange={(event) => {this.handlerSearch (cell.field, event)}}    
                                                                />
                                                              )}
                                                              {(cell.field ==="numero" || cell.field === "numcompta") &&  (  
                                                                <NumberFormat  className="inputchoix width60"
                                                                    isNumericString format="######"                       
                                                                   
                                                                    value={this.state.filters[cell.field]}
                                                                    onChange={(event) => {this.handlerSearch (cell.field, event)}} />
                                                              )}
                                                            <ButtonOw 
                                                                size ="small"
                                                                title='Recherche' 
                                                                onClick={this.clickSearch}>
                                                                <SearchIcon color="primary"/> 
                                                              </ButtonOw>
                                                             
                                                             
                                                        </div>
                                                            
                                                        )}  
                                                        {!this.state.filtersEnable && cell.field==="zsociete" && (
                                                            <div >
                                                                <ResizableIconButton size='small' title="Filtres"
                                                                    onClick={this.handlerFilters}>
                                                                    <FilterListIcon color="primary"/>                                                                 
                                                                </ResizableIconButton>

                                                            </div>
                                                        )}      
                                                    </TableCell>
                                                )
                                            })
                                        }
                                        
                                    </TableRow>
                                    
                                </TableHead>
                                <TableBody  >
                                {this.state.values.length === 0 ?
                                    <TableRow key='vide'>
                                        <TableCell>Aucun choix possible.</TableCell>
                                    </TableRow> :
                                    this.state.values.map( (client) => (
                                      
                                        <TableRow  className = {this.props.classes.root}
                                         key={client.numero} 
                                                                                      
                                                selected={client.numero === this.state.numclientselect}
                                                onClick={(evt) => {this.onClickRow (client.numero, evt)}}>
                                            <TableCell component="th" scope="row">
                                                {client.societe}
                                            </TableCell>
                                            <TableCell align="left">{client.numero}</TableCell>
                                            <TableCell align="left">{client.numcompta}</TableCell>
                                            <TableCell align="left">{client.cp}</TableCell>
                                            <TableCell align="left">{client.ville}</TableCell>
                                        
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        }
                    </Paper>    
                </Container>
            
            )
        }

    }

}

const mapStateToProps = state => ({
    isConnected : state.identity.isConnected,
    numClientEnCours : state.identity.numClientEnCours,
    clients : state.identity.clients,
    clientsactifs : state.identity.clientsactifs,
    identityLoading : state.identity.loading,
    
})

const mapDispatchToProps = dispatch => {
    return {
        getClients : bindActionCreators (getClients, dispatch),
        getClientsActifs : bindActionCreators (getClientsActifs, dispatch),
        choisirClient : bindActionCreators (choisirClient, dispatch),
        setTitle : bindActionCreators (setTitle, dispatch),       
    }
}

const classes = theme => ({
    bkcol: {
        /*
        backgroundColor: '#DBFCFB',// bleu pastel
        backgroundColor: '#92ECEB',// bleu ciel
        backgroundColor: '#F9D7D7',// rose pastel 
        backgroundColor: '#F49090',// rose 
        backgroundColor: '#BBD3F7',// bleu 
        backgroundColor: '#93F7E1', 
        backgroundColor: '#CFC961',// marron
        backgroundColor: '#A0F950',//vert
        backgroundColor: '#5ef2e2', 
       */
      },    
        root: {
            '&:nth-of-type(odd)': {
                           
                backgroundColor: theme.bkcolor.bleuciel, 
            },
            "&.Mui-selected, &.Mui-selected:hover": {
                color: theme.bkcolor.noir,
                backgroundColor: theme.bkcolor.rose,
                cursor: 'none', 
            },
            '&.MuiTableRow-hover': {
                cursor: 'pointer',  
                backgroundColor: theme.bkcolor.rose, 
             
            } , 
               scrollBehavior: 'smooth',
               flexGrow: 1,
               backgroundColor: theme.bkcolor.bleupastel,
               display :'table-row',
               width:'100vh',
            } , 
        head: {
         
            backgroundColor: theme.bkcolor.vertpastel,
            color: theme.bkcolor.noir,
        
        },
        headcontrol: {
            height:'30px',
            backgroundColor: theme.bkcolor.vertpastel,
            color: theme.bkcolor.noir,
            width :'100vh',
        },
   
        body: {
            fontSize: 14,
        },
       

        
    })

 

export default connect (mapStateToProps, mapDispatchToProps) (withStyles(classes)  (ChoixClient))