
import {
  Container,
  Grid, Hidden, Toolbar,
  Typography, withStyles
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { GridOverlay,DataGrid } from '@material-ui/data-grid';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/AddCircleOutlined';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import React from 'react';
import { bindActionCreators } from 'redux';
import { Scrollbars } from 'react-custom-scrollbars';
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import SplitPane from "react-split-pane";
import '../../../stylesheets/splitpane.css';
import '../../../stylesheets/tabpanel.css';
import ButtonAdd from '../window/ButtonAdd';
import ButtonOw from '../window/ButtonOw';
import CloseButton from '../window/CloseButton';

/*
      axios.get('https://api.npms.io/v2/invalid-url')
      .then(response => this.setState({ totalReactPackages: response.data.total }))
      .catch(error => {
          this.setState({ errorMessage: error.message });
          console.error('There was an error!', error);
      });      
*/




  
  function CustomNoRowsOverlay() {
    return (
      <GridOverlay className={classes.root}>
         <div className={classes.label}>Aucun Matériel</div>
     </GridOverlay>
  );
}

class MaterielForm extends React.Component {
  constructor(props) {
    super(props);


  this.state = {  
    etat :0,
    editMode:false,
    addMode:false,
    begin : false,
    end : false,
    numclient:0,
    newid:1,
    idencours:50,
    nbrows :0,
    selectedDateTime: "2017-05-24T10:30:00",
    zmateriel:{
      id:1,
      numero :0,
      date_iso : new Date(),
      date_interv : new Date(),
      code_interv:'KFIC',
      libel_interv:"Pb en Cours",
      dur_interv:1,
      comment_interv:'?',
      login_interv:'NME',
  },
    materiel:{
        id:0,
        numero :0,
        date_iso : new Date(),
        date_interv : new Date(),
        code_interv:'KFIC',
        libel_interv:this.recupLibel("KFIC"),
        dur_interv:12,
        comment_interv:'',
        login_interv:'NME',
    },
    Rows:[],
    
    newRows: [
      {
        id:1,
        numero: 5049,
        date_iso:new Date("2000-01-01T00:00"),
        date_interv:new Date("2000-01-01T00:00"),
        code_interv: "V",
        libel_interv: "",
        dur_interv: "0",
        login_interv:"",
        comment_interv:"i",
        
      },
    ]
   
   }
   this.handleInputChange = this.handleInputChange.bind(this);
  
   
    
  }
 
    typeinterv = [
      {label:"", code:"0"},
      {label:"Dernier MAJ reçue", code:"DENV"},
      {label:"Inter clôturée", code:"OFIC"},            
      {label:"Pb en Cours", code:"KFIC"},
      {label:"Installation", code:"INST"},
      {label:"PB logiciel Résolu", code:"OLOG"},
      {label:"Pb logiciel", code:"KLOG"},
     ];

     typeutilisateur = [
      {label:"", code:"0"},
      {label:"Nicolas MEDINA", code:"NME"},
      {label:"Jean Jacques BARET", code:"JJB"},
      {label:"Jean Philippe", code:"JPB"},
      {label:"Claude CASTELLO", code:"CLC"},
    ];
    
    listmodif = [];
    componentDidMount() {
      const newid = 1;
      
     
       console.log('code_mat ',this.state.materiel.code_mat);
       console.log('numclient' ,this.props.numclient);
       console.log('this.state.numclient',this.state.numclient);
      
       this.setState({
        begin:true,
        })

      try {
        this.state.selectedDateTime = (this.state.materiel.date_mat).toISOString().slice(0, 16)
   
      } catch(e) {
        this.state.selectedDateTime ="";
      }
     
    }   
    
    recupLibel (code) {
     const resu = this.typeinterv.find(item =>item.code === code) ;   
     return resu.label;
    }
    componentDidUpdate(prevProps, prevState) {  
      console.log('state ',this.state.numclient);
      console.log('props ',this.props.numclient);

     
      if (this.state.numclient != this.props.numclient) {
        this.setState({
          numclient: this.props.numclient,
        })
        console.log('this.props.numclient ',this.props.numclient);

          let materiels = [
            {
                "numero": 818,
                "date_interv": "04/11/2020 14:31:12",
                "code_interv": "DENV",
                "comment_interv": "> Je mets le dernier \"Tachynumerique.exe\".\nPEM AD. OK. NME01.",
                "dur_interv": 5,
                "login_interv": "JPB"
            },
            {
                "numero": 818,
                "date_interv": "27/10/2020 16:52:46",
                "code_interv": "OFIC",
                "comment_interv": "> PARAM ET TRAITEMENT RGPD.\nPEM AD. OK. NME01.",
                "dur_interv": 1,
                "login_interv": "NME"
            },
            {
                "numero": 818,
                "date_interv": "27/10/2020 16:06:23",
                "code_interv": "DENV",
                "comment_interv": "> MAJ 3.04 + 2.03 + 1.3 + SQL2012.\n> C:\\Athmo.   Win 10 Pro.   32 bits.\n> Epson Perfection V33.\n> Save.bat + Tache Plan. et copie auto sur serveur :\n\\\\SRVPAIE\\commun\\SAUVEGARDE_ATHMO\nPEM AD. OK. NME01.",
                "dur_interv": 1,
                "login_interv": "NME"
            },
            {
                "numero": 818,
                "date_interv": "30/03/2020 10:25:32",
                "code_interv": "OFIC",
                "comment_interv": "- Concernant le msg \"RGPD\".\n> Je les ai eu et je leur ai expliqué que ce n'est rien.\n> Je les rappellerai plus tard pour le paramétrage.\nOK. NME01.",
                "dur_interv": 1,
                "login_interv": "NME"
            },
            {
                "numero": 818,
                "date_interv": "30/03/2020 10:18:20",
                "code_interv": "KFIC",
                "comment_interv": "> Message RGPD\n\n> Rappeler Juliette, le matin.\n",
                "dur_interv": 1,
                "login_interv": "JPB"
            },
            {
                "numero": 818,
                "date_interv": "16/01/2020 10:50:50",
                "code_interv": "DENV",
                "comment_interv": "> MAJ 3.03 + 2.02 + 1.3 + SQL2012.\n> C:\\Athmo.   Win 10 Pro.   32 bits.\n> Epson Perfection V33.\n> Save.bat + Tache Plan. et copie auto sur serveur :\n\\\\SRVPAIE\\commun\\SAUVEGARDE_ATHMO\nPEM AD. OK. NME01.",
                "dur_interv": 1,
                "login_interv": "NME"
            },
            {
                "numero": 818,
                "date_interv": "15/07/2019 09:43:57",
                "code_interv": "DENV",
                "comment_interv": "> MAJ 2.46 + 1.36 + 1.3 + SQL2012.\n> C:\\Athmo.   Win 10 Pro.   32 bits.\n> Epson Perfection V33.\n> Save.bat + Tache Plan. et copie auto sur serveur :\n\\\\SRVPAIE\\commun\\SAUVEGARDE_ATHMO\nPEM AMMYY3. OK. NME01.",
                "dur_interv": 1,
                "login_interv": "JPB"
            },
            {
                "numero": 818,
                "date_interv": "25/05/2018 15:38:53",
                "code_interv": "OFIC",
                "comment_interv": "- Pblm pour ouvrir le logiciel.\n> Il faut lancer \"START SERVICES.BAT\" (qui est sur le bureau) EN TANT QU'ADMIN !!!\nPEM AMMYY3. OK. NME01.",
                "dur_interv": 1,
                "login_interv": "NME"
            },
            {
                "numero": 818,
                "date_interv": "01/02/2018 09:52:38",
                "code_interv": "DENV",
                "comment_interv": "> MAJ 2.45 + 1.34 + 1.3 + SQL2012.\n> C:\\Athmo.   Win 10 Pro.   32 bits.\n> Epson Perfection V33.\n> Save.bat + Tache Plan. et copie auto sur serveur :\n\\\\SRVPAIE\\commun\\SAUVEGARDE_ATHMO\nPEM AMMYY3. OK. NME01.",
                "dur_interv": 1,
                "login_interv": "NME"
            },
            {
                "numero": 818,
                "date_interv": "17/05/2017 10:20:30",
                "code_interv": "DENV",
                "comment_interv": "> MAJ 2.42 + 1.33 + 1.3 + SQL12.\n> C:\\Athmo.   Win 10 Pro.   32 bits.\n> Epson Perfection V33.\nPEM AMMYY3. OK. NME01.",
                "dur_interv": 1,
                "login_interv": "NME"
            }
          ];
          let values =  Object.values (materiels)
          this.state.numclient = values[0].numero;
          
          console.log('materiels ',materiels);
          console.log('values ', values);
          const nbrows = values.length;
          let items = [];
      
          for (var i=0;i<nbrows;i++) {
            let tmpdat = values[i].date_mat;          
            let datz = tmpdat.substr(3,2)+'/'+ tmpdat.substr(0,2)+'/'+tmpdat.substr(6,13);
          
            let item ={
              id:i+1,
              numero :values[i].numero,      
              date_iso : new Date(datz).toISOString(),        
              date_mat :new Date(datz).toLocaleString().slice(0,17),
              code_mat:values[i].code_mat,
              libel_interv:this.recupLibel(values[i].code_interv),
              dur_interv:values[i].dur_interv,
              comment_interv:values[i].comment_interv,
              login_interv:values[i].login_interv  
            };       
          
            items.push(item);
          }
        
      
          this.setState({
            Rows:items,
            materiel:items[this.state.newid -1],
          })
      

      }
    }



   

    clickEdit= () => {   
      
      let values = this.state.newRows;
      this.setState({
        editMode: true,  
        addMode: false,       
        idencours:this.state.newid,            
      })

      localStorage.setItem('Suiviclients_Search_modifEncours', 1);
      const displayCode = (localStorage.getItem('Suiviclients_Search_modifEncours') != 0)
      console.log("displayCode",displayCode);
      const mdf1 = localStorage.getItem('Suiviclients_Search_modifEncours') ;
      console.log("mdf1",mdf1);
      const mdf = localStorage.getItem('Suiviclients_Search_modifEncours') ;
      console.log("mdf",mdf);

     var datx = this.state.materiel.date_iso;
     

      var tzoffset = (new Date(datx)).getTimezoneOffset(); //offset in minues
      var datz = new Date(datx);
       var mn = new Date(datz).getMinutes();
  
       datz.setMinutes(mn -tzoffset);
   
       var localISOTime = new Date(datz).toISOString().slice(0, 16);
    

      try {
      // this.state.selectedDateTime = new Date((this.state.interv.date_iso)).toISOString().slice(0, 16)
       this.state.selectedDateTime = localISOTime;
       } catch(e) {
        
         this.state.selectedDateTime ="";
       }
      
     }

     clickAjout= () => {   
      this.state.zmateriel.date_iso = new Date().toISOString();        
      this.state.zmateriel.date_mat =new Date().toLocaleString();
      localStorage.setItem('Suiviclients_Search_modifEncours', 1);;
      const materiel = this.state.zmateriel;
    

      var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, 16);
   ;
      this.setState({
        addMode: true,   
        editMode:true,      
        idencours:this.state.newid,  
        
      })

      this.setState({
        materiel: materiel,    
      })
   
      
      this.state.selectedDateTime = localISOTime;
    ;
     }

    clickValid= () => {    

      console.log('listmodif ',this.listmodif);
      var changes = new Object();
      for (var key in this.listmodif) {
        var obj = this.listmodif[key];
        for (var prop in obj) {
          console.log(prop + " = " + obj[prop]);
          changes[prop] = obj[prop];
          }               
                       
        };  

        console.log('changes ',changes);
      
/*
myCar.make = 'Ford';
myCar.model = 'Mustang';
myCar.year = 1969;

console.log('my car ', myCar);
 */   
      let materiel = this.state.materiel;
     // interv.libel_interv = this.recupLibel(interv.code_interv);
      console.log('materiel ',materiel);
      let values =  this.state.Rows;    
    
     if (this.state.addMode) {
       materiel.numero = 818;
     
     }
      values[this.state.newid-1] = materiel;
   
    
      this.setState({
        editMode: false, 
        addMode :false,
        idencours :50,       
        Rows : values,
      })
      localStorage.setItem('Suiviclients_Search_modifEncours', 0);
    }

    clickSearch= () => {

    }

    clickAnnul= () => {    
      let values =  this.state.Rows;         
      this.state.materiel = values[this.state.newid-1];   
   
      this.setState({
        editMode: false,   
        addMode:false,      
        idencours:50,     
          
      })      
      localStorage.setItem('Suiviclients_Search_modifEncours', 0);
      
    }

    handleInfosChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      } 
     
      handleInputChange(evt) {
        const field = {}
        const fieldName= evt.target.name          
        field[fieldName] =  evt.target.value;

        console.log('listmodif', this.listmodif);
            
        let dejaexist = false;
        for (var key in this.listmodif) {
          var obj = this.listmodif[key];
          for (var prop in obj) {
            console.log(prop + " = " + obj[prop]);
            if (prop == fieldName) {
              obj[prop] = evt.target.value;
              dejaexist = true;
            }               
                         
          };  
        }
        if (!dejaexist) {
          this.listmodif.push (field);
        }
   

       
        const materiel = Object.assign({}, this.state.materiel, field)
        this.setState({
          materiel: materiel,
         })

  

    }

      updateSelectedDateTime = event => {
       
        this.setState({ selectedDateTime: event.target.value });
     
        const field = {}
        const fieldName= event.target.id;  
        field[fieldName] = new Date(event.target.value);
     
        const materiel = Object.assign({}, this.state.materiel, field)                
        this.setState({
            materiel: materiel,
        })
      
      }

      handleSelectedRow = (row)  => {
  
      }       
     
      handlePageChange = (event) => {
  
    
    }

      handleClickRow = (event)  => {
       
        if (!this.state.editMode)
        {
          
          const materiel = this.state.Rows[event.id-1];        
         
      
          this.setState({
            newid:event.id,
            idencours:50, 
            materiel : materiel,      
          });
      
          let tmpdat = materiel.date_mat;
          
       
        }
        else {
      
          if (this.state.idencours!=50) {

          }
          else {
        
          this.setState({
            newid:event.id,
            idencours:event.id, 
           
          });
        }
       }
     
      };


      handleOverRow = (event)  => {
 
        /*
        this.setState({
          newid:event.id,
        
        });
        */
      } 

      handleCellChange =(event)  => {
     
   
        
      } 
      
      handleCellChangeCommitted =(event)  => {
 
       
       
      } 

      setSelection=(selections) => {
           
      
      }
     


  Columns = [
  { field: 'numero',
    headerName: 'Numero',
    headerClassName: 'super-app-theme--header',  
    headerAlign: 'left',
    sortable:false,
    hide:true,
    width: 100, editable: false,
  },
  { field: 'date_mat',
   headerName: 'date_mat', 
   headerClassName: 'super-app-theme--header',  
   type: 'dateTime', 
   sortable:false,width: 170,
   editable: true 
  },
  {
    field: 'code_mat',
    headerName: 'Code',
    headerClassName: 'super-app-theme--header', 
    type: 'text',
    width: 80,
    sortable:false,
    editable: true,
  },
  {
    field: 'libel_interv',
    headerName: 'Intervention',
    headerClassName: 'super-app-theme--header', 
    type: 'text',
    width: 170,
    sortable:false,
    editable: true,
  },
  {
    field: 'qte_mat',
    headerName: 'qté',
    headerClassName: 'super-app-theme--header', 
    type: 'number',
    width: 80,
    sortable:false,
    editable: true,
  },
  {
    field: 'login_interv',
    headerName: 'Login',
    headerClassName: 'super-app-theme--header', 
    type: 'text',
    width: 80,
    sortable:false,
    editable: true,
  }
];


      
     render() {
    
        let  newrow =null;
        const selectionModel = [this.state.newid];
          
  
      
            return (
             
                     <Container>
                    
               
                  
                    <SplitPane split="vertical" defaultSize="60%" >
                         <div className = "boxRight" >  
                            <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>
                               
                                <div style={(!this.state.addMode && !this.state.editMode)?{ color: "#ffffff",visibility:"hidden",height:0 }
                                            :{ color: "#000000",visibility:"visible"}} >

                                      
                                      <FormControl className = {this.props.classes.formControlInterv} >      
                                        <div>
                                        <label className="label margin-l-5">
                                            Modification du Matériel : 
                                          </label>
                                       </div>                
                                        <div >   
                                          <label className="label margin-l-5">
                                            Date du matériel : 
                                          </label>
                                          <TextField
                                              id="date_mat"       
                                              type="datetime-local"
                                              disabled={!this.state.addMode}                                         
                                              
                                              value={this.state.selectedDateTime}
                                              onChange={this.updateSelectedDateTime}       
                                              className={this.props.classes.textFieldDate}                                             
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              inputProps={{style: {fontSize: 16,height:12,fontWeight:"bold"}}}  
                                            />    

                                        </div>
                                        <div>
                                        <label className="label margin-l-5">
                                            Code marériel :
                                            <select name="code_mat" value={this.state.materiel.code_mat}
                                            disabled={!this.state.editMode} className= "inputinterv width200 gras"
                                          
                                                onChange={this.handleInputChange}>
                                                {this.typeinterv.map((option) => (
                                                    <option value={option.code}>{option.label}</option>
                                                    ))}
                                            </select> 
                                        </label>       
                                        </div>
                                        <div>
                                          <label className="label margin-l-5">
                                            prix matériel :
                                            <input  className= "inputinterv width60 gras"
                                                name="prix_mat"
                                                type="number"  min={0}
                                                disabled={!this.state.editMode}                              
                                                value={this.state.materiel.prix_mat}
                                                onChange={this.handleInputChange} />
                                            </label>
                                        </div>
                                        <div>
                                        <label className="label margin-l-5">
                                            Intervention effectuée par :
                                            <select name="login_interv" value={this.state.interv.login_interv}
                                            disabled={!this.state.editMode} className= "inputinterv width200 gras"
                                                onChange={this.handleInputChange}>
                                                {this.typeutilisateur.map((option) => (
                                                    <option value={option.code}>{option.label}</option>
                                                    ))}
                                            </select> 
                                        </label>       
                                        </div>
                                        <div>
                                        <label className= {(localStorage.getItem('Suiviclients_Search_modifEncours') != 0)? "label-hidden": "label-red"}> 
                                          Email non valide !  
                                        </label>
                                        </div>

                                      </FormControl>
                                    
                                </div> 
                                
                                <div style={(this.state.addMode || this.state.editMode)?{ color: "#ffffff",visibility:"hidden" }
                                            :{ color: "#000000",visibility:"visible"}} >
                               
                                  <Grid >
                                        <Grid item lg className={this.props.classes.grid}>
                                        <DataGrid className={this.props.classes.root} id="gridinterv"
                                          components={{
                                            NoRowsOverlay: CustomNoRowsOverlay,
                                            
                                          }}
                                          rows={this.state.editMode?this.state.Rows:this.state.Rows}                                            
                                          columns={this.Columns} 
                                          disableColumnMenu
                                          headerHeight={45}
                                          rowHeight = {50}
                                          isRowSelectable={(params) => false}
                                          onRowSelected={(e) => this.handleSelectedRow(e)} 
                                          onSelectionChange={(newSelection) => {
                                                
                                              
                                            }}
                                          onRowOver=  {(rowData) => this.handleOverRow(rowData.row)} 
                                          hideFooter
                                          onRowClick={(rowData) => this.handleClickRow(rowData.row)} 
                                          onEditCellChange ={(cellData) => this.handleCellChange(cellData)} 
                                          onEditCellChangeCommitted ={(cellData) => this.handleCellChangeCommitted(cellData)} 
                                          selectionModel ={selectionModel}
                                          isCellEditable={(params) => params.row.id === this.state.idencours}
                                          onPageChange={(page) => this.handlePageChange(page)} >
                                        </DataGrid> 
                                                                
                                        </Grid>


                                    </Grid>  
                                
                                </div>
                                
                            
                            </CustomScrollbars>                   
                        </div>            
                        <div className= {this.state.editMode?"boxRight":"boxInterv" }>
                            <CustomScrollbars1 autoHide autoHideTimeout={500} autoHideDuration={200}>
                            
                                <Toolbar className={this.props.classes.toolbar}>
                                        
                                        <Typography className={this.props.classes.title} variant="h5">Intervention</Typography>
                                        <ButtonOw 
                                            title='Modificationn' 
                                            onClick={this.clickEdit}>
                                            <EditIcon style={this.state.editMode?{ color: "#ffffff",visibility:"hidden" }
                                            :{ color: "#000000",visibility:"visible"}} />
                                        </ButtonOw>
                                        <ButtonOw 
                                            title='Ajouter un matériel'   
                                            disabled= {this.state.editMode}                                                  
                                            onClick={this.clickAjout}>
                                            <AddIcon style={this.state.editMode?{ color: "#ffffff",visibility:"hidden" }:{ color: "#000000",visibility:"visible"}} />
                                          </ButtonOw> 
                                        <ButtonOw 
                                            title='Valider' 
                                            onClick={this.clickValid}>
                                            <DoneIcon style={this.state.editMode?{ color: "#f0621f",visibility:"visible" }
                                            :{ color: "#f0621f",visibility:"hidden"}} />
                                        </ButtonOw>
                                        <ButtonOw 
                                            title='Annuler' 
                                        disabled= {!this.state.editMode}  
                                            onClick={this.clickAnnul}>
                                            <CancelIcon style={this.state.editMode?{ color: "#f0621f",visibility:"visible" }
                                            :{ color: "#f0621f",visibility:"hidden"}} />
                                        </ButtonOw>
                                       
                                        <ButtonOw 
                                            title='Recherche' 
                                            disabled={this.state.editMode}  
                                            onClick={this.clickSearch}>
                                            <SearchIcon style={{ color: "#0ee73f"} }/>
                                        </ButtonOw>
                                        <CloseButton onClick={this.props.onClose} />                        
                                        
                                        
                                </Toolbar>                           
                                
                         
                                <FormControl fullWidth 
                                className={this.state.editMode?this.props.classes.formControlInfosModif:this.props.classes.formControlInfos}>
                                  <TextField 
                                   style ={this.state.editMode?{backgroundColor:"#ffffff"}:{}}
                                   variant ={this.state.editMode?"outlined":"filled"} 
                                     name="comment_mat"                                                                          
                                     disabled={!this.state.editMode} 
                                     multiline
                                     inputProps={{style: {fontWeight:"bold"}}} 
                                     rowsMax={10}
                                     value={this.state.materiel.comment_mat}
                                     onChange={this.handleInputChange}
                                  />
          
                                </FormControl>
                              

                          </CustomScrollbars1>
                        </div>
                  
                    
                    </SplitPane>

                
                   
                </Container>
       
         )
    }     
}  


const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 10,
      backgroundColor: 'rgba(252, 36, 89, 0.8)',
      width:10,
      height : 10,
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  
  const renderThumb1 = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 10,
      backgroundColor: 'rgba(22, 36, 89, 0.8)',
      width:10,
      height : 10,
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  const CustomScrollbars = props => (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...props}
    />
  );

  const CustomScrollbars1 = props => (
    <Scrollbars
      renderThumbHorizontal={renderThumb1}
      renderThumbVertical={renderThumb1}
      {...props}
    />
  );
    const mapStateToProps = state => ({
        loading: state.data.loading,
        driver: state.data.values,
        actualUser: state.identity.user,
        existCode: state.data.error,
        limits: state.client.informations.ow.limits,
        drivers: state.client.drivers,
    })

    const classes = theme => ({
      bkcol: {
        backgroundColor: '#DBFCFB',// bleu pastel
        backgroundColor: '#92ECEB',// bleu ciel
        backgroundColor: '#F9D7D7',// rose pastel 
        backgroundColor: '#F49090',// rose 
        backgroundColor: '#BBD3F7',// bleu 
        backgroundColor: '#93F7E1', 
        backgroundColor: '#CFC961',// marron
        backgroundColor: '#A0F950',//vert
        backgroundColor: '#5ef2e2', 
       
      },
      root: {
        '& .super-app-theme--header': {
          backgroundColor: theme.bkcolor.bleuciel,
          fontWeight: 'bold',
          color:theme.bkcolor.noir,
          
        },
        '& .MuiDataGrid-cellEditable': {
          backgroundColor: theme.bkcolor.rose,
        },
        '& .Mui-selected': {
          color: 'black',
          backgroundColor: theme.bkcolor.jaune,
         },

        scrollBehavior: 'smooth',
        flexGrow: 1,
        backgroundColor: theme.bkcolor.jaunepastel,
        height:'35vh',
      },
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: theme.bkcolor.bleuciel,
        height:45,
        minHeight : 20,
        
      },
        title: {
            color:'inherit',
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            
         },       
        memo: {
            textAlign: 'justify',
            marginBottom: '1.3em',
        },
        btn_class :{
            color:"primary",
        },          
        customColor: {
          // or hex code, this is normal CSS background-color
          color:theme.bkcolor.rosepastel,
          backgroundColor: theme.bkcolor.rosepastel,
          height:30,         
          maxHeight: 300,
        },   
        formControl: {         
          color: 'primary',    
          margin: theme.spacing(2),   
         // backgroundColor: theme.bkcolor.rosepastel,      
        } ,
        formControlInterv: {         
          color: 'primary',    
          margin: theme.spacing(2),                
       
       },   
       formControlInfos: {         
         color: 'primary',    
         margin: theme.spacing(2),   
        // backgroundColor: theme.bkcolor.rosepastel,      
       
       },   
      formControInfosModif: {         
       color: 'primary',    
       margin: theme.spacing(2),   
      // backgroundColor: theme.bkcolor.rosepastel,      
       } ,
        textField: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          width:200,
        },
        textFieldDate: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(2),
          marginBottom:theme.spacing(1),
          marginTop :5,
          width:180,
          backgroundColor: "white", 
          color: "black",   
        },
        grid : {
        '&& .MuiTableRow-hover': {
            cursor: 'pointer',
          },
          height:'90vh',
          backgroundColor: theme.bkcolor.jaunepastel,
         },
        formtextfield: {
          margin: theme.spacing(1),
        },
        textfieldinterv: {
          backgroundColor: theme.bkcolor.jaunepastel,
        },
        ztextarea: {
          '& .MuiTextField-root': {
            margin: theme.spacing(1),
            
          },
        },
        headergrid : {
          backgroundColor: theme.bkcolor.rosepastel,
        },
        superappthemeheader: {
          backgroundColor: 'rgba(255, 7, 0, 0.55)',
        },
       
         
         
})

export default  (withStyles(classes) (MaterielForm))
