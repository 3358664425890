import { Container, Paper, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginForm from '../../core/components/authentication/LoginForm';
import { login, logout } from '../../core/redux/actions/IdentityActions';
import { setTitle } from '../../core/redux/actions/WindowAppActions';


/**
 * Page d'authentification de l'utilisateur
 */
class AuthenticationPage extends Component {
    
    state = {
        errorAuth : false,
        
    }
    componentDidMount() {
        this.props.setTitle ('');
      
    }

    onSubmit = (data) => {
        this.setState({
            errorAuth: false
        })

      // eslint-disable-next-line
       
            
            this.props.login (data)
            .then ( (response) => {
                
                this.props.history.push ('/SplitPage')
           

            }, (error) => {
                console.error('Erreur login', error)
                this.props.logout ()
                .then ( (response) => {
              
                   this.props.history.push ('/')
        
                }, (error) => {
                    console.warn('Erreur', error)
                    
                } )
               // this.props.history.push ('/')
            } )
        
         

          
        
         
    }

    render() {
     
        return (
            <Container className={this.props.classes.root}>
                <div>
                    <div className={this.props.classes.title} >
                        <h1>SuiviClients</h1>
                        <h3>Suivi des clients ATHMO</h3>
                    </div>
                    <Paper>
                        <LoginForm onLogon={this.onSubmit} errorAuth={this.state.errorAuth} />
                    </Paper>
                </div>
                <div className={this.props.classes.version}>
                    version {process.env.REACT_APP_VERSION}{process.env.REACT_APP_ENV}
                    &nbsp;&mdash;&nbsp;
                  
                </div>                    
            </Container>
            
        )
    }
}

const mapStateToProps = state => ({    
    numClientEnCours: state.identity.numClientEnCours,
    clients: state.identity.clients,
})

const mapDispatchToProps = (dispatch) => {
   
    return {         
            login : bindActionCreators (login, dispatch),
            logout : bindActionCreators (logout, dispatch),
            setTitle : bindActionCreators (setTitle, dispatch),
           
        };

}

const classes = theme => ({
    root: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '90vh',
        backgroundColor: theme.bkcolor.jaunepastel,
    },    

    title: {
        textAlign: 'center',
        marginBottom: '4em',
    },
    version: {
        textAlign: 'center'
    }
})
export default connect (mapStateToProps, mapDispatchToProps)(withStyles (classes) (AuthenticationPage));
