import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ButtonOw from './ButtonOw'
import CloseIcon from '@material-ui/icons/Close'
class CloseButton extends Component {
  static propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  }

  render() {
    const title = this.props.title ? this.props.title : 'Fermer'
    return (
        <ButtonOw onClick={this.props.onClick} title={title}>
            <CloseIcon />
        </ButtonOw>
    )
  }
}
export default CloseButton
