import { Button, Container, TextField, withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * Formulaire récupérant les identifiants de l'utilisateur
 */
class LoginForm extends Component {

    static propTypes = {
        onLogon : PropTypes.func.isRequired
    }
    constructor() {
        super ();
        this.changeFormField = this.changeFormField.bind (this)
        this.submit = this.submit.bind (this)
    }

    state = {
        identity : {            
            login : '',
            password : ''   
        },
        errors : {
            errorAuth : false,
            login : '',
            password : '',
            form : ''
        },
        connecting : false,
        errorLogin :false,
      
       
    }
    componentDidMount() {
   
       
        this.setState({       
        connecting : false,    
         });  

    }
    submit = (ev) => {
        
        ev.preventDefault ();
            
        if (this.isValid ()) {

            this.setState({
                errorAuth: false,
                connecting : true,
                onSubmit: true,
            });
            
            this.props.onLogon(this.state.identity)
            
        }
    }
    changeFormField = (ev) => {
        const etat = Object.assign (this.state.identity, {[ev.target.name] : ev.target.value});
        this.setState ({
            identity : etat,
            errorAuth: false,
        });
      
    }

    isValid = () => {
        
             
        let isValid = true;
        
            let text = Object.assign (this.state.errors, {
                login : '',
                password : ''
            });
            if (this.state.identity.login.length < 3) {
                text = Object.assign (this.state.errors, {login : 'Identifiant trop court'});
                isValid = false;
            }
            if (this.state.identity.password.length < 8) {
                text = Object.assign (this.state.errors, {password : 'Mot de passe trop court'});
                isValid = false;
            }
            this.setState ({errors : text});
        
       return isValid;
    }
    
    componentDidUpdate () {
    
        if (!this.props.errorAuth && this.state.connecting) {
    
            this.setState ({
                connecting: false,
                errorAuth: this.props.errorAuth,
                errorLogin :true,
            })
        }   
    }
    render() {
        
        return (
            <Container className={this.props.classes.root}>
                <h2>Connexion</h2>
                <section className={this.props.classes.form}>
                    <form noValidate onSubmit={this.submit}>
                        
                        {
                            this.state.errorLogin &&
                                (<div>
                                   <Alert severity="error">{this.state.errors.form ? this.state.errors.form : `Erreur d'identifiants`}</Alert>
                                 </div>
                                )
                        }
                        <div>
                            <TextField 
                                name="login"
                                label="Identifiant"
                                type="text"
                                error={this.state.errors.login.length > 0}
                                helperText={this.state.errors.login}
                                required
                                value={this.state.identity.login} 
                                onChange={this.changeFormField} />
                        </div>
                        <div>
                            <TextField
                                name="password"
                                label="Mot de passe"
                                type="password"
                                required
                                error={this.state.errors.password.length > 0}
                                helperText={this.state.errors.password}
                                value={this.state.identity.password}
                                onChange={this.changeFormField} />

                        </div>
                        <div>
                            <Button type="submit"
                                    variant="contained"
                                    color= {this.state.connecting ?"primary":"primary"}>
                                 
                                {this.state.connecting ? 'Connexion en cours...' : 'Se connecter'}
                            </Button>
                        </div>
                    </form>
                    </section>
                    <section>
                        <div>Utilisez les mêmes identifiants que ceux utilisés pour Optimal Web</div>
                        
                    </section>
                {this.props.children}
            </Container>
        )
    }

}

const classes = theme => ({
    root: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        backgroundColor: theme.bkcolor.bleuciel,
    },
    form: {
        marginBottom: '2em',
    }
})

export default withStyles(classes) (LoginForm)
