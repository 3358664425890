import { Card, CardContent, CardHeader, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import AthmoIcon from '../icons/AthmoIcon'
import ButtonOw from '../window/ButtonOw'

class Galaxy extends Component {

    state = {
        displayCard: false,
    }

    handleDisplay = () => {
        this.setState({
            displayCard: !this.state.displayCard,
        })
    }
    render() {
        const titleCard = (<span>Autres services <em>Athmo</em></span>)
        return (
            <>
            <ButtonOw title={titleCard}
                    onClick={this.handleDisplay}>
                <AthmoIcon />
            </ButtonOw>
            {this.state.displayCard && 
                <Card className={this.props.classes.card}>
                    <CardHeader title={titleCard} />
                    <CardContent>
                        <p>
                            <a href="https://optimalweb.athmo.eu" 
                                    target="_blank" rel="noreferrer"
                                    onClick={this.handleDisplay}>
                               Optimal Web
                            </a>
                        </p>
                        <p>
                            <a href="https://gestion.athmo.eu" 
                                    target="_blank" rel="noreferrer"
                                    onClick={this.handleDisplay}>
                               Gestion ATHMO
                            </a>
                        </p>
                        <p>
                            <a href="https://extend.inescrm.com/" 
                                    target="_blank" rel="noreferrer"
                                    onClick={this.handleDisplay}>
                                Ines
                            </a>
                        </p>
                    
                    </CardContent>
                </Card>
            }
            </>
        )
    }

}

const classes = theme => ({
    card: {
        display: 'block',
        position: 'absolute',
        top: '70px',
        zIndex: theme.zIndex.appBar
    }
})
export default withStyles(classes) (Galaxy)
