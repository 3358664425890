import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import fscreen from 'fscreen'
import { Tooltip } from '@material-ui/core';

/**
 * Bouton permettant la bascule de l'application en mode "Plein écran"
 */
class ButtonFullScreen extends Component {

    state = {
        fullScreen : false
    }
    componentDidMount() {
        fscreen.onfullscreenchange = this.onChangeMode
    }

    onChangeMode = (evt) => {
        this.setState({
            fullScreen : (fscreen.fullscreenElement !== null)
        })
    }
    changeMode = () => {
        
        if (this.state.fullScreen) {
            fscreen.exitFullscreen(document.body)
        } else {
            fscreen.requestFullscreen(document.body)
        }
    }
    render() {

        /*
         * aucun rendu si le mode Plein écran n'est pas pris en charge
         */
        if (!fscreen.fullscreenEnabled) {
            return (<></>)
        }
        return (
            <Tooltip title={this.state.fullScreen ? 'Sortir du mode Plein Écran' : 'Mode Plein Écran'}>
                <IconButton
                    color="primary" 
                    onClick={this.changeMode}
                    >
                    {this.state.fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
            </Tooltip>
        )
    }
}

export default ButtonFullScreen
