import {
    CircularProgress, Container, Paper, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow,
    TableSortLabel, withStyles
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ResizableIconButton from '../../core/components/window/ResizableIconButton';
import WaitBlock from '../../core/components/window/WaitBlock';
import { getListeMaintentreDates, getListeMaintparDate, getNextMaint } from '../../core/redux/actions/ClientActions';
import { setTitle } from '../../core/redux/actions/WindowAppActions';
import '../../stylesheets/ChoixClient.css';





class ChoixMaint extends Component {

    /**
     * Champs avec un filtre
     */
    headerCells = [
        {
            field : 'date_maint',
            label : 'Date',
        },
        {
            field : 'numero',
            label : 'Client',
        },        
        {
            field : 'nom client',
            label : 'Société',
        },      
        {
            field : 'montant',
            label : 'montant', 
        },
        {
            field : 'commentaire',
            label : 'Commentaire',
        },
       
    ]

    
    valuesRef = []
   
    constructor(props) {
        super(props);
        this.state = {
            choice: false,
            modifEncours : false,
            displayedHelp : false,
            initialsValues: [],
            values : [],
            order : 'desc',
            columnSort : 'date_maint',
            filtersEnable : true,
            titre: 'Liste des prochaines dates de Maintenance',
            filters : {
                numero: '',
                date_maint: '',
                montant:'',                
            },
            filtre :'',        
            maint:{
                id:0,
                numero :0,
                nom_maint:'',
                date_iso : new Date(),
                date_maint : new Date(),
                montant:0,
                date_pay: new Date(),
                ordre:1,
                commentaire:'',
                
            },
            begin : false,
            numclientselect:0,
            dateselect: new Date(),  
            evt:null,
            evtclick:null,
            changeliste : false,
            typeliste:{},
            reqencours:false,
        }
        this.onClickRow = this.onClickRow.bind (this)
        this.handlerSort = this.handlerSort.bind (this)
        this.handleInputChange =this.handleInputChange(this)
     
      
        this.clickSearch = this.clickSearch.bind(this);
                
    }

    typeinterv = [
        {label:"", code:"0"},
        {label:"Dernier MAJ reçue", code:"DENV"},
        {label:"Inter clôturée", code:"OFIC"},            
        {label:"Pb en Cours", code:"KFIC"},
        {label:"Installation", code:"INST"},
        {label:"PB logiciel Résolu", code:"OLOG"},
        {label:"Pb logiciel", code:"KLOG"},
       ];

    componentDidMount() {
      
        /*
         * Contrôle accès
         */
     
        
        // eslint-disable-next-line
     

            if (!this.props.isConnected) {
                console.warn('Not connected')
               // this.props.history.push ('/')
            }
            this.props.setTitle ("LIste des Prochaines Dates de Mainenance")

            var today = new Date();              
           
            let date_debut = today.toISOString().slice(0,10);
            let zliste ={
                code:1,
                titre :'',
                qte :0,
                datedeb : date_debut,
                datefin : "2021-07-01",
               };
          
                this.setState({
                    typeliste : zliste,
                    },()=>{
                   
                      this.recupdonnees();
                })
            
    } 
    
    


 
  
    recupdonnees = () => {
        this.setState({
            reqencours:true,
         })
        var opt = parseInt(this.state.typeliste.code);
      
        let datedeb = '';
        let datefin = '';
        let datavalues = [];  
        this.afficheMaint(datavalues);
        this.setState({
            reqencours:true,
         })

        switch (opt) {
            case 1:
                datedeb = this.state.typeliste.datedeb;   
                this.props.getListeMaintparDate (datedeb,'avant')              
                .then ( (response) => {
                  
                 datavalues = response.value;
              
                 this.afficheMaint(datavalues);
                }, (error) => {
                console.warn('error ',error);
                
                })
              break
    
            case 2:
                datedeb = this.state.typeliste.datedeb;   
                datefin = this.state.typeliste.datefin;                                         
                this.props.getListeMaintentreDates (datedeb,datefin)
                .then ( (response) => {
                
                 datavalues = response.value;
      
                 datavalues = datavalues.map((x) => x);
           
                 this.afficheMaint(datavalues);

                }, (error) => {
                console.warn('error ',error);
                
                })
              break
              case 3:
                datedeb = this.state.typeliste.datedeb;     
                datefin = this.state.typeliste.datefin;              
                this.props.getListeMaintentreDates (datedeb,datefin)
                .then ( (response) => {
                 
                 datavalues = response.value;
          
                 datavalues = datavalues.map((x) => x);
               
                 this.afficheMaint(datavalues);
                }, (error) => {
                console.warn('error ',error);
                
                })
              break
              default:
              break;
              
            }  
        
         
              
        
    }
    

    afficheMaint (datas) {
        
        const nbrows = datas.length;
     
        let items = [];
        let titz = '';
      
        if (nbrows ===0) {
          titz = 'Liste des prochaines dates de Maintenance ';
          titz =  this.state.typeliste.titre;
          titz += '  ........  ( ';
          titz += nbrows.toString();
          titz += '  lignes ) ';
          this.setState({
            columnContract: false,
            filtersEnable: false,  
            values:items,   
            titre: titz,         
          })
  
        } 
        else {
            let datmaint = new Date().toLocaleString();
            let datpay = new Date().toLocaleString();
            let datiso = new Date().toISOString();
           // let datpayiso = new Date().toISOString();
            let item ={};
            
            for (var i=0;i<nbrows;i++) {
                    
                    let tmpdat = datas[i].date_maint;     
                    if (tmpdat === null) {
                         item ={
                            id:i+1,
                            numero :datas[i].numero,      
                            date_iso : null,        
                            date_maint :null,
                            montant:datas[i].montant,
                            //nom_maint : this.recupNom(datas[i].numero),
                            nom_maint :datas[i].societe,
                            date_pay :null,
                            ordre:datas[i].ordre,
                            commentaire:datas[i].commentaire,
                        
                        };     
                    }
                    else {
                        
                        let datz =  datas[i].date_maint;     
                     
                        datz = tmpdat.substr(3,2)+'/'+ tmpdat.substr(0,2)+'/'+tmpdat.substr(6,13);
                      
                        datiso = new Date(datz).toISOString();        
                        datmaint =new Date(datz).toLocaleString().slice(0,10);
                        datz =  datas[i].date_pay;     
                        datz = tmpdat.substr(3,2)+'/'+ tmpdat.substr(0,2)+'/'+tmpdat.substr(6,13);
                       // datpayiso = new Date(datz).toISOString();        
                        datpay =new Date(datz).toLocaleString().slice(0,17);
                                    
                                
                         item ={
                            id:i+1,
                            numero :datas[i].numero,      
                            date_iso : datiso,        
                            date_maint :datmaint,
                            montant:datas[i].montant,
                            //nom_maint : this.recupNom(datas[i].numero),
                            nom_maint :datas[i].societe,
                            date_pay :datpay,
                            ordre:datas[i].ordre,
                            commentaire:datas[i].commentaire,
                        
                        };       
                    }
                      items.push(item);
                    
            }
            titz = 'Liste des prochaines dates de Maintenance ';
            titz =  this.state.typeliste.titre;
            titz += '  ........  ( ';
            titz += nbrows.toString();
            titz +=  '  lignes ) ';
            this.setState ({
               order : 'asc',
               columnSort : 'date_maint',
               values:items,
               maint:items[this.state.newid -1],
               titre: titz,
            })
            this.props.afficheTitre (titz); 
            this.triAscendant ('date_maint', items)
        
         
        }
          
        try {
        
          this.setState({
            selectedDateTime : (this.state.maint.date_maint).toISOString().slice(0, 16),
          })
      
        } catch(e) {
          
          this.setState({
            selectedDateTime :"",
          })
        
        }
        this.setState({
            reqencours:false,
         })
    }
  
      recupLibel (code) {
    
        if (code !=='') {
          const resu = this.typeinterv.find(item =>item.code === code) ;   
        ;
          if (resu !== undefined) {
            return resu.label;
          }
          else return '';
        }
        else return '';  
       }
  
       recupNom (code) {

        if (code !=='') {
            let num = parseInt(code);      
           
            let infosClient =  this.props.clients.find(element => element.numero === num);  
            if (infosClient !== undefined) {
                    
                const resu = infosClient.societe;                 
                if (resu !== undefined) {
                return resu;
                }
                else return '';
            }
            else return '';
        }
        else return '';  
       } 
      

    handlerSort = (property) => {

        
        /*
         * inversion de l'ordre de tri de la propriété précédemment sélectionnée
        */
      if (property === 'date_maint') {
            if (this.state.columnSort === property) {    

                let initval = this.state.values;

                this.setState({
                   values:[],
                   },()=>{
                    
                    let reverseval = initval.reverse();
                    this.setState({
                        order : (this.state.order === 'asc') ? 'desc' : 'asc',
                        values : reverseval,
                       },()=>{
                    
                        
                      })
                    
                  })
               

                /*
                * Changement de propriété de tri
                */
                } else {
                    this.triAscendant (property, this.state.values)
                    this.setState ({            
                        order : 'asc',
                        columnSort : property,
                    })
                }
            }
    }

    triAscendant = (property, values) => {
        
        /*
        values.sort ((a, b) => {
     
            if (a[property] < b[property]) {
      
                return -1
            } else if (a[property] > b[property]) {
      
                return 1
            } else {
      
                return 0
            }
        })
        */
    }

    handlerFilters = () => {       

       
        this.setState({
          
            filtersEnable : !this.state.filtersEnable,                
        })
        
    }



    resetClick = () => {

    
        this.setState({
            modifEncours: false
        })  
    }

     

    componentDidUpdate(prevProps, prevState) {
        if (this.props.changeliste) {

            if (this.state.changeliste !== this.props.changeliste ) {

                this.setState({
                    typeliste : this.props.listemodif,
                    changeliste : this.props.changeliste,
                    
                    })
               
                    this.recupdonnees();
             
            
            }
        }
        else {
            if (this.state.changeliste !== this.props.changeliste ) {
                this.setState({
                    
                    changeliste : this.props.changeliste,
                })
            }
        }
        
    }

    onClickRow (num,date, event) {
     
       this.setState({
        evtclick :event,
       })
       
        event.preventDefault ();
        const displayCode = (localStorage.getItem('Suiviclients_Search_modifEncours') === '0')
           
        if (displayCode) {
            this.setState({
                choice: true,
                numclientselect:num,
                dateselect:date,
            })
            
            if (this.props.clients) {
                let infosClient =  this.props.clients.find(element => element.numero === num);
                if (infosClient !== undefined) {
                    this.props.setTitle(infosClient.societe)
                }
                this.props.setTitle(num);
            }
            
            this.props.afficheClientClick(num);

        }
        else { 
            this.setState({
                modifEncours: true
            })
        }
    }

    clickSearch ()
    {
  
       var val = this.state.filters[this.state.filtre];
  
       
        if (val !== undefined && val !== null) {
             // eslint-disable-next-line
            if (val.length !=0) {
                this.handlerSearch (this.state.filtre,this.state.evt);
            }
        }
       
       
          
       
    }

    handleInputChange(evt) {
      //  const field = {}
      //  const fieldName= evt.target.name     
      //  var str = evt.target.value;
    }

    handlerSearch = (field, evt) => {
        
        evt.preventDefault ()
        let filters = this.state.filters
        filters[field] = evt.target.value      
        
        let values = JSON.parse(JSON.stringify(this.state.initialsValues))

        for (let fieldName in filters) {
            let search = filters[fieldName];

            if (search && typeof(search) === 'string' && search !== '') {
               
                values = values.filter (elt => { 
                   
                   if ((fieldName ==='numero') || (fieldName ==='numcompta')) {
                       let numsearch = search.toString();
                       numsearch = numsearch.trim();                     
                     
                    return ((elt[fieldName]).toString()).includes (numsearch)
                   
                   }
                   else {
                     
                       
                   return elt[fieldName] !== null && elt[fieldName].includes(search)
                   }
                })
                
            }
        }
                   
        this.setState ({
            filters : filters,
            values : values
        })

        if (values.length === 1) {
            var num = values[0].numero;
            var ev = new Event('input', { bubbles: true });
            ev = this.state.evtclick;
            this.onClickRow(num,ev);

        }
    }


   

   
      
    render() {
       
        if (  (!this.props.clients)      
        || this.props.clientLoading
        || this.state.reqencours
        ) 
        {
            return (
            <Container >
              
              {this.state.reqencours?
                <WaitBlock text="Requête en cours..."/> 
                :              
                 <WaitBlock text="Chargement en cours..."/> 
                } 
            </Container>)
       
        } else {      
               
            return (
                <Container className={this.props.classes.root}>
                  
                    <Paper >
                    {this.state.modifEncours ? 
                    <div className="outlined_encours">  
                        <CircularProgress className="margin-t-15 margin-l-5" />
                        <label className="label margin-t-15">  
                            Modification en cours - Action Non Autorisée                    
                        </label>                        
                        <ResizableIconButton   title="Cliquer pour annuler"
                            onClick={this.resetClick}>
                            <CancelIcon   style= {{ color: "#ff0000",visibility:"visible", fontSize: 48}} />                                                                 
                        </ResizableIconButton>
                    </div>:
                        <TableContainer component={Paper} className="containerChoixclient">    
                           

                            <Table  stickyHeader size ="small" aria-label="choix client" >  
                          
                                <TableHead >   
                                                 
                                    <TableRow >
                                        {                                                                   
                                            this.headerCells.map ( (cell) => {
                                                return (
                                                    <TableCell  key={cell.field}
                                                        sortDirection={this.state.order}
                                                        className={this.props.classes.head}
                                                        align='left'>
                                                        <div>
                                                            <TableSortLabel active={this.state.columnSort === cell.field} 
                                                                direction={this.state.order}
                                                                hideSortIcon
                                                                onClick={() => {this.handlerSort (cell.field)}} >
                                                                {cell.label}       
                                                            </TableSortLabel>
                                                        </div>
                                                        
                                                            
                                                    </TableCell>
                                                )
                                            })
                                        }
                                        
                                    </TableRow>
                                    
                                </TableHead>
                                <TableBody  >
                                {this.state.values.length === 0 ?
                                    <TableRow key='vide'>
                                        <TableCell>Aucun choix possible.</TableCell>
                                    </TableRow> :
                                    this.state.values.map( (inter) => (
                                      
                                        <TableRow  className = {this.props.classes.root}                                     
                                         key={inter.numero} 
                                                                                      
                                                selected={inter.numero === this.state.numclientselect &&
                                                 inter.date_maint === this.state.dateselect}
                                                onClick={(evt) => {this.onClickRow (inter.numero,inter.date_maint, evt)}}>
                                            <TableCell component="th" scope="row">{inter.date_maint}</TableCell>
                                            <TableCell align="left">{inter.numero}</TableCell>
                                            <TableCell align="left">{inter.nom_maint}</TableCell>                                                                                      
                                            <TableCell align="left">{inter.montant}</TableCell>
                                            <TableCell align="left">{inter.commentaire}</TableCell>
                                        
                                      
                                    </TableRow>  
                                    ))}
                                </TableBody>
                            </Table>
                           
                        </TableContainer>
                        }
                    </Paper>  
                        
                </Container>
            
            )
        }

    }

}

  

const mapStateToProps = state => ({
    isConnected : state.identity.isConnected,
    numClientEnCours : state.identity.numClientEnCours,
    clients : state.identity.clients,
    identityLoading : state.identity.loading,
    
})

const mapDispatchToProps = dispatch => {
    return {
        getListeMaintparDate : bindActionCreators (getListeMaintparDate, dispatch),     
        getListeMaintentreDates : bindActionCreators (getListeMaintentreDates, dispatch),     
        getNextMaint : bindActionCreators (getNextMaint, dispatch),     
        setTitle : bindActionCreators (setTitle, dispatch),   
    }
}

const classes = theme => ({
    bkcol: {
        /*
        backgroundColor: '#DBFCFB',// bleu pastel
        backgroundColor: '#92ECEB',// bleu ciel
        backgroundColor: '#F9D7D7',// rose pastel 
        backgroundColor: '#F49090',// rose 
        backgroundColor: '#BBD3F7',// bleu 
        backgroundColor: '#93F7E1', 
        backgroundColor: '#CFC961',// marron
        backgroundColor: '#A0F950',//vert
        backgroundColor: '#5ef2e2', 
       */
      },    
        root: {
            '&:nth-of-type(odd)': {
                           
                backgroundColor: theme.bkcolor.rosepastel, 
            },
            '&:nth-of-type(zeven)': {
                           
                backgroundColor: theme.bkcolor.vertpastel, 
            },
            "&.Mui-selected, &.Mui-selected:hover": {
                color: theme.bkcolor.noir,
                backgroundColor: theme.bkcolor.rose,
                cursor: 'none', 
            },
            '&.MuiTableRow-hover': {
                cursor: 'pointer',  
                backgroundColor: theme.bkcolor.rose, 
             
            } , 
               scrollBehavior: 'smooth',
               flexGrow: 1,
               backgroundColor: theme.bkcolor.bleupastel,
               display :'table-row',
               width :'100vh',
            } , 
        head: {
         
            backgroundColor: theme.bkcolor.vertpastel,
            color: theme.bkcolor.noir,            
        },
        headcontrol: {
            height:'30px',
            backgroundColor: theme.bkcolor.vertpastel,
            color: theme.bkcolor.noir,
            width :'100vh',
        },
      
        body: {
            fontSize: 14,
        },
        zformControl: {
            margin: theme.spacing.unit * 1,
            fontSize: 10,
          },
        zgroup: {
        margin: `${theme.spacing.unit}px 0`,
        fontSize: 10,
        },
        formControl: {
            margintop:1,
            fontSize: 8,
          },
        group: {
            margintop:1,
        fontSize: 6,
       
        },
        icon: {
            borderRadius: '50%',
            width: 16,
            height: 16,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
              outline: '2px auto rgba(19,124,189,.6)',
              outlineOffset: 2,
            },
            'input:hover ~ &': {
              backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
              boxShadow: 'none',
              background: 'rgba(206,217,224,.5)',
            },
          },
          checkedIcon: {
            backgroundColor: '#137cbd',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
              display: 'block',
              width: 16,
              height: 16,
              backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
              content: '""',
            },
            'input:hover ~ &': {
              backgroundColor: '#106ba3',
            },
          },

        
    })

 

export default connect (mapStateToProps, mapDispatchToProps) (withStyles(classes)  (ChoixMaint))