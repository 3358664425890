import { URL_API } from './../core/components/Init';
import BaseService from './BaseService';



/**
 * Service d'accès aux informations liées à l'identité de l'utilisateur
 * 
 * @todo à réorganiser pour utiliser le même modèle d'accès à l'API que ElementsAction
 * 
 */
export default class SuiviclientsService extends BaseService {

    /**
     * Récupère l'identité complète
     */
    getIdentity () {
        return this.get(`${URL_API}/server/who`);
    }
    
    /**
     * Liste des clients accessibles
     */
    getClients () {
        return this.get (`${URL_API}/server/getlistetousclients`)
    }

    getClientsActifs () {
        return this.get (`${URL_API}/server/getlistetousclientsactifs`)
    }

    /**
     * Détermination du client en cours de gestion
     * @param {Number} numClient 
     */
    setClient (numClient) {
       
        return this.get(`${URL_API}/server/getinfosclient/${numClient}`)
    }

   
    updateClient (changes) {
       
        return this.post(`${URL_API}/server/updateclient/`,changes)
    }

    insertClient (changes) {
       
        return this.post(`${URL_API}/server/insertclient/`,changes)
    }

      /**
     * dernière interventions
     */
    
       getLastInterv (nb) {
        return this.get (`${URL_API}/server/getlastinterv/${nb}`)

    }

    updateInterv (changes) {
       
        return this.post(`${URL_API}/server/updateinterv/`,changes)
    }

    insertInterv (changes) {
       
        return this.post(`${URL_API}/server/insertinterv/`,changes)
    }

      /**
     * liste des dernières interventions par client    
     */
       getListeLastIntervParClient (numClient,nb,ofs) {        
        return this.get(`${URL_API}/server/getlistelastintervparclient/${numClient}/${nb}/${ofs}`)
    
    }
    /**
     * liste des clients sans intervention depuis le    
     */

     getListeSansIntervDepuisle (date_debut) {        
        return this.get(`${URL_API}/server/getlistesansintervdepuisle/${date_debut}`)
    
    }
    
       /**
     * liste des dernières interventions depuis le    
     */
        getListeLastIntervDepuisle (date_debut) {        
        return this.get(`${URL_API}/server/getlistelastintervdepuisle/${date_debut}`)
    
    }

       /**
     * liste des  interventions entre 2 dates    
     */
        getListeIntervEntreDates (date_debut,date_fin) {        
            return this.get(`${URL_API}/server/getlisteinterventredates/${date_debut}/${date_fin}`)
        
        }

     /**
     * liste des prochains appels à effectuer    
     */
    getNextAppels (nb) {
        return this.get (`${URL_API}/server/getnextappels/${nb}`)

    }

     /**
     * liste des prochains appels à effectuer à partir d'une date    
     */
      getListeAppelsparDate (date_appel,opt) {        
            return this.get(`${URL_API}/server/getlisteappelspardate/${date_appel}/${opt}`)
        
        }
     
      /**
     * liste des prochains appels à effectuer par client    
     */
       getListeAppelsparClient (numClient) {        
        return this.get(`${URL_API}/server/getdateappelsparclient/${numClient}`)
    
    }   

    updateAppel (changes) {
       
        return this.post(`${URL_API}/server/updateappels/`,changes)
    }

    insertAppel (changes) {
       
        return this.post(`${URL_API}/server/insertappels/`,changes)
    }

    deleteAppel (changes) {
       
        return this.post(`${URL_API}/server/deleteappels/`,changes)
    }

    
      /**
     * liste des prochaines Date de Maintenance    
     */
       getNextMaint (nb) {
        return this.get (`${URL_API}/server/getnextmaintenance/${nb}`)

    }

     /**
     * liste des prochaines dates de maintenance    
     */
      getListeMaintparDate (date_deb,opt) {        
            return this.get(`${URL_API}/server/getlistemaintenancepardate/${date_deb}/${opt}`)
        
        }

      /**
     * liste des prochaines dates de maintenance entre dates   
     */
       getListeMaintentreDates (date_deb,date_fin) {        
        return this.get(`${URL_API}/server/getlistemaintenanceentredates/${date_deb}/${date_fin}`)
    
    }    

         /**
     * liste des prochaines date de maintenancer par client    
     */
          getListeMaintparClient (numClient) {        
            return this.get(`${URL_API}/server/getdatemaintenanceparclient/${numClient}`)
        
        }   
       

        updateMaint (changes) {
       
            return this.post(`${URL_API}/server/updatemaintenance/`,changes)
        }
    
        insertMaint (changes) {
           
            return this.post(`${URL_API}/server/insertmaintenance/`,changes)
        }
    
       
    
     /**
     * liste des types d'intervention    
     */
      getListeParamInterv (opt) {        
        return this.get(`${URL_API}/server/getlisteparaminterv/${opt}`)
    
    }    

     /**
     * liste des types de motif    
     */
      getListeParamMotif (opt) {        
        return this.get(`${URL_API}/server/getlisteparammotif/${opt}`)
    
    }    
      
        
       
    
} 
