
import {
  CircularProgress, Container,
  Grid, Toolbar,
  Typography, withStyles
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/AddCircleOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import SplitPane from "react-split-pane";
import { bindActionCreators } from 'redux';
import ResizableIconButton from '../../../core/components/window/ResizableIconButton';
import '../../../stylesheets/splitpane.css';
import '../../../stylesheets/tabpanel.css';
import { getListeMaintparClient, insertMaint, updateMaint } from '../../redux/actions/ClientActions';
import { setTitle } from '../../redux/actions/WindowAppActions';
import ButtonOw from '../window/ButtonOw';



  
  function CustomNoRowsOverlay() {
    return (
      <GridOverlay className={classes.root}>
         <div className={classes.label}>Aucune Date de Maintenance </div>
     </GridOverlay>
  );
}

class MaintForm extends React.Component {
  constructor(props) {
    super(props);


  this.state = {  
    etat :0,
    editMode:false,
    addMode:false,
    reaffiche  : false,    
    numclient:0,
    newid:1,
    idencours:50,
    nbrows :0,
    ajoutPossible :1,
    ordremax :0,
    datedujour :"2020-07-28",
    datemaxi:"2022-07-28",
    selectedDateTime: "2017-05-24",
    selectedDatePay: "2017-05-24",
    zmaint:{
      id:1,
      numero :0,
      date_iso : new Date(),
      date_maint : new Date(),
      monntant:0,
      date_payiso : new Date(),
      date_pay: new Date(),
      ordre:1,
      commentaire:'',
     
  },
    maint:{
        id:0,
        numero :0,
        date_iso : new Date(),
        date_maint : new Date(),
        monntant:0,
        date_payiso : new Date(),
        date_pay: new Date(),
        ordre:1,
        commentaire:'',
    },
    maintnull:{
      id:0,
      numero :0,
      date_iso : new Date(),
      date_maint : new Date(),
      monntant:0,
      date_payiso : new Date(),
      date_pay: new Date(),
      ordre:1,
      commentaire:'',
  },
    Rows:[],
    
    newRows: [
      {
        id:1,
        numero: 5049,
        date_iso:new Date("2000-01-01T00:00"),
        date_maint:new Date("2000-01-01T00:00"),
        montant: 0,
        date_payiso:new Date("2000-01-01T00:00"),
        date_pay:new Date("2000-01-01T00:00"),
        ordre: 1,        
        commentaire:'',
        
      },
    ]
   
   }
   this.handleInputChange = this.handleInputChange.bind(this);
   this.afficheMaint = this.afficheMaint.bind(this);
   this.modifdatenulle = this.modifdatenulle.bind(this);
    
  }
 
    typeinterv = [
      {label:"", code:"0"},
      {label:"Dernier MAJ reçue", code:"DENV"},
      {label:"Inter clôturée", code:"OFIC"},            
      {label:"Pb en Cours", code:"KFIC"},
      {label:"Installation", code:"INST"},
      {label:"PB logiciel Résolu", code:"OLOG"},
      {label:"Pb logiciel", code:"KLOG"},
     ];

     typeutilisateur = [
      {label:"", code:"0"},
      {label:"Nicolas MEDINA", code:"NME"},
      {label:"Jean Jacques BARET", code:"JJB"},
      {label:"Jean Philippe", code:"JPB"},
      {label:"Claude CASTELLO", code:"CLC"},
    ];
    
    listmodif = [];


    afficheMaint (values) {
 
      const nbrows = values.length;
      let datz ='';
      let datz1 ='';
      let items = [];
      if (nbrows ===0) {
        let maint0 = this.state.maintnull;
        this.setState({
          Rows:items,
          maint:maint0,
          nbrows :0,
          ordremax :0,
        })

      } 
      else {

          let datiso = null;
          let datpayiso = null;
          let datmaint = null;
          let datpay = null;
          for (var i=0;i<nbrows;i++) {
            let tmpdat = values[i].date_maint;  
       
            try {
              if (tmpdat != null) {
                datz = tmpdat.substr(6,4)+'-'+ tmpdat.substr(3,2)+'-'+tmpdat.substr(0,2);    
                datmaint  = new Date(datz).toLocaleString().slice(0,10);    
                datiso = new Date(datz).toISOString();        
              }

              else  { 
                datz = '';    
                datmaint = null;
                datiso = null;
              }
          
            } catch(e) {
              datz = ''; 
              datmaint = null;
              datiso = null;
            }   
            
            let tmpdat1 = values[i].date_pay;  
            try {
              if (tmpdat1 != null) {
                datz1 = tmpdat1.substr(6,4)+'-'+ tmpdat1.substr(3,2)+'-'+tmpdat1.substr(0,2);    
                datpay  = new Date(datz1).toLocaleString().slice(0,10);
                datpayiso = new Date(datz1).toISOString();        
              }
              else  { 
                datz1 = '';    
                datpay = null;
                datpayiso = null;
              }
          
            } catch(e) {
              datz1 = ''; 
              datpay = null;
              datpayiso = null;
            } 
                         
            let item ={
              id:i+1,
              numero :values[i].numero,      
              date_iso : datiso,        
              date_maint :datmaint,
              montant:values[i].montant,
              date_payiso : datpayiso,       
              date_pay : datpay,
              ordre:values[i].ordre,
              commentaire:values[i].commentaire,
              
            };       
          
            items.push(item);
          }
        
      
          this.setState({
            Rows:items,
            maint:items[this.state.newid -1],
            nbrows :nbrows,
            ordremax :i,
          })


      }
        
      try {
      
        this.setState({
          selectedDateTime : datz,
          selectedDatePay : datz1,
        })
    
      } catch(e) {
        
        this.setState({
          selectedDateTime :"",
          selectedDatePay: "",
        })
      
      }
   
    }

    recupLibel (code) {
  
      if (code !=='') {
        const resu = this.typeinterv.find(item =>item.code === code) ;   
      ;
        if (resu !== undefined) {
          return resu.label;
        }
        else return '';
      }
      else return '';  
     }

     modifdatenulle () {
      var datz = '';  
      this.setState({
        selectedDatePay : datz,
       
        },()=>{
          var field = {}
          var fieldName= 'date_pay';  
          field[fieldName] = null;    
          
          var maint = Object.assign({}, this.state.maint, field)       
         
          let dejaexist = false;
          for (var key in this.listmodif) {
            var obj = this.listmodif[key];
            for (var prop in obj) {
         
              if (prop === 'date_pay') {
                obj[prop] = null;
                dejaexist = true;
              }               
                           
            };  
          }
          if (!dejaexist) {
            this.listmodif.push (field);
          }
  
          
          field = {};
          fieldName = 'date_payiso';
          field[fieldName] = null;   
         
          maint = Object.assign({}, this.state.maint, field)     
  
          this.setState({
              maint: maint,
          })
        
        
          
        })
      }
    
    componentDidMount() {

      this.typemotif = this.props.typemotifs;
      var today = new Date();                     
      var sYear = today.getFullYear().toString ();
      var sYear2 =(2+ today.getFullYear()).toString ();
      var sMonth = ('0'+(today.getMonth() + 1)).slice(-2);
      var sDay =   ('0'+today.getDate()).slice(-2);
      var datejour =   sYear+'-'+sMonth +'-'+sDay;      
      var datemaxi =   sYear2+'-'+sMonth +'-'+sDay;         

       this.setState({
        datedujour : datejour,
        datemaxi : datemaxi,
        }); 
      

      this.setState({
        numclient :this.props.numclient,
       },()=>{
        var numsearch = parseInt(this.props.numclient);
        this.props.getListeMaintparClient (numsearch)
        .then ( (response) => {
      
         this.afficheMaint(response.value);
         
      })
    
      }, (error) => {
        console.warn('error ',error);
        
      })

    }
       
     

      
      
        
    
   
    componentDidUpdate(prevProps, prevState) {  
       
      if ((this.state.numclient !== this.props.numclient) || 
         (this.state.reaffiche)) {
        this.setState({
          numclient :this.props.numclient,
          reaffiche: false,
          newid:1,
         },()=>{
          var numsearch = parseInt(this.props.numclient);
          this.props.getListeMaintparClient (numsearch)
          .then ( (response) => {
      
           this.afficheMaint(response.value);
          
    
        })
      
        }, (error) => {
          console.warn('error ',error);
          
        })
      }
    }



   

    clickEdit= () => {   

      const displayCode = (localStorage.getItem('Suiviclients_Search_ajoutPossible') === '1') 
      if (displayCode) {
 
            this.setState({
              editMode: true,  
              addMode: false,       
              idencours:this.state.newid,            
            })

            localStorage.setItem('Suiviclients_Search_modifEncours', 1);
            var datz ='';
            var datz1 ='';
              var tmpdat = this.state.maint.date_maint;    
        
              if (tmpdat === null) {
                datz  ='';
              }        
              else datz = tmpdat.substr(6,4)+'-'+ tmpdat.substr(3,2)+'-'+tmpdat.substr(0,2);   
              try {
                this.setState({
                  selectedDateTime : datz,
                })    
            
                } catch(e) {
                  this.setState({
                    selectedDateTime :"",
                  })
                  
                }
              var tmpdat1 = this.state.maint.date_pay;     
         
              if (tmpdat1 === null) {
                datz1  ='';
              }
              else datz1 = tmpdat1.substr(6,4)+'-'+ tmpdat1.substr(3,2)+'-'+tmpdat1.substr(0,2);    
              try {
           
                this.setState({
                  selectedDatePay : datz1,
                })    
            
                } catch(e) {
                  this.setState({
                    selectedDatePay :"",
                  })
                  
                }

           


      }
      else {
          this.setState({
            ajoutPossible :false,
          },()=>{
           
          
          })
      }
      
    }

     clickAjout= () => {   
      const displayCode = (localStorage.getItem('Suiviclients_Search_ajoutPossible') === '1') 
      if (displayCode) {
        var dateiso = new Date().toISOString(); 
        dateiso = dateiso.substr(0,10) + "T00:00:00Z";
        
        var datelocale = new Date().toLocaleString();
        var tmp = this.state.zmaint;
         tmp.numero = this.state.numclient;
         tmp.date_iso = dateiso;
         tmp.date_maint =datelocale;       
         tmp.date_payiso = dateiso;
         tmp.date_pay =datelocale;     
         tmp.montant =0;  
         
        this.setState({
          zmaint : tmp,
        })
        localStorage.setItem('Suiviclients_Search_modifEncours', 1);;
        const maint = this.state.zmaint;
   
        var tmpdat = maint.date_maint;
      
        var datz = tmpdat.substr(6,4)+'-'+ tmpdat.substr(3,2)+'-'+tmpdat.substr(0,2);     
       
        this.setState({
          addMode: true,   
          editMode:true,      
          idencours:this.state.newid,  
          
        })
  
        this.setState({
          maint: maint,   
          selectedDateTime : datz, 
        })

        var tmpdat1 = maint.date_pay;
      
        var datz1 = tmpdat1.substr(6,4)+'-'+ tmpdat1.substr(3,2)+'-'+tmpdat1.substr(0,2);     
       
        this.setState({
          addMode: true,   
          editMode:true,      
          idencours:this.state.newid,  
          maint: maint,   
          selectedDatePay : datz1, 
        },()=>{
         
       
        })
  
       
     
      }
      else {
        this.setState({
          ajoutPossible :false,
        },()=>{
         
        
        })

      }
   
   
     }

    clickValid= () => {    

      var field = {}
      var fieldName = "numero";
      field[fieldName] =  this.state.maint.numero;
      this.listmodif.push(field);
      fieldName = "ordre";
      field[fieldName] =  this.state.maint.ordre;
      if (this.state.addMode) {field[fieldName] =  this.state.ordremax +1;}
      this.listmodif.push(field);

      fieldName = "date_maint";
      var datx= this.state.maint.date_iso;
      field[fieldName] =  datx;     
      this.listmodif.push(field);

      if (this.state.addMode) {
        /*fieldName = "ordre";
        field[fieldName] =  this.state.maint.ordre;
        this.listmodif.push(field); */
        fieldName = "montant";
        field[fieldName] =  this.state.maint.montant;
        this.listmodif.push(field);
        fieldName = "commentaire";
        field[fieldName] =  this.state.maint.commentaire;
        this.listmodif.push(field);
        fieldName = "date_pay";
        var datx1= new Date(this.state.maint.date_payiso);
        field[fieldName] =  datx1;     
        this.listmodif.push(field);
      }

      var changes = {};
      for (var key in this.listmodif) {
        var obj = this.listmodif[key];
        for (var prop in obj) {
   
          changes[prop] = obj[prop];
          }            
        };  
     
      while(this.listmodif.length > 0) {
        this.listmodif.pop();
      }

    
      let maint = this.state.maint;
      maint.libel_maint = this.recupLibel(maint.motif_maint);
     
      let values =  this.state.Rows;    
    
      if (this.state.addMode) {
        maint.numero = this.state.numclient;
      }

      values = values.map((x) => x);
      values[this.state.newid-1] = maint;
          
      if (!this.state.addMode) {

        this.props.updateMaint (changes)      
        .then ( (resu) => {    
                    
          if (resu.value ===1) {     
                  
                                          
              localStorage.setItem('Suiviclients_Search_modifEncours', 0);
              
              

              this.setState({
                  editMode: false,
                  addMode:false,
                  idencours :50,       
                  Rows : values,
                  reaffiche:true,   // il faut recharger la liste des interv
               
                },()=>{
                    // It is an callback function.
                    // Here you can access the update value
                
                  
                })  

            
                        
            }
            else {
              
            }
        }
        , (error) => {
          console.warn('Erreur update', error)
          
        })
      }
      else
      {
        this.props.insertMaint (changes)
        .then ( (resu) => {        
       
           if (resu.value === true) {        
              localStorage.setItem('Suiviclients_Search_modifEncours', 0);
              
                
              this.setState({
                editMode: false,
                  addMode:false,
                  idencours :50,       
                  Rows : values,
                  reaffiche:true,   // il faut recharger la liste des interv
               
                },()=>{
                    // It is an callback function.
                    // Here you can access the update value
                
                  
                })  

             
            }
            else {
              
            }
        }
        , (error) => {
          console.warn('Erreur update', error)
          
        })

      }
    
    }

   

    

   
    clickSearch= () => {

    }

    clickAnnul= () => {    
      let values =  this.state.Rows;  
      this.setState({
        editMode: false,   
        addMode:false,         
        idencours:50,     
        ajoutPossible :1,  
      })      

      if (values.length >0) {
        this.setState({
          maint : values[this.state.newid-1] ,      
        })
      }
      else {
       // this.state.maint.commentaire ='';
        this.setState(Object.assign(this.state.maint,{commentaire:''}));
      }
      localStorage.setItem('Suiviclients_Search_modifEncours', 0);
      
    }

    resetClick = () => {

    
      this.setState({
        ajoutPossible: true,
      })  
      this.clickAnnul();
    }

    handleInfosChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      } 
     
      handleInputChange(evt) {
        const field = {}
        const fieldName= evt.target.name          
        field[fieldName] =  evt.target.value;

        let dejaexist = false;
        for (var key in this.listmodif) {
          var obj = this.listmodif[key];
          for (var prop in obj) {
       
            if (prop === fieldName) {
              obj[prop] = evt.target.value;
              dejaexist = true;
            }               
                         
          };  
        }
        if (!dejaexist) {
          this.listmodif.push (field);
        }
   

       
        const maint = Object.assign({}, this.state.maint, field)
        this.setState({
          maint: maint,
         })

  

    }

      updateSelectedDateTime = event => {
       
        this.setState({ selectedDateTime: event.target.value });
     
        var field = {}
        var fieldName= event.target.id;  
        field[fieldName] = new Date(event.target.value);     
        var maint = Object.assign({}, this.state.maint, field)   
        fieldName = 'date_iso';
        field[fieldName] = event.target.value +'T00:00:00Z';    
        maint = Object.assign({}, this.state.maint, field)     
        
        
        this.setState({
            maint: maint,
        })

        
      
      }

      updateSelectedDatePay = event => {
       
        this.setState({ selectedDatePay: event.target.value });
     
        var field = {}
        var fieldName= event.target.id;  
        field[fieldName] = new Date(event.target.value);            
        
        var maint = Object.assign({}, this.state.maint, field)     

        var  datpayiso = event.target.value +'T00:00:00Z';   
        let dejaexist = false;
        for (var key in this.listmodif) {
          var obj = this.listmodif[key];
          for (var prop in obj) {
       
            if (prop === 'date_pay') {
              obj[prop] = datpayiso;
              dejaexist = true;
            }               
                         
          };  
        }
        if (!dejaexist) {
          this.listmodif.push (field);
        }

        
        field = {};
        fieldName = 'date_payiso';
        field[fieldName] = event.target.value +'T00:00:00Z';   
       
        maint = Object.assign({}, this.state.maint, field)     

        this.setState({
            maint: maint,
        })
       


      }

      handleSelectedRow = (row)  => {
  
      }       
     
      handlePageChange = (event) => {
  
    
    }

      handleClickRow = (event)  => {
       
        if (!this.state.editMode)
        {
          
          const maint = this.state.Rows[event.id-1];        
         
      
          this.setState({
            newid:event.id,
            idencours:50, 
            maint : maint,      
          });
      
        
          
      
        }
        else {
      
          if (this.state.idencours!==50) {

          }
          else {
        
          this.setState({
            newid:event.id,
            idencours:event.id, 
           
          });
        }
       }
     
      };


      handleOverRow = (event)  => {
 
        /*
        this.setState({
          newid:event.id,
        
        });
        */
      } 

      handleCellChange =(event)  => {
     
   
        
      } 
      
      handleCellChangeCommitted =(event)  => {
 
       
       
      } 

      setSelection=(selections) => {
           
      
      }
     


  Columns = [
  { field: 'numero',
    headerName: 'Numero',
    headerClassName: 'super-app-theme--header',  
    headerAlign: 'left',
    sortable:false,
    hide:true,
    width: 100, editable: false,
  },
  { field: 'date_maint',
   headerName: 'date maint', 
   headerClassName: 'super-app-theme--header',  
   type: 'dateTime', 
   sortable:false,width: 170,
   editable: true 
  },
  {
    field: 'montant',
    headerName: 'Montant',
    headerClassName: 'super-app-theme--header', 
    type: 'text',
    width: 110,
    sortable:false,
    editable: true,
  },
  {
    field: 'date_pay',
    headerName: 'Date Paiement',
    headerClassName: 'super-app-theme--header', 
    type: 'text',
    width: 170,
    sortable:false,
    editable: true,
  },
  {
    field: 'ordre',
    headerName: 'Ordre',
    headerClassName: 'super-app-theme--header', 
    type: 'number',
    width: 80,
    sortable:false,
    editable: true,
  },
 
];


      
     render() {
    
           const selectionModel = [this.state.newid];
          
  
      
            return (
              <Container className={this.props.classes.root}>
              {!this.state.ajoutPossible ? 
                <div className="outlined_encours">  
                    <CircularProgress className="margin-t-15 margin-l-5" />
                    <label className="label margin-t-15">  
                        Modification en cours - Action Non Autorisée                    
                    </label>                        
                    <ResizableIconButton   title="Cliquer pour annuler"
                        onClick={this.resetClick}>
                        <CancelIcon   style= {{ color: "#ff0000",visibility:"visible", fontSize: 48}} />                                                                 
                    </ResizableIconButton>
                </div>:
                <div>
              
                  <Container>
                    
               
                  
                    <SplitPane split="vertical" defaultSize="60%" >
                         <div className = "boxRight" >  
                            <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>
                               
                                <div style={(!this.state.addMode && !this.state.editMode)?{ color: "#ffffff",visibility:"hidden",height:0 }
                                            :{ color: "#000000",visibility:"visible"}} >

                                      
                                      <FormControl className = {this.props.classes.formControlInterv} >      
                                        <div>
                                        <label className="label margin-l-5">
                                            Modification de la date de maintenance : 
                                          </label>
                                       </div>                
                                       <div>
                                          <label className="label margin-l-5">
                                            Ordre maint :
                                            <input  className= "inputmaint width60 gras"
                                                name="ordre_maint"
                                                type="number" handleInputChange
                                                disabled={true}   
                                                value={!this.state.addMode?this.state.maint.ordre:this.state.ordremax+1}
                                                onChange={this.handleInputChange} />
                                            </label>
                                        </div>
                                        <div >   
                                          <label className="label margin-l-5">
                                            Date de la Maintenance : 
                                          </label>
                                          <TextField
                                              id="date_maint"       
                                              type="date"
                                              value={this.state.selectedDateTime}
                                              onChange={this.updateSelectedDateTime}       
                                              className={this.props.classes.textFieldDate}                                             
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              inputProps={{min: this.state.datedujour, max: this.state.datemaxi ,style: {fontSize: 16,height:12,fontWeight:"bold"}}}  
                                            />    

                                        </div>
                                        <div>
                                        <label className="label margin-l-5">
                                            Montant :
                                            <NumberFormat  className="input width100 gras" name ="montant"
                                              isNumericString format="#####"                                               
                                              required={true}   
                                              disabled = {false}                    
                                              value={this.state.maint.montant}                         
                                              onChange = {this.handleInputChange }/>

                                          </label>                                           
                                        </div>
                                        <div >   
                                          <label className="label margin-l-5">
                                            Date du Paiement : 
                                          </label>
                                          <TextField
                                              id="date_pay"       
                                              type="date"
                                              value={this.state.selectedDatePay}
                                              onChange={this.updateSelectedDatePay}       
                                              className={this.props.classes.textFieldDatePay}                                             
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              inputProps={{style: {fontSize: 16,height:12,fontWeight:"bold"}}}  
                                             
                                            /> 
                                             <ButtonOw 
                                                title='Date nulle' 
                                                disabled= {!this.state.editMode}  
                                                onClick={this.modifdatenulle}>                          
                                                <CancelIcon style={!this.state.editMode?{ color: "#ffffff",visibility:"hidden" }:{ color: "#000000",visibility:"visible"}} />
                                              </ButtonOw>   

                                        </div>
                                        
                                        
                                        <div>
                                        <label className= {(localStorage.getItem('Suiviclients_Search_modifEncours') !== 0)? "label-hidden": "label-red"}> 
                                          Email non valide !  
                                        </label>
                                        </div>

                                      </FormControl>
                                    
                                </div> 
                                
                                <div style={(this.state.addMode || this.state.editMode)?{ color: "#ffffff",visibility:"hidden" }
                                            :{ color: "#000000",visibility:"visible"}} >
                               
                              
                                   
                                    <Grid item lg className={this.props.classes.classgrid}>
                                        <DataGrid className={this.props.classes.root} id="gridinterv"
                                          components={{
                                            NoRowsOverlay: CustomNoRowsOverlay,
                                            
                                          }}
                                          rows={this.state.editMode?this.state.Rows:this.state.Rows}                                            
                                          columns={this.Columns} 
                                          disableColumnMenu
                                          headerHeight={45}
                                          rowHeight = {50}
                                          isRowSelectable={(params) => true}
                                          onRowSelected={(e) => this.handleSelectedRow(e)} 
                                          onSelectionChange={(newSelection) => {
                                                
                                              
                                            }}
                                          onRowOver=  {(rowData) => this.handleOverRow(rowData.row)} 
                                          hideFooter
                                          onRowClick={(rowData) => this.handleClickRow(rowData.row)} 
                                          onEditCellChange ={(cellData) => this.handleCellChange(cellData)} 
                                          onEditCellChangeCommitted ={(cellData) => this.handleCellChangeCommitted(cellData)} 
                                          selectionModel ={selectionModel}
                                          isCellEditable={(params) => params.row.id === this.state.idencours}
                                          onPageChange={(page) => this.handlePageChange(page)} >
                                        </DataGrid> 
                                                                
                                    </Grid>      
                                
                                </div>
                                
                            
                            </CustomScrollbars>                   
                        </div>            
                        <div className= {this.state.editMode?"boxRight":"boxMaint" }>
                            <CustomScrollbars1 autoHide autoHideTimeout={500} autoHideDuration={200}>
                            
                                <Toolbar className={this.props.classes.toolbar}>
                                      
                                        <Typography className={this.props.classes.title} variant="h5">Maintenance</Typography>
                                        <ButtonOw 
                                            title='Modificationn' 
                                            onClick={this.clickEdit}>
                                            <EditIcon style={(this.state.editMode || this.state.nbrows===0)?{ color: "#ffffff",visibility:"hidden" }
                                            :{ color: "#000000",visibility:"visible"}} />
                                        </ButtonOw>
                                        <ButtonOw 
                                            title='Ajouter une Date de Maintenance'   
                                            disabled= {this.state.editMode}                                                  
                                            onClick={this.clickAjout}>
                                            <AddIcon style={this.state.editMode?{ color: "#ffffff",visibility:"hidden" }:{ color: "#000000",visibility:"visible"}} />
                                          </ButtonOw> 
                                        <ButtonOw 
                                            title='Valider' 
                                            onClick={this.clickValid}>
                                            <DoneIcon style={this.state.editMode?{ color: "#f0621f",visibility:"visible" }
                                            :{ color: "#f0621f",visibility:"hidden"}} />
                                        </ButtonOw>
                                        <ButtonOw 
                                            title='Annuler' 
                                        disabled= {!this.state.editMode}  
                                            onClick={this.clickAnnul}>
                                            <CancelIcon style={this.state.editMode?{ color: "#f0621f",visibility:"visible" }
                                            :{ color: "#f0621f",visibility:"hidden"}} />
                                        </ButtonOw>
                                       
                                        <ButtonOw 
                                            title='Recherche' 
                                            disabled={this.state.editMode}  
                                            onClick={this.clickSearch}>
                                            <SearchIcon style={{ color: "#0ee73f",visibility:"hidden"} }/>
                                        </ButtonOw>
                                       
                                        
                                        
                                </Toolbar>                           
                                
                         
                                <FormControl fullWidth 
                                className={this.state.editMode?this.props.classes.formControlInfosModif:this.props.classes.formControlInfos}>
                                  <TextField 
                                   style ={this.state.editMode?{backgroundColor:"#ffffff"}:{}}
                                   variant ={this.state.editMode?"outlined":"filled"} 
                                     name="commentaire"                                                                          
                                     disabled={!this.state.editMode} 
                                     multiline
                                     inputProps={{style: {fontWeight:"bold"}}} 
                                     rowsMax={10}
                                     value={this.state.maint.commentaire}
                                     onChange={this.handleInputChange}
                                  />
          
                                </FormControl>
                              

                          </CustomScrollbars1>
                        </div>
                  
                    
                    </SplitPane>

                
                   
                  </Container>
                </div>  
              }    
              </Container>  
       
         )
    }     
}  


const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 10,
      backgroundColor: 'rgba(252, 36, 89, 0.8)',
      width:10,
      height : 10,
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  
  const renderThumb1 = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 10,
      backgroundColor: 'rgba(22, 36, 89, 0.8)',
      width:10,
      height : 10,
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };
  const CustomScrollbars = props => (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...props}
    />
  );


  const CustomScrollbars1 = props => (
    <Scrollbars
      renderThumbHorizontal={renderThumb1}
      renderThumbVertical={renderThumb1}
      {...props}
    />
  );

  const mapStateToProps = state => ({
    isConnected : state.identity.isConnected,
    numClientEnCours : state.identity.numClientEnCours,
    clients : state.identity.clients,
    identityLoading : state.identity.loading,
    loading : false,
   
})
      



const mapDispatchToProps = (dispatch) => {
    return {
      getListeMaintparClient : bindActionCreators (getListeMaintparClient, dispatch),   
      updateMaint : bindActionCreators (updateMaint, dispatch),   
      insertMaint : bindActionCreators (insertMaint, dispatch),   
      setTitle : bindActionCreators (setTitle, dispatch),
    }  
    
  }

    const classes = theme => ({
      bkcol: {
        /*
        backgroundColor: '#DBFCFB',// bleu pastel
        backgroundColor: '#92ECEB',// bleu ciel
        backgroundColor: '#F9D7D7',// rose pastel 
        backgroundColor: '#F49090',// rose 
        backgroundColor: '#BBD3F7',// bleu 
        backgroundColor: '#93F7E1', 
        backgroundColor: '#CFC961',// marron
        backgroundColor: '#A0F950',//vert
        backgroundColor: '#5ef2e2', 
       */
      },
      
      classgrid: {
        '& .super-app-theme--header': {
          backgroundColor: theme.bkcolor.jaunepastel,
          fontWeight: 'bold',
          color:theme.bkcolor.noir,
          
        },
        scrollBehavior: 'smooth',
        flexGrow: 1,
        backgroundColor: theme.bkcolor.rosepastel,
        height:'35vh',
      },
      root: {
        '& .zMuiDataGrid-row': {
          display: 'flex',
          width: 'fit-content',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
          '&.Mui-selected': {
            backgroundColor: theme.bkcolor.marron,
            color:theme.bkcolor.blanc,
          },
          '@media (hover: none)': {
            backgroundColor:theme.bkcolor.rmarron,
           
          },
        },
        
        flexGrow: 1,
        backgroundColor: theme.bkcolor.rosepastel,
        height:'35vh',
      },
         
      toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: theme.bkcolor.jaunepastel,
        height:45,
        minHeight : 20,
        
      },
        title: {
            color:'inherit',
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            
         },       
        memo: {
            textAlign: 'justify',
            marginBottom: '1.3em',
        },
        btn_class :{
            color:"primary",
        },          
        customColor: {
          // or hex code, this is normal CSS background-color
          color:theme.bkcolor.rosepastel,
          backgroundColor: theme.bkcolor.rosepastel,
          height:30,         
          maxHeight: 300,
        },   
        formControl: {         
          color: 'primary',    
          margin: theme.spacing(2),   
         // backgroundColor: theme.bkcolor.rosepastel,      
        } ,
        formControlInterv: {         
          color: 'primary',    
          margin: theme.spacing(2),                
       
       },   
       formControlInfos: {         
         color: 'primary',    
         margin: theme.spacing(2),   
         backgroundColor: theme.bkcolor.rosepastel,      
       
       },   
      formControInfosModif: {         
       color: 'primary',    
       margin: theme.spacing(2),   
      // backgroundColor: theme.bkcolor.rosepastel,      
       } ,
        textField: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          width:220,
        },
        textFieldDate: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(2),
          marginBottom:theme.spacing(1),
          marginTop :5,
          width:140,
          backgroundColor: "white", 
          color: "black",   
        },
        textFieldDatePay: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(2),
          marginBottom:theme.spacing(1),
          marginTop :9,
          width:140,
          backgroundColor: "white", 
          color: "black",   
        },
        grid : {
        '&& .MuiDataGrid-hover': {
            cursor: 'pointer',
          },
          height:'90vh',
          backgroundColor: theme.bkcolor.rosepastel,
          '& .MuiDataGrid-cellEditable': {
            backgroundColor: theme.bkcolor.marron,
          },
          '&.MuiDataGrid-row.Mui-odd': {
            backgroundColor: theme.bkcolor.marron,
          },          
          '& .MuiDataGrid-row-selected': {
            color: 'white',
            backgroundColor: theme.bkcolor.marron,
           },
           '& .Mui-cellEditable': {
            backgroundColor: theme.bkcolor.marron,
          },
          '& .MuiTableRow-selected': {
            color: 'white',
            backgroundColor: theme.bkcolor.marron,
          },
  
         },
       
        formtextfield: {
          margin: theme.spacing(1),
        },
        textfieldinterv: {
          backgroundColor: theme.bkcolor.rosepastel,
        },
       
        headergrid : {
          backgroundColor: theme.bkcolor.rosepastel,
        },
        superappthemeheader: {
          backgroundColor: 'rgba(255, 7, 0, 0.55)',
        },
        tableRow: {
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "purple",
            "& > .MuiTableCell-root": {
              color: "yellow"
            }
          }
        }
         
         
})

export default connect ( mapStateToProps, mapDispatchToProps) (withStyles(classes) (MaintForm))

