import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Galaxy from '../core/components/assistance/Galaxy';
import Identity from '../core/components/identity/Identity';
import ButtonFullScreen from '../core/components/window/ButtonFullScreen';
import InspectInfos from '../core/components/window/InspectGrid';

class Header extends Component {

  render() {

    return (
      <header className={this.props.classes.root}>
        <div className={this.props.classes.logo}>
          <Link to="/">
            <img src="/images/athmo.svg" className="imgLogo" alt="logo Athmo"/>
          </Link>
         
        </div>
        {this.props.title && (
          <h2>Suiviclients - {this.props.title}</h2>
        )}
        <div className={this.props.classes.athmoMenu}>
            <InspectInfos />
            <ButtonFullScreen />         
            <Galaxy />
            <Identity />
        </div>
        
      </header>
    )
  }
}

const mapStateToProps = state => {
  return {
    isConnected : state.identity.isConnected,
    numClient: state.identity.numClientEnCours,
    title : state.windowApp.title,
  }
}

const classes = theme => ({
  
  
  root: {
    color: theme.palette.primary.main,
    height: 'calc(7vh - 2px)',    
    minHeight: '26px',
    borderBottom: '2px solid #092F6A',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.bkcolor.bleupastel,
},
  logo: {
    margin : 0,
    '&& img': {
      height: 'calc(7vh - 2px)'
    }
  },
  
  athmoMenu: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& a:-webkit-any-link': {
      color: '-webkit-link',
      cursor: 'pointer',
      textDecoration: 'none',
    }
  },
})

export default connect (mapStateToProps) (withStyles(classes) (Header))