import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Initialization from '../../../core/components/Init';
import { logout } from '../../../core/redux/actions/IdentityActions';
import './Identity.css';

class Identity extends Component {  

    logout = () => {
        this.props.logout ()
        .then ( (response) => {
            const init = new Initialization ()
            init.initialize()
            this.props.history.push ('/')

        }, (error) => {
            console.warn('Erreur', error)
            
        } )



    }
    render() {
       

        return (
            <>
                 {this.props.isConnected ?
                     (<>
                        
                         <Tooltip title="Se déconnecter">
                             <IconButton 
                                     variant="text"
                                     color="primarry" 
                                     onClick={this.logout}>
                                 <ExitToAppIcon />
                             </IconButton>
                         </Tooltip>
                        
 
                     </>
                     ) :
                     (<>
                     </>
                    )
                 }
             </>
         )
   }
 }

const styles = (theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    button: {
        color: theme.palette.primary.main,
    }
});

const mapStateToProps = state => {
    return {
        isConnected: state.identity.isConnected,
        user: state.identity.user,
        idEnCours: state.identity.numClientEnCours,
        clients: state.identity.clients,
    }
}

const mapDismatchToProps = (payload) => {
    return {
        logout: bindActionCreators(logout, payload)
      
    }
}
export default connect(mapStateToProps, mapDismatchToProps)(withRouter(withStyles(styles)(Identity)));