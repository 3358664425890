import { Container, withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React, { Component } from 'react';
import '../../stylesheets/splitpane.css';
import ChoixClient from '../Home/ChoixClient';

class Clients extends Component  {

    constructor(props) {
        super(props);
        this.state = {
            etatClient:0,
            num:0,
            client:null,
            titre:'Liste des Clients',
            radio :1,
        }
        this.handleRadioChange  = this.handleRadioChange.bind(this)
    }
    
    afficheClient (id) {
    
        this.props.afficheClientClick(id);
    }
    
    afficheTitre (titz) {
    
        this.setState ({
          
            titre: titz,
        },()=>{
     
        })

    }  
    
    handleRadioChange = (event) => {
        var val = this.state.radio;
      
         // eslint-disable-next-line    
        if (val == 0) {val =1;} else val = 0;
        this.setState ({
            radio :val,
        })
      
        
     }
 
    componentDidMount() {
        var tousclients = localStorage.getItem('Suiviclients_Search_tousClients');
        this.setState ({
           radio :  tousclients,
        })

      }

    componentDidUpdate(prevProps, prevState) {

        if (this.state.num !== this.props.num) { 
  
             this.setState({
              num: this.props.num,
              client : this.props.client,
             });    
          }
   
      }
    render() {
      
        return (


            <Container className={this.props.classes.root}>  
       
                            <FormControl component="fieldset" className={this.props.classes.headcontrol}>
                            <div>
                               <label className="label margin-l-10 margin-r-50">
                                  {this.state.titre} </label>
                                <FormControl>
                                  <div className="radio">
                                    <label>
                                        <input
                                        type="radio"
                                        value="Actifs"
                                        className="label margin-l-10 margin-t-10"
                                        // eslint-disable-next-line
                                        checked={this.state.radio == 0}
                                        onChange={this.handleRadioChange}
                                        />
                                        Actifs
                                    </label>
                                    
                                    <label>
                                        <input
                                        type="radio"
                                        value="Tous"
                                        className="label margin-l-10 margin-t-10"
                                        // eslint-disable-next-line
                                        checked={this.state.radio == 1}
                                        onChange={this.handleRadioChange}
                                        />
                                        Tous
                                    </label>
                                    </div>
                                
                                </FormControl>
                            </div>
                            </FormControl> 

                    <ChoixClient reAfficheListe = {this.props.reAfficheListe}
                      radiostate = {this.state.radio}
                      afficheTitre = {(titz) =>this.afficheTitre(titz)}
                      afficheClientClick= {(id) =>this.afficheClient(id)}>
                     </ChoixClient>
              
        
              
                
            </Container>
        )
    
    }
}


    const classes = theme => ({
        root: {
            scrollBehavior: 'smooth',
            flexGrow: 1,            
            height:'5vh',            
            backgroundColor: theme.bkcolor.bleupastel,
            display :'table-row',
            width:'100vh',
        },
        title: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
            },
        memo: {
            textAlign: 'justify',
            marginBottom: '1.3em',
        },
        btn_class :{
            color:"primary",
        },
        input: {
        lineHeight: '2rem',
        },
        label: {
        display: 'inline-block',
        width: '12em',
        },
        headcontrol: {
            height:'30px',
            backgroundColor: theme.bkcolor.vertpastel,
            color: theme.bkcolor.noir,
            width :'100vh',
        },
    })
    
    export default  (withStyles(classes) (Clients))
    
    