import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core';
import { frFR } from '@material-ui/core/locale';
import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Initialization from './core/components/Init';
import { store } from './core/redux/store';
import { LightTheme } from './layout/AthmoTheme';
import DynamicApp from './layout/DynamicApp';
import Header from './layout/Header';


class App extends Component {
  
  render () {
    
    const init = new Initialization ()
    init.initialize()
    const devEnv = process.env.NODE_ENV === 'development'
    devEnv && console.info ('environnement de développement')
    let theme = createTheme(LightTheme, frFR);
    theme = responsiveFontSizes(theme);

          
      return (
        <ThemeProvider theme={theme}>
          <Provider store={store} >
            <BrowserRouter>
              <Header />
             <DynamicApp></DynamicApp>
              
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
    )

  } 
}
export default App;
