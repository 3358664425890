import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import Error from '../../../layout/Error';
import Page404 from '../../../layout/Page404';
import AuthenticationPage from '../../../pages/Authentication/AuthenticationPage';
import SplitPage from '../../../pages/Home/SplitPage';
import WaitBlock from '../window/WaitBlock';









/**
 * Routes accessibles dans l'application
 * 
 * Le droit minimal d'accès à un composant privé doit être spécifié via minRoll
 * Le droit est défini par les constantes rolls.U_
 */ 
class Routes extends Component {

  state = {
    loading : true
  }
  componentDidMount () {
    if (this.props.isConnected 
        && this.props.numClientEnCours > 0
        && ( !this.props.clients)
    ) {
     
        this.setState ({
          loading : false
        })
      }     
     else {
      this.setState ({
        loading : false
      })
    }
  }

  render() {
    return (
      <>
        {this.props.error && <Error />}
        <Suspense fallback={(<WaitBlock text="Chargement en cours..." />)}>
          <Switch>
            <Route path="/" exact component={AuthenticationPage} />            
            <Route path="/splitPage" exact component={SplitPage} />
            
            <Route path='*' component={Page404} />
          </Switch>
        </Suspense>
      </>
    )
  }
}
const mapStateToProps = state => {
    return {
      error: state.windowApp.error,
    }
}    

export default connect (mapStateToProps) (Routes);
